import React from 'react'
import { Box, FormControl, FormControlLabel, Grid, InputLabel, Radio, RadioGroup,Button } from '@mui/material'
import { Navigate, Route } from 'react-router-dom'
import FormA from '../commodity-add-company-form/FormA'
import AddCompanyWithNoGst from '../commodity-add-company-form/AddCompanyWithNoGst'
import MainForm from '../commodity-add-company-form/MainForm'
import { useNavigate } from 'react-router-dom';


const GstCheckAddCompany = () => {
    const navigate = useNavigate();
    const [haveGstNumber,setHaveGstNumber] = React.useState('')
   
    const handleGstCheck=(e)=>{
        setHaveGstNumber(e.target.value)
     
    }
    const setData = () => {
    
      localStorage.setItem('haveGST', haveGstNumber); // use setter here
      console.log('Value set in localStorage:', haveGstNumber);
      navigate('/add-company-eKyc')
    };
  return (
<Grid>
      <Grid item xs={12}>
      <Box style={{  display: "flex", alignItems: "center",marginTop:"1vh" }}>
  <InputLabel>
    <strong>Do you have a GST number?</strong>
  </InputLabel>
</Box>
      </Grid>
      <Grid item xs={12}>
      <Box style={{  display: "flex", justifyContent:"center",marginTop:"1vh"}}>
    <FormControl>
      <RadioGroup  row aria-label="gender" name="row-radio-buttons-group" value={haveGstNumber} onChange={handleGstCheck} >
        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
        <FormControlLabel value="No" control={<Radio />} label="No" />
      </RadioGroup>
    </FormControl>
    </Box>
      </Grid>
     <Grid item sm={12} style={{  display: "flex",
  flexDirection: "column",
  alignContent: "center",
 }}>
        <Button size='small' onClick={setData} style={{ backgroundColor: "grey",
  color: "white",
  borderRadius: "5px",
  padding: "2px"}}>Submit</Button>
        
     </Grid>
    </Grid>
  )
}

export default GstCheckAddCompany