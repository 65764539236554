import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import MuiLink from '@mui/material/Link';
import { useLocation, useNavigate } from 'react-router-dom';
import VerifiedIcon from '@mui/icons-material/Verified';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Rating from '@mui/material/Rating';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import InboxIcon from '@mui/icons-material/Inbox';
import ReactGA from 'react-ga4';
import useMediaQuery from '@mui/material/useMediaQuery';
import { cDecrypt, storeCTAClick } from '../../helpers';
import { User } from './../../api/resources/User';
// import Badges from './Badges';

import {
    checkAndLoadUser,
    fetchUnreadNotificationCount,
    loginUser,
    logoutUser,
} from '../../redux/actions/userActions';
import {
    setLoginModal,
    setSignupModal,
} from '../../redux/actions/commonActions';
import { connect } from 'react-redux';
import { cEncrypt } from '../../helpers';
import { useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import Slide from '@mui/material/Slide';
import CancelIcon from '@mui/icons-material/Close';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import Drawer from '@mui/material/Drawer';
import Badge from '@mui/material/Badge';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import Hidden from '@mui/material/Hidden';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import makeStyles from '@mui/styles/makeStyles';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AddBoxIcon from '@mui/icons-material/AddBox';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import ContentLoader from 'react-content-loader';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import loadable from '@loadable/component';
import CircularProgress from '@mui/material/CircularProgress';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import EditProfileDialog from '../profile/subusers/EditProfileDialog';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const LanguageTranslate = loadable(() =>
    import(
    /* webpackChunkName: "js/chunks/partials-LanguageTranslate" */ '../partials/UnderScoreLanguage'
    )
);
const LoginForm = loadable(
    () =>
        import(
      /* webpackChunkName: "js/chunks/partials-LoginForm" */ '../partials/LoginForm'
        ),
    {
        fallback: () => (
            <div className="text-center">
                <CircularProgress />
            </div>
        ),
    }
);
const ProfileCompanyInfo = loadable(
    () =>
        import(
      /* webpackChunkName: "js/chunks/profile-ProfileCompanyInfo" */ '../profile/ProfileCompanyInfo'
        ),
    {
        fallback: () => (
            <div className="text-center">
                <CircularProgress />
            </div>
        ),
    }
);
const SignUp = loadable(
    () =>
        import(
      /* webpackChunkName: "js/chunks/signup-SignUp" */ '../signupV2/SignUp'
        ),
    {
        loading: () => (
            <div className="text-center">
                <CircularProgress />
            </div>
        ),
    }
);

const useStyles = makeStyles((theme) => ({
    root: {
        transform: 'translateZ(0px)',
        flexGrow: 1,
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    navRoot: {
        backgroundColor: theme.palette.primary.main,
    },
    appBarColor: {
        '&.MuiPaper-root': {
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
        },
    },

    grow: {
        flexGrow: 1,
    },
    navBtn: {
        padding: '1rem',
    },
    navBtn2: {
        padding: '5px 1rem',
    },
    activeItem: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.primary.main,
        borderRadius: 0,
        '&:hover': {
            backgroundColor: theme.palette.common.white,
        },
    },
    btnUI: {
        background: '#ffffff2e',
    },
    languageSelect: {
        marginLeft: '10px',
        position: 'relative',
        top: '-8px',
    },

    menuButton: {
        marginRight: theme.spacing(0),
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    dialogHeader: {
        padding: theme.spacing(1),
        marginTop: theme.spacing(1.5),
        '& h2': {
            fontSize: 24,
            fontWeight: 'bolder',
        },
    },
    whiteButton: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.primary.main,
        marginRight: theme.spacing(1),
        padding: '5px 10px',
        '&:hover': {
            backgroundColor: theme.palette.common.white,
        },
    },
    drawerBadgesContainer: {
        backgroundColor: '#0000001f',
        margin: theme.spacing(1, 1.5, 1.5),
        borderRadius: '4px',
        '& img': {
            width: 65,
        },
        '& p': {
            fontSize: '13px',
        },
        '& br': {
            display: 'none',
        },
    },
    NavbarBadgesContainer: {
        backgroundColor: '#0000001f',
        margin: theme.spacing(1, 1.5, 1.5),
        borderRadius: '4px',
        padding: theme.spacing(1),
        '& br': {
            display: 'none',
        },
    },
    drawerCompanyInfo: {
        display: 'grid',
        gridTemplateColumns: '20px auto',
        gridGap: theme.spacing(1.25),
        backgroundColor: '#cdd4fd8f',
        padding: theme.spacing(1.5, 2),
    },
    profile: {
        position: 'absolute',
        top: '55px',
        left: '66px',
        background: '#fff',
        boxShadow: '0px 1px 2px #0003',
        borderRadius: '24px',
        display: 'flex',
        alignItems: 'center',
        padding: '4px 6px 4px 4px',
        border: '1px solid #CCCCCC',
        minWidth: '80px',
    },
    linkColor: {
        color: '#0645AD',
    },
    list: {
        paddingTop: 0,
        paddingBottom: 0,
        '& .MuiListItem-root': {
            paddingTop: 0,
            paddingBottom: 0,
        },
        // '& .MuiListItemIcon-root': {
        //     minWidth: 45
        // },
    },
    _2col: {
        '& span': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
    },
    dataCount: {
        position: 'absolute',
        background: 'red',
        borderRadius: '8px',
        top: '8px',
        right: 0,
        padding: '2px 4px',
        color: '#fff',
        fontSize: 11,
    },
}));

function NavbarV2(props) {
    const classes = useStyles();
    let location = useLocation();
    const [state, setState] = React.useState({ left: false });
    const [anchorElNotifications, setAnchorElNotifications] =
        React.useState(null);
    const matchesViewport = useMediaQuery('(max-width:580px)');
    const [openOtherDropdown, openOtherDropdownSet] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const user = useSelector((state) => state.userReducer.user);
    const noCompany = useSelector((state) => state.userReducer.noCompany);
    const user_mode = useSelector((state) => state.userReducer.user_mode);
    const [adCreditData, setAdCreditData] = React.useState('');
    const [reportIssueDialogOpen, reportIssueDialogOpenSet] =
        React.useState(false);
    const [editProfileDialogOpen, editProfileDialogOpenSet] =
        React.useState(false);
    const [issueReportedSuccess, issueReportedSuccessSet] = React.useState(false);
    const [loadingMsgCount, loadingMsgCountSet] = React.useState(false);
    const [apiSuccessResponse, apiSuccessResponseSet] = React.useState(null);
    const [counts, countsSet] = React.useState();
    const [countShow, countShowSet] = React.useState(false);
    const [badgesData, setBadgesData] = React.useState();
    const [loading, setLoading] = React.useState(true);
    // const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();
    const brandStoreId = JSON.parse(atob(location?.search.split('?bs=')[1]))
    console.log(brandStoreId)

    const toggleDrawer = (side, open) => (event) => {
        if (
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [side]: open });
    };

    const handleDrawerClose = () => {
        setState(false);
    };

    const handleCloseNotifications = () => {
        setAnchorElNotifications(null);
    };

    const handleClickNotifications = (event) => {
        setAnchorElNotifications(event.currentTarget);
    };

    useEffect(() => {
        let intervalID = null;
        if (props.user && props.isLoggedIn) {
            intervalID = setInterval(() => {
                props.fetchUnreadNotificationCount();
            }, 30000);
        }

        return () => {
            if (intervalID != null) {
                clearInterval(intervalID);
            }
        };
    }, [props.user]);

    useEffect(() => {
        if (props.isLoggedIn) {
            props.setLoginModal(false);
            props.setSignupModal(false);
        }
    }, [props.isLoggedIn]);

    const BadgeCardLoader = () => (
        <ContentLoader
            speed={2}
            // style={{ width: '100%' }}
            width={300}
            viewBox="0 0 300 120"
            style={{ width: '100%' }}
        >
            <rect x="5" y="29" rx="3" ry="3" width="88" height="6" />
            <rect x="6" y="6" rx="3" ry="3" width="52" height="6" />
            <rect x="198" y="31" rx="3" ry="3" width="88" height="6" />
            <rect x="7" y="111" rx="3" ry="3" width="88" height="6" />
            <circle cx="239" cy="15" r="13" />
            <circle cx="52" cy="79" r="24" />
            <rect x="107" y="110" rx="3" ry="3" width="88" height="6" />
            <circle cx="151" cy="81" r="24" />
            <rect x="202" y="110" rx="3" ry="3" width="88" height="6" />
            <circle cx="246" cy="81" r="24" />
        </ContentLoader>
    );

    const getRatingBadges = async (params) => {
        setLoading(true);
        var params = Object.assign(
            { company_id: user?.active_company?.id },
            params
        );

        try {
            const res = await User.profileBadges(params);
            if (res.data.status == 'success') {
                setBadgesData(res.data.data);
            }
        } catch (e) {
            console.error(e);
        }
        setLoading(false);
    };

    const getUnreadMsgCountNav = async (params) => {
        loadingMsgCountSet(true);
        try {
            const res = await User.getUnreadMsgCount(params);
            if (res.data.status == 'success') {
                countShowSet(true);
                countsSet(res.data.data);
            }
        } catch (e) {
            console.error(e);
        }
        loadingMsgCountSet(false);
    };

    React.useEffect(() => {
        if (props.isLoggedIn) {
            if (user) {
                // getRatingBadges();
                // getUnreadMsgCountNav();
            }
            if (user?.latest_credit_consumption_summary) {
                setAdCreditData(
                    user?.latest_credit_consumption_summary?.current_balance
                );
            } else {
                setAdCreditData('00');
            }
        }
        // console.log(user)
    }, [user, props.isLoggedIn]);

    const EkycPending = () => (
        <Box
            sx={{
                background: '#EEF2F5',
                p: 2,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
            }}
        >
            <Box>
                <Box sx={{ fontWeight: 'bold', fontSize: '17px', mb: 0.75 }}>
                    eKYC Verification{' '}
                    <Box
                        component="span"
                        sx={{
                            background: '#FF0000',
                            fontSize: 11,
                            color: '#fff',
                            px: 0.75,
                            py: 0.5,
                            borderRadius: 12,
                            fontWeight: 'normal',
                        }}
                    >
                        PENDING
                    </Box>
                </Box>
                <Box>
                    You are 1-step away from profitable trade, <br /> just add your GST to
                    begin.
                </Box>
            </Box>
            <IconButton
                size="medium"
                onClick={(e) => {
                    navigate('/add-company-ekyc');
                    handleMenuClose(e);
                    handleDrawerClose(e);
                }}
                color="primary"
                sx={{ minWidth: 35, ml: 1.2 }}
            >
                <ChevronRightIcon fontSize="medium" className="vAlignBottom" />
            </IconButton>
        </Box>
    );

    const LoggedInUserInfo = () => (
        <Box sx={{ textAlign: 'center', mb: 1.5 }}>
            <Box sx={{ position: 'relative', py: 1.5, maxWidth: 100, mx: 'auto' }}>
                <img src="/images/user-avatar.png" width="80" />
                <Box className={classes.profile}>
                    <img
                        src="/images/rupee-icon-golden-badge.svg"
                        alt="paid icon"
                        className={
                            badgesData?.plan_status === 'UnPaid' ? classes.unpaid : ''
                        }
                        style={{ display: 'block', width: '24px', marginRight: '4px' }}
                    />
                    <Box sx={{ fontWeight: '500' }}>
                        {badgesData?.plan_status === 'Paid' ? 'PAID' : 'UNPAID'}
                    </Box>
                </Box>
            </Box>
            <Box
                component="p"
                sx={{
                    fontWeight: 'bold',
                    fontSize: '16px',
                    m: 0,
                    textTransform: 'capitalize !important',
                }}
            >
                {user && user.full_name}
                <IconButton
                    aria-label="Edit"
                    size="small"
                    color="primary"
                    onClick={(e) => {
                        editProfileDialogOpenSet(e);
                        handleDrawerClose(e);
                        handleMenuClose(e);
                    }}
                >
                    <EditIcon fontSize="small" />
                </IconButton>
                {/* <ProfileForm user={user} onClickEvent={handleClickOpen} open={open} /> */}
            </Box>
            <Box sx={{ mb: 1 }}>
                {user && user.email}{' '}
                <VerifiedIcon
                    fontSize="small"
                    sx={{ verticalAlign: 'middle', color: '#85CE1F' }}
                />
            </Box>
            <Box>
                {user && user.contact_number}{' '}
                <VerifiedIcon
                    fontSize="small"
                    sx={{ verticalAlign: 'middle', color: '#85CE1F' }}
                />
            </Box>
        </Box>
    );

    const BadgesProfileCompanySwitch = (props) => (
        <>
            <LoggedInUserInfo />
            {user && user.active_company !== null && (
                <>
                    {loading && (
                        <Box p={2} style={{ width: '100%', textAlign: 'center' }}>
                            <BadgeCardLoader />
                        </Box>
                    )}
                    {!loading && badgesData && (
                        <Box
                            className={
                                props.isNavigation
                                    ? classes.NavbarBadgesContainer
                                    : classes.drawerBadgesContainer
                            }
                        >
                            {/* <Badges
                                isChatModal={false}
                                quantity_transacted={badgesData.quantity_transacted}
                                transactions_done={badgesData.transactions_done}
                                age_of_account={badgesData.age_of_account}
                            /> */}
                        </Box>
                    )}
                </>
            )}
            {/* Switch company with company details dialog and add company */}

            {((user && user.active_company !== 0) || !noCompany) &&
                !loading &&
                badgesData && (
                    <Box
                        className={classes.drawerCompanyInfo}
                        sx={{ margin: '8px 12px 12px' }}
                    >
                        <img
                            src="/images/icon-company.svg"
                            alt="company Name"
                            className="vAlignMiddle"
                        />
                        <Box>
                            <Box sx={{ mb: 0.75 }}>
                                <MuiLink
                                    color="primary"
                                    underline="none"
                                    //onClick={console.log('')}
                                    size="small"
                                    sx={{ fontWeight: 'bold', fontSize: '15px' }}
                                >
                                    {user?.company?.company_name}
                                </MuiLink>
                                <IconButton size="small" color="primary">
                                    <ChevronRightIcon fontSize="small" className="vAlignBottom" />
                                </IconButton>
                            </Box>

                            <Box sx={{ display: 'flex' }}>
                                <Rating
                                    value={
                                        user &&
                                        user.rating &&
                                        user?.rating !== null &&
                                        user?.rating?.avg_round
                                    }
                                    precision={0.5}
                                    readOnly
                                    sx={{ fontSize: '1.25rem' }}
                                />
                                <Box sx={{ ml: 1, px: 0.5 }}>
                                    {user?.rating?.avg_round}
                                </Box>
                                <Box pl={0.5} style={{ borderLeft: '1px solid #ccc' }}>
                                    {`${badgesData?.rating_data?.rating_comments.length > 1
                                        ? badgesData?.rating_data?.rating_comments.length +
                                        ' Reviews'
                                        : badgesData?.rating_data?.rating_comments.length +
                                        ' Review'
                                        }`}
                                </Box>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                                {user && user.active_company !== null && (
                                    <ProfileCompanyInfo isDrawer={true} />
                                )}

                                <MuiLink
                                    color="inherit"
                                    underline="none"
                                    onClick={(e) => {
                                        navigate('/add-company-eKyc');
                                        handleDrawerClose(e);
                                        handleMenuClose(e);
                                    }}
                                    target="_blank"
                                    size="small"
                                    className={`${classes.linkColor} pointer`}
                                    sx={{ marginLeft: 2.5, fontSize: { xs: '13px' } }}
                                >
                                    <AddIcon
                                        fontSize="small"
                                        className="vAlign-text-top"
                                        sx={{ fontSize: { xs: '13px' } }}
                                    />{' '}
                                    Add Company
                                </MuiLink>
                            </Box>
                        </Box>
                    </Box>
                )}
            {/* user.ekyc_status == 2 no company */}
            {user &&
                (user.active_company === null ||
                    noCompany ||
                    user.ekyc_status == 2) && <EkycPending />}
        </>
    );

    // Drawer menu lists for small screens
    const sideList = (side) => (
        <div
            className={`w-100 isRelative`}
            role="presentation"
        >
            <List>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space=between',
                        alignItems: 'center',
                    }}
                >
                    <ListItem button to="/" component={Link}>
                        <img src="/media/front/logo.png" style={{ maxWidth: 180 }} />
                    </ListItem>
                    <IconButton onClick={toggleDrawer('left', false)}>
                        <CancelIcon />
                    </IconButton>
                </Box>
                <Divider />

                {props.isLoggedIn && props.user && (
                    <>
                        <BadgesProfileCompanySwitch isDrawer={true} />
                        <ListItem
                            button
                            onClick={toggleDrawer('left', false)}
                            to={{ pathname: '/profile', search: '?ref=nav_profile' }}
                            component={Link}
                        >
                            <ListItemIcon>
                                <img src="/images/icon-My-Profile.svg" alt="My profile" />
                            </ListItemIcon>
                            <ListItemText>
                                <LanguageTranslate s={'My Profile'} />
                            </ListItemText>
                        </ListItem>
                        <Divider />

                        {/* <ListItem
                            button
                            onClick={toggleDrawer('left', false)}
                            to={{ pathname: '/deals-and-trades', search: '?ref=drawer_nav' }}
                            component={Link}
                        >
                            <ListItemIcon>
                                <img src="/images/icon-My-Sales.svg" alt="My Sales" />
                            </ListItemIcon>
                            <ListItemText>
                                <LanguageTranslate s={'My Deals'} />
                            </ListItemText>
                        </ListItem> */}

                        {/* <Divider />
                        <ListItem
                            button
                            onClick={toggleDrawer('left', false)}
                            to={{ pathname: '/re-post' }}
                            component={Link}
                        >
                            <ListItemIcon>
                                <img src="/images/icon-my-listings.svg" alt="RePost" />
                            </ListItemIcon>
                            <ListItemText className={classes._2col}>
                                <LanguageTranslate s="RePost" />
                            </ListItemText>
                        </ListItem> */}

                        <Divider />
                        {(user?.active_company == brandStoreId && user?.brand_store_enabled == true) &&
                            <ListItem
                                button
                                onClick={toggleDrawer('left', false)}
                                to="/dashboard/my-ratings"
                                component={Link}
                            >
                                <ListItemIcon>
                                    <img src="/images/icon-My-Ratings.svg" alt="My Ratings" />
                                </ListItemIcon>
                                <ListItemText className={classes._2col}>
                                    <LanguageTranslate s="My Ratings" />
                                </ListItemText>
                            </ListItem>
                        }
                        <Divider />
                        {/* <ListItem
                            button
                            onClick={toggleDrawer('left', false)}
                            to={{ pathname: '/my-safety-circle', search: '?ref=nav_profile' }}
                            component={Link}
                        >
                            <ListItemIcon>
                                <img src="/images/icon-My-Trusted-Companies.svg" alt="My References" />
                            </ListItemIcon>
                            <ListItemText className={classes._2col}>
                                <LanguageTranslate s="My References" />
                            </ListItemText>
                        </ListItem>
                        <Divider /> */}
                        {(user?.active_company == brandStoreId && user?.brand_store_enabled == true) &&
                            <ListItem
                                button
                                onClick={toggleDrawer('left', false)}
                                to={{ pathname: '/blocked-companies', search: '?ref=drawer_nav' }}
                                component={Link}
                            >
                                <ListItemIcon>
                                    <img src="/images/icon-Blocked-List.svg" alt="Blocked List" />
                                </ListItemIcon>
                                <ListItemText className={classes._2col}>
                                    <Box>
                                        <LanguageTranslate s="Blocked List" />
                                        <Box
                                            component="p"
                                            sx={{
                                                display: 'inline',
                                                background: '#85ce1f',
                                                color: '#fff',
                                                borderRadius: '8px',
                                                ml: 0.75,
                                                px: 0.75,
                                                fontSize: '11px',
                                            }}
                                        >
                                            New
                                        </Box>
                                    </Box>
                                </ListItemText>
                            </ListItem>
                        }
                        <Divider />
                        {(user?.active_company == brandStoreId && user?.brand_store_enabled == true) &&
                            <ListItem
                                button
                                component={Link}
                                to={{ pathname: '/contact', search: '?ref=drawer_nav' }}
                                onClick={toggleDrawer('left', false)}
                            >
                                <ListItemIcon>
                                    <img src="/images/icon-Contact-Us.svg" alt="Contact us" />
                                </ListItemIcon>
                                <ListItemText className={classes._2col}>
                                    <LanguageTranslate s="Contact Us" />
                                </ListItemText>
                            </ListItem>
                        }
                        <Divider />
                        {(user?.active_company == brandStoreId && user?.brand_store_enabled == true) &&
                            <ListItem
                                button
                                component={Link}
                                to="/profile/support-assigned-dealer"
                                onClick={toggleDrawer('left', false)}
                            >
                                <ListItemIcon>
                                    <img src="/images/icon-Support.svg" alt="Support" />
                                </ListItemIcon>
                                <ListItemText className={classes._2col}>
                                    <LanguageTranslate s="Support" />
                                </ListItemText>
                            </ListItem>
                        }
                        <Divider />
                        <ListItem
                            button
                            component={Link}
                            to={{ pathname: '/logout', search: '?ref=drawer_nav' }}
                            onClick={toggleDrawer('left', false)}
                        >
                            <ListItemIcon>
                                <img src="/images/icon-Logout.svg" alt="Logout" />
                            </ListItemIcon>
                            <ListItemText>
                                <LanguageTranslate s="Logout" />
                            </ListItemText>
                        </ListItem>
                    </>
                )}
                {!props.isLoggedIn && (
                    <>
                        <Box sx={{ p: 2, mb: 1.5 }}>
                            <Box
                                component="p"
                                sx={{
                                    fontSize: '19px',
                                    mb: 1,
                                    mt: 0,
                                    textTransform: 'capitalize !important',
                                }}
                            >
                                Join Us
                            </Box>
                            <Box sx={{ mb: 1.5 }}>
                                Register & add your GST to get an unrestricted, personalized
                                experience and alerts of the coal market.
                            </Box>
                            <Button
                                color="primary"
                                variant="contained"
                                size="large"
                                disableElevation
                                component={Link}
                                to={`/sign-up/${cEncrypt(0)}`}
                                onClick={toggleDrawer('left', false)}
                            >
                                Register for free!
                            </Button>
                        </Box>
                        <ListItem
                            button
                            to="/"
                            component={Link}
                            onClick={toggleDrawer('left', false)}
                        >
                            <ListItemIcon>
                                <HomeIcon />
                            </ListItemIcon>
                            <ListItemText>
                                <LanguageTranslate s="Home" />
                            </ListItemText>
                        </ListItem>
                        <ListItem
                            button
                            to={{ pathname: '/about-us', search: '?ref=drawer_nav' }}
                            component={Link}
                            onClick={toggleDrawer('left', false)}
                        >
                            <ListItemIcon>
                                <InfoIcon />
                            </ListItemIcon>
                            <ListItemText>
                                <LanguageTranslate s="About" />
                            </ListItemText>
                        </ListItem>
                        <ListItem
                            button
                            to={{ pathname: '/pricing-plans', search: '?ref=drawer_nav' }}
                            component={Link}
                            onClick={toggleDrawer('left', false)}
                        >
                            <ListItemIcon>
                                <LocalOfferIcon />
                            </ListItemIcon>
                            <ListItemText>
                                <LanguageTranslate s="Pricing" />
                            </ListItemText>
                        </ListItem>
                        <ListItem
                            button
                            to={{ pathname: '/markets', search: '?ref=drawer_nav' }}
                            component={Link}
                            onClick={toggleDrawer('left', false)}
                        >
                            <ListItemIcon>
                                <img
                                    src="/images/vessel-sIcon.svg"
                                    style={{ width: 18, marginLeft: 3 }}
                                    alt="Markets"
                                />
                            </ListItemIcon>
                            <ListItemText>
                                <LanguageTranslate s="Markets" />
                            </ListItemText>
                        </ListItem>

                        <ListItem
                            button
                            to={
                                user_mode === 1 ? '/requirements/buyer' : '/requirements/seller'
                            }
                            component={Link}
                            onClick={toggleDrawer('left', false)}
                        >
                            <ListItemIcon>
                                <AddBoxIcon />
                            </ListItemIcon>
                            <ListItemText>Add Listing</ListItemText>
                        </ListItem>
                        <ListItem
                            button
                            component={Link}
                            to={{ pathname: '/events', search: '?ref=drawer_nav' }}
                            onClick={toggleDrawer('left', false)}
                        >
                            <ListItemIcon>
                                <FormatListBulletedIcon />
                            </ListItemIcon>
                            <ListItemText>
                                <LanguageTranslate s="Events" />
                            </ListItemText>
                        </ListItem>
                        <ListItem
                            button
                            component={Link}
                            to={{ pathname: '/blog', search: '?ref=drawer_nav' }}
                            onClick={toggleDrawer('left', false)}
                        >
                            <ListItemIcon>
                                <FormatListBulletedIcon />
                            </ListItemIcon>
                            <ListItemText>
                                <LanguageTranslate s="Blog" />
                            </ListItemText>
                        </ListItem>
                        <ListItem
                            button
                            component={Link}
                            to={{ pathname: '/news', search: '?ref=drawer_nav' }}
                            onClick={toggleDrawer('left', false)}
                        >
                            <ListItemIcon>
                                <FormatListBulletedIcon />
                            </ListItemIcon>
                            <ListItemText>
                                <LanguageTranslate s="News" />
                            </ListItemText>
                        </ListItem>
                        <ListItem
                            button
                            component={Link}
                            to={{ pathname: '/contact', search: '?ref=drawer_nav' }}
                            onClick={toggleDrawer('left', false)}
                        >
                            <ListItemIcon>
                                <PermContactCalendarIcon />
                            </ListItemIcon>
                            <ListItemText>
                                <LanguageTranslate s="Contact Us" />
                            </ListItemText>
                        </ListItem>
                    </>
                )}
            </List>
        </div>
    );

    // Set mobile, Buyer and Seller menu dropdowns

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    // Close menu
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    // Renders user's menu when logged in
    const RenderProfileMenu = (props) => (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            id="user-menu"
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
        >
            <Box sx={{ display: 'flex' }} component="li">
                {/* Profile menu column 1 */}
                <Box sx={{ p: 1, borderRight: '1px solid #ddd' }}>
                    <BadgesProfileCompanySwitch isNavigation={true} />
                </Box>
                {/* Profile menu column 2 */}
                <Box>
                    {(user?.active_company == brandStoreId && user?.brand_store_enabled == true) &&
                        <>
                            <MenuItem
                                onClick={handleMenuClose}
                                component={Link}
                                to="/"
                            >
                                <ListItemIcon>
                                    <img src="/images/2-tone-logo.jpg" alt="CoalShastra logo" style={{ width: 20, }} />
                                </ListItemIcon>
                                <ListItemText>
                                    <LanguageTranslate s="CoalShastra" />
                                </ListItemText>
                            </MenuItem>
                            <Divider />
                        </>
                    }
                    <MenuItem
                        onClick={handleMenuClose}
                        component={Link}
                        to={{ pathname: '/profile', search: '?ref=nav_profile' }}
                    >
                        <ListItemIcon>
                            <img src="/images/icon-My-Profile.svg" alt="My profile" />
                        </ListItemIcon>
                        <ListItemText>
                            <LanguageTranslate s="My Profile" />
                        </ListItemText>
                    </MenuItem>
                    <Divider />
                    {/* 
                    {(user?.active_company == brandStoreId &&user?.brand_store_enabled == true) &&
                        <MenuItem
                            onClick={handleMenuClose}
                            component={Link}
                            to={{ pathname: `/brand-stores/${user?.brand_store_slug}` }}
                        >
                            <ListItemIcon>
                                <img src="/images/icon-my-listings.svg" alt="BrandStore" />
                            </ListItemIcon>
                            <ListItemText>
                                <LanguageTranslate s="My Brand Store" />
                            </ListItemText>
                        </MenuItem>
                    } */}
                    {(user?.active_company == brandStoreId && user?.brand_store_enabled == true) &&
                        <>
                            <MenuItem
                                onClick={handleMenuClose}
                                component={Link}
                                to={{ pathname: '/dashboard/my-listings' }}
                            >
                                <ListItemIcon>
                                    <img src="/images/icon-my-listings.svg" alt="My Sales" />
                                </ListItemIcon>
                                <ListItemText>
                                    <LanguageTranslate s="My Listings" />
                                </ListItemText>
                            </MenuItem>
                            <Divider />
                        </>
                    }

                    <MenuItem
                        onClick={handleMenuClose}
                        component={Link}
                        to={{ pathname: '/deals-and-trades', search: '?ref=nav_profile' }}
                    >
                        <ListItemIcon>
                            <img src="/images/icon-My-Sales.svg" alt="My Sales" />
                        </ListItemIcon>
                        <ListItemText>
                            <LanguageTranslate s={'Deals'} />
                        </ListItemText>
                    </MenuItem>
                    <Divider />
                    {/* <MenuItem
                        onClick={handleMenuClose}
                        component={Link}
                        to={{ pathname: '/re-post' }}
                    >
                        <ListItemIcon>
                            <img src="/images/icon-my-listings.svg" alt="RePost" />
                        </ListItemIcon>
                        <ListItemText>
                            <LanguageTranslate s="RePost" />
                        </ListItemText>
                    </MenuItem> */}

                    {(user?.active_company == brandStoreId && user?.brand_store_enabled == true) &&
                        <MenuItem
                            onClick={handleMenuClose}
                            component={Link}
                            to={{ pathname: '/dashboard/my-ratings' }}
                        >
                            <ListItemIcon>
                                <img src="/images/icon-My-Ratings.svg" alt="My Ratings" />
                            </ListItemIcon>
                            <ListItemText>
                                <LanguageTranslate s="My Ratings" />
                            </ListItemText>
                        </MenuItem>
                    }
                    <Divider />
                    <MenuItem
                        onClick={handleMenuClose}
                        component={Link}
                        to={{ pathname: '/my-safety-circle', search: '?ref=nav_profile' }}
                    >
                        <ListItemIcon>
                            <img
                                src="/images/icon-My-Trusted-Companies.svg"
                                alt="My References"
                            />
                        </ListItemIcon>
                        <ListItemText>
                            <LanguageTranslate s="My References" />
                        </ListItemText>
                    </MenuItem>

                    {(user?.active_company == brandStoreId && user?.brand_store_enabled == true) &&
                        <>
                            <Divider />
                            <MenuItem
                                onClick={handleMenuClose}
                                component={Link}
                                to={{ pathname: '/blocked-companies', search: '?ref=nav_profile' }}
                            >
                                <ListItemIcon>
                                    <img src="/images/icon-Blocked-List.svg" alt="Blocked List" />
                                </ListItemIcon>
                                <ListItemText>
                                    <LanguageTranslate s="Blocked List" />
                                    <Box
                                        component="span"
                                        sx={{
                                            background: '#85ce1f',
                                            color: '#fff',
                                            borderRadius: '8px',
                                            ml: 0.75,
                                            px: 0.75,
                                            fontSize: '11px',
                                        }}
                                    >
                                        New
                                    </Box>
                                </ListItemText>
                            </MenuItem>
                        </>
                    }

                    {(user?.active_company == brandStoreId && user?.brand_store_enabled == true) &&
                        <>
                            <Divider />
                            <MenuItem
                                onClick={handleMenuClose}
                                component={Link}
                                to={{ pathname: '/contact', search: '?ref=nav_profile' }}
                            >
                                <ListItemIcon>
                                    <img src="/images/icon-Contact-Us.svg" alt="Contact us" />
                                </ListItemIcon>
                                <ListItemText>
                                    <LanguageTranslate s="Contact Us" />
                                </ListItemText>
                            </MenuItem>
                        </>
                    }
                    {(user?.active_company == brandStoreId && user?.brand_store_enabled == true) &&
                        <>
                            <Divider />
                            <MenuItem
                                onClick={handleMenuClose}
                                component={Link}
                                to="/profile/support-assigned-dealer"
                            >
                                <ListItemIcon>
                                    <img src="/images/icon-Support.svg" alt="Support" />
                                </ListItemIcon>
                                <ListItemText>
                                    <LanguageTranslate s="Support" />
                                </ListItemText>
                            </MenuItem>
                        </>
                    }
                    <Divider />
                    <MenuItem onClick={handleMenuClose} component={Link} to="/logout">
                        <ListItemIcon>
                            <img src="/images/icon-Logout.svg" alt="Logout" />
                        </ListItemIcon>
                        <ListItemText>
                            <LanguageTranslate s="Logout" />
                        </ListItemText>
                    </MenuItem>
                </Box>
            </Box>
        </Menu>
    );

    // Open login dialog
    const loginDialog = () => {
        if (!props.loginModal) return <></>;

        return (
            <Dialog
                open={props.loginModal}
                onClose={(e) => {
                    props.setLoginModal(false);
                    ReactGA.event({ category: 'User', action: 'Closed Login Dialog' });
                }}
                aria-labelledby="form-dialog-title"
            >
                <LoginForm
                // onRedirect={path => { console.log(path) }}
                />
            </Dialog>
        );
    };

    // Open signup dialog
    const signUpDialog = () => {
        if (!props.signupModal) return <></>;

        return (
            <Dialog
                open={props.signupModal}
                onClose={(e) => {
                    props.setSignupModal(false);
                    ReactGA.event({ category: 'User', action: 'Closed Signup Dialog' });
                }}
                aria-labelledby="form-dialog-title"
            >
                <SignUp navbar={true} />
            </Dialog>
        );
    };

    return (
        <>
            <div className={`${classes.grow} ${classes.navRoot}`}>
                <Container maxWidth="lg" style={{ padding: 4 }}>
                    <AppBar
                        position="static"
                        elevation={0}
                        className={`${classes.appBarColor}`}
                    >
                        <Toolbar sx={{ minHeight: 'auto !important' }}>
                            {/* Menu button for drawer navigation for small and mobile screens */}
                            <Hidden only={['md', 'lg', 'xl']}>
                                <IconButton
                                    edge="start"
                                    className={classes.menuButton}
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={toggleDrawer('left', true)}
                                    size="large"
                                >
                                    <MenuIcon />
                                </IconButton>
                            </Hidden>

                            {/* Left column */}
                            {/* Application Logo */}
                            {/* <Hidden xsDown> */}
                            <Box className={classes.grow}>
                                {user?.active_company == brandStoreId && user?.brand_store_company?.logo &&
                                    <img
                                        src={user?.brand_store_company?.logo}
                                        alt="logo"
                                        style={{ width: 140, verticalAlign: 'top' }}
                                    />
                                }
                                {/* Desktop nav items for unauthenticated users */}


                            </Box>

                            {/* Right column */}
                            {/* Desktop nav items for..  */}
                            <div className={classes.sectionDesktop}>
                                <div className={`${classes.sectionDesktop} ${classes.root}`}>
                                    {/* ..authenticated users */}
                                    {props.isLoggedIn && (
                                        <>

                                            <Box
                                                sx={{
                                                    margin: '0 !important',
                                                    marginRight: '8px !important',
                                                }}
                                                id="myWalletIcon"
                                            >
                                                <IconButton
                                                    aria-label={`Inbox`}
                                                    size="small"
                                                    sx={{ borderRadius: '4px' }}
                                                    className={`${classes.btnUI} text-white`}
                                                    component={Link}
                                                    to={'/dashboard/inbox'}
                                                    onClick={(e) => {
                                                        storeCTAClick(e, 'wallet-icon');
                                                        return e;
                                                    }}
                                                >

                                                    <Badge
                                                        badgeContent={
                                                            counts?.inbox_unread_chat_count_data
                                                                ?.nav_inbox_unread_chat_count
                                                        }
                                                        color="secondary"
                                                        size="small"
                                                    >
                                                        <InboxIcon />
                                                    </Badge>
                                                </IconButton>
                                            </Box>
                                            {(user?.active_company == brandStoreId && user?.brand_store_enabled == true) &&
                                                <Box
                                                    sx={{
                                                        margin: '0 !important',
                                                        marginRight: '8px !important',
                                                    }}
                                                    id="myWalletIcon"
                                                >
                                                    <IconButton
                                                        aria-label={`My wallet`}
                                                        size="small"
                                                        sx={{ borderRadius: '4px' }}
                                                        className={`${classes.btnUI} text-white`}
                                                        component={Link}
                                                        to="/my-wallet"
                                                        onClick={(e) => {
                                                            storeCTAClick(e, 'wallet-icon');
                                                            return e;
                                                        }}
                                                    >
                                                        <AccountBalanceWalletIcon />
                                                    </IconButton>
                                                </Box>
                                            }
                                            <Box
                                                sx={{
                                                    margin: '0 !important',
                                                    marginRight: '8px !important',
                                                }}
                                                id="notificationsIcon"
                                            >
                                                <IconButton
                                                    aria-label={`Show ${props.unreadNotificationCount} new notifications`}
                                                    sx={{ borderRadius: '4px' }}
                                                    className={`${classes.btnUI} text-white`}
                                                    size="small"
                                                    component={Link}
                                                    to="/notifications"
                                                    onClick={(e) => {
                                                        handleClickNotifications(e);
                                                        storeCTAClick(e, 'notification-bell-icon');
                                                        return e;
                                                    }}
                                                >
                                                    <Badge
                                                        badgeContent={
                                                            props.unreadNotificationCount > 9
                                                                ? '9+'
                                                                : props.unreadNotificationCount
                                                        }
                                                        color="secondary"
                                                        size="small"
                                                    >
                                                        <NotificationsIcon />
                                                    </Badge>
                                                </IconButton>
                                            </Box>

                                            <Menu
                                                id="fade-menu"
                                                anchorEl={anchorElNotifications}
                                                keepMounted
                                                open={false}
                                                onClose={handleCloseNotifications}
                                                TransitionComponent={Fade}
                                            >
                                                {props.unreadNotifications &&
                                                    props.unreadNotifications.length > 0 &&
                                                    props.unreadNotifications
                                                        .slice(0, 9)
                                                        .map((notification, index) => (
                                                            <MenuItem key={index}>
                                                                {notification.title}
                                                            </MenuItem>
                                                        ))}
                                            </Menu>



                                            <Box
                                                sx={{
                                                    margin: '0 !important',
                                                    marginLeft: '8px !important',
                                                }}
                                                id="userInfoIcon"
                                            >
                                                <Button
                                                    edge="end"
                                                    size="small"
                                                    aria-label="User"
                                                    color="inherit"
                                                    aria-haspopup="true"
                                                    onClick={handleProfileMenuOpen}
                                                    variant="text"
                                                    className={`${classes.btnUI} text-white`}
                                                >
                                                    <AccountCircle /> &nbsp; {props.user.first_name}
                                                    <KeyboardArrowDownIcon />
                                                </Button>
                                            </Box>
                                        </>
                                    )}
                                </div>

                                {/* ..unauthenticated users */}
                                <>
                                    {loginDialog()}
                                    {signUpDialog()}

                                    {!props.isLoggedIn && (
                                        <div
                                            className={`${classes.sectionDesktop} ${classes.root}`}
                                        >
                                            <Button
                                                aria-label="Markets"
                                                aria-haspopup="true"
                                                color="inherit"
                                                className={`${location.pathname == '/markets'
                                                    ? classes.activeItem
                                                    : ''
                                                    } ${classes.navBtn2}`}
                                                variant="text"
                                                size="small"
                                                margin="normal"
                                                component={Link}
                                                to="/markets"
                                            >
                                                <LanguageTranslate s="Markets" />
                                            </Button>
                                            <Button
                                                aria-label="Benchmarks"
                                                aria-haspopup="true"
                                                color="inherit"
                                                className={`${location.pathname == '/guest/benchmark'
                                                    ? classes.activeItem
                                                    : ''
                                                    } ${classes.navBtn2}`}
                                                variant="text"
                                                size="small"
                                                margin="normal"
                                                component={Link}
                                                to="/guest/benchmark"
                                            >
                                                <LanguageTranslate s="Benchmarks" />
                                            </Button>
                                            <Button
                                                aria-label="plan and pricing menu"
                                                aria-haspopup="true"
                                                color="inherit"
                                                className={`${location.pathname == '/pricing-plans'
                                                    ? classes.activeItem
                                                    : ''
                                                    } ${classes.navBtn2}`}
                                                variant="text"
                                                size="small"
                                                margin="normal"
                                                component={Link}
                                                to="/pricing-plans"
                                            >
                                                <LanguageTranslate s="Pricing" />
                                            </Button>
                                            <Button
                                                aria-label="Events"
                                                aria-haspopup="true"
                                                color="inherit"
                                                className={`${location.pathname == '/events'
                                                    ? classes.activeItem
                                                    : ''
                                                    } ${classes.navBtn2}`}
                                                variant="text"
                                                size="small"
                                                margin="normal"
                                                component={Link}
                                                to="/events"
                                            >
                                                <LanguageTranslate s="Events" />
                                            </Button>
                                            <Button
                                                component={Link}
                                                to={`/login`}
                                                onClick={(e) => {
                                                    // props.setLoginModal(true);
                                                    ReactGA.event({
                                                        category: 'User',
                                                        action: 'Opened Login Dialog',
                                                    });
                                                }}
                                                className={`text-white`}
                                                size="small"
                                            >
                                                <LanguageTranslate s="Login " />
                                            </Button>

                                            <Button
                                                component={Link}
                                                to={`/sign-up/${cEncrypt(0)}`}
                                                onClick={(e) => {
                                                    //props.setSignupModal(true);
                                                    ReactGA.event({
                                                        category: 'User',
                                                        action: 'Opened Signup Dialog',
                                                    });
                                                }}
                                                //variant="outlined"
                                                className={classes.whiteButton}
                                                size="small"
                                            >
                                                <LanguageTranslate s="SIGNUP" />
                                            </Button>
                                        </div>
                                    )}
                                </>
                            </div>

                            {/* Mobile nav items for..  */}
                            <div className={classes.sectionMobile}>
                                {/* ..authenticated users */}
                                {props.isLoggedIn && (
                                    <Hidden mdUp>
                                        <Box mr={1}>
                                            <IconButton
                                                aria-label={`My wallet`}
                                                className="text-white"
                                                size="small"
                                                component={Link}
                                                to="/my-wallet"
                                                onClick={(e) => {
                                                    storeCTAClick(e, 'notification-bell-icon');
                                                    return e;
                                                }}
                                            >
                                                <AccountBalanceWalletIcon />
                                            </IconButton>
                                        </Box>
                                        <Box mr={1}>
                                            <IconButton
                                                aria-label={`Show ${props.unreadNotificationCount} new notifications`}
                                                sx={{ borderRadius: '4px' }}
                                                className={`${classes.btnUI} text-white`}
                                                size="small"
                                                component={Link}
                                                to="/notifications"
                                                onClick={(e) => {
                                                    handleClickNotifications(e);
                                                    storeCTAClick(e, 'notification-bell-icon');
                                                    return e;
                                                }}
                                            >
                                                <Badge
                                                    badgeContent={
                                                        props.unreadNotificationCount > 9
                                                            ? '9+'
                                                            : props.unreadNotificationCount
                                                    }
                                                    color="secondary"
                                                    size="small"
                                                >
                                                    <NotificationsIcon />
                                                </Badge>
                                            </IconButton>
                                        </Box>
                                    </Hidden>
                                )}

                                {/* ..unauthenticated users */}
                                {!props.isLoggedIn && (
                                    <>
                                        <Hidden mdUp>
                                            <Link to="/login">
                                                <Button className={`text-white`} size="small">
                                                    <LanguageTranslate s="Login" />
                                                </Button>
                                            </Link>

                                            <Link to={`/sign-up/${cEncrypt(0)}`}>
                                                <Button
                                                    variant="outlined"
                                                    className={`${classes.buttonWhiteOutlined} bg-white`}
                                                    size="small"
                                                >
                                                    <LanguageTranslate s="SIGNUP" />
                                                </Button>
                                            </Link>
                                        </Hidden>
                                    </>
                                )}
                            </div>
                        </Toolbar>
                    </AppBar>
                </Container>

                {/* Keep profile menu hidden for small screens */}
                <Hidden mdDown>
                    <RenderProfileMenu />
                </Hidden>

                {/* Closing the drawer if clicked anywhere */}
                <Drawer
                    open={state.left}
                    onClose={toggleDrawer('left', false)}
                    PaperProps={{ sx: { width: ['100%', 540] } }}
                >
                    {sideList('left')}
                </Drawer>


                {/* edit profile dialog */}
                <Dialog
                    open={editProfileDialogOpen}
                    keepMounted
                    TransitionComponent={Transition}
                    onClose={(e) => {
                        editProfileDialogOpenSet(false);
                    }}
                >
                    <IconButton
                        aria-label="close"
                        className="closeBtn"
                        size="small"
                        //color="secondary"
                        onClick={(e) => {
                            editProfileDialogOpenSet(false);
                        }}
                    >
                        <CancelIcon />
                    </IconButton>
                    <DialogTitle id="alert-dialog-title">Edit Profile</DialogTitle>
                    <DialogContent dividers={true}>
                        <EditProfileDialog
                            user={user}
                            cancelButton={true}
                            onClose={(e) => {
                                editProfileDialogOpenSet(false);
                            }}
                        />
                    </DialogContent>
                </Dialog>

            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.userReducer.user,
        isLoggedIn: state.userReducer.isLoggedIn,
        messagesCount: state.userReducer.messagesCount,
        unreadNotificationCount: state.userReducer.unreadNotificationCount,
        unreadNotifications: state.userReducer.unreadNotifications,
        loginModal: state.commonReducer.loginModal,
        signupModal: state.commonReducer.signupModal,
    };
};

export default connect(mapStateToProps, {
    fetchUnreadNotificationCount,
    checkAndLoadUser,
    loginUser,
    logoutUser,
    setLoginModal,
    setSignupModal,
})(NavbarV2);
