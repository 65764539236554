import React from 'react';
import Skeleton from '@mui/material/Skeleton';

export default function SmCardLoader(props) {
    return (
        <div className="card-skeleton" style={{marginBottom: "20px"}}>
            <Skeleton
                animation='wave'
                variant="rounded"
                {...props.loaderProps}
            />
        </div>
    );
}
