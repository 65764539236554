import { io } from 'socket.io-client';
import { getAdminCommUserToken, getCommUserToken } from './helpers';
import { Navigate } from 'react-router-dom';

// "undefined" means the URL will be computed from the `window.location` object
// const URL = process.env.NODE_ENV === 'production' ? undefined : 'http://localhost:3001';
const URL = process.env.REACT_APP_SOCKET_URL;
const userToken = getCommUserToken();
const extraHeaders = userToken ? { Authorization: `Bearer ${userToken}` } : {};

export const socket = io(URL, {
    autoConnect: false,
    extraHeaders,
});

// const adminUserToken = getAdminCommUserToken('commconsole');
// const URL = process.env.REACT_APP_SOCKET_URL;
// const extraHeadersAdmin = adminUserToken ? { Authorization: `Bearer ${adminUserToken}` } : {};

// Object.assign(extraHeadersAdmin, {isadmin: true})
// const extraHeadersAdmin = {isadmin: true}

export const socketAdmin = io(URL, {
    autoConnect: false,
    // extraHeaders:extraHeadersAdmin,
});

export const connectWS = (userType = '', extraHeadersAdmin='') => {
    console.log({userType, extraHeadersAdmin})
    if(userType === 'admin') {
        socketAdmin.io.opts.extraHeaders = extraHeadersAdmin;
        socketAdmin.connect();
        
        socketAdmin.on('connect', () => {
            console.log('Connected admin to the server successfully.', socketAdmin);
        });
        
        socketAdmin.on('connect_error', (error) => {
            console.error('Connection admin error:', error);
        });
        
        socketAdmin.on('disconnect', (reason) => {
            console.log('Disconnected admin from the server:', reason);
        });
    } else {
        socket.connect();
        socket.emit('joinRoom', 'tempCommRoom1');
        console.log('join room called', {socket});
        socket.on('connect', () => {
            console.log('Connected to the server successfully.');
        });
    
        socket.on('connect_error', (error) => {
            console.error('Connection error:', error);
        });
    
        socket.on('disconnect', (reason) => {
            console.log('Disconnected from the server:', reason);
        });
    
        // socket.on('userStatus', (data) => {
        //     console.log('user live status', {data, status:data.status, user: data.userId});
        //     // updateUserStatus(data.userId, data.status);
        // });

        // socket.on('userBlocked', (data) => {
        //     console.log('socket user blocked')
        //     if(data.status === 1) {
        //         console.log('logging out userrr')
        //         localStorage.removeItem('comm_user_token');
        //     }
        // })
    }
    // socket.emit('joinRoom', "roomId_usr_" + this.props.user._id);
    // socket.on('joinedRoom', (room) => {
    //     console.log({joinedRoom: room});
    //     // socket.emit('msgToRoom', {room, message: {title: "title", body: "body"}});
    // });
    // socket.on('msgToClient', (message) => {
    //     console.log('msgToClient', message);
    // });
    // const userToken = getUserToken();
    // if(userToken) {
    //     connectSocket(userToken);
    // }
}

// const updateUserStatus = (userId, status) => {
//     let onlineUsers = JSON.parse(localStorage.getItem('onlineUsers')) || [];
//     if(status === 'online') {
//         !onlineUsers.includes(userId) && onlineUsers.push(userId);
//     } else if(status === 'offline') {
//         onlineUsers = onlineUsers.filter(user => user !== userId)
//     }

//     localStorage.setItem('onlineUsers', JSON.stringify(onlineUsers));
// }

// export const getOnlineUsers = () => {
//     return JSON.parse(localStorage.getItem('onlineUsers')) || [];
// }