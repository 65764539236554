import React, { useState, useEffect } from 'react'
import Box from "@mui/material/Box";
import ContentLoader from "react-content-loader"
import { User } from '../../api/resources/User'
import Rating from '@mui/material/Rating';
import makeStyles from '@mui/styles/makeStyles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { ViewChatLinkBtn, ListingBidOfferBtn, SentBtn } from './ListingActionButtons'
import { useSelector } from 'react-redux'
import Emitter from '../../services/emitter';
import genOfferAndBidMessage from '../../components/partials/chat/genOfferAndBidMessage'

const useStyles = makeStyles(theme => ({
    listingItem: {
        display: 'grid',
        // gridTemplateColumns: '23px 120px 136px 108px 100px 90px 106px',
        gridTemplateColumns: '22px 120px 136px 108px 85px 88px 106px',
        gridGap: '8px',
        alignItems: 'center',
        color: '#000',
        // fontWeight: 'bold',
        padding: '8px 0',
    },
    textMuted: {
        color: "#999999",
        fontSize: 12,
        fontWeight: 'normal'
    },
    listingItem1: {
        display: 'grid',
        // gridTemplateColumns: '23px 120px 136px 108px 100px 90px 106px',
        gridTemplateColumns: '20px 135px 117px 102px 195px auto',
        gridGap: '8px',
        alignItems: 'center',
        color: '#000',
        fontWeight: 'bold',
        padding: '8px 0',
    },
    topSpacing: {
        marginTop: theme.spacing(1.8)
    },
    btnBadge: {
        backgroundColor: '#d4def1',
        borderRadius: '18px',
        fontSize: 12,
        display: 'inline-block',
        textAlign: 'center',
        padding: '3px 10px'
    }
}))

export default function MatchedListingItems(props) {
    const classes = useStyles();
    //console.log(props)
    // console.log(props?.postingListingData?.currency)
    const user_mode = useSelector(state => state.userReducer.user_mode);
    const user = useSelector(state => state.userReducer.user);
    const postingListingData = props.postingListingData;
    const deliveryTermId = '2'
    const [messageLength, setMessageLength] = useState(0);
    const [message, setMessage] = useState("");
    const [deliveryTerm, deliveryTermSet] = useState(null);
    const [visible, setVisible] = useState(false);
    const [showViewChat, setShowViewChat] = useState(false);
    const [showSentBtn, showSentBtnSet] = useState(false);
    const [fetching, fetchingSet] = React.useState(false);

    const prepareAndSubmitMessage = (data) => {

        const msgProps = {
            product_type: props.listing.product_type,
            currency: props?.postingListingData?.currency
        }
        let msgData = {
            price: 0,
            lifting: 0,
            paymentMode: '',
            quantity: data.quantity,
            validityTime: postingListingData?.validity_time ? postingListingData?.validity_time : 30,
            deliveryTerm: (props.listing?.origin_of_coal == 1 && props.listing?.coal_type_id == 5) ? 'FOB (free on board)' : (props.listing?.origin_of_coal == 1 && props.listing?.coal_type_id != 5) ? 'Ex-mine/depo' : (props.listing?.origin_of_coal == 2) && 'Ex-port'
        }

        if (data.price && data.price.length > 0) {
            msgData.price = props.isTopListings == true ? postingListingData?.price : postingListingData?.price[0]?.product_price;
            msgData.lifting = props.isTopListings == true ? postingListingData?.lifting_days : postingListingData?.price[0]?.days;
            msgData.paymentMode = props.isTopListings == true ? postingListingData?.payment_text : postingListingData?.price[0]?.payment_term_name;
            msgData.comment = props.isTopListings == true ? postingListingData?.comment : '';
            msgData.advance = props.isTopListings == true ? postingListingData?.advance : postingListingData?.price[0]?.advance;
            msgData.balance = props.isTopListings == true ? postingListingData?.balance : postingListingData?.price[0]?.balance;
            msgData.creditDays = props.isTopListings == true ? postingListingData?.no_credit_days : '';
            msgData.paymentDays = props.isTopListings == true ? postingListingData?.payment_days : '';
            msgData.paymentModeId = props.isTopListings == true ? postingListingData?.payment_id : postingListingData?.price[0]?.payment_term_id
        }

        const message = genOfferAndBidMessage(msgProps, msgData);

        setMessage(message)
    }

    useEffect(() => {
        if (postingListingData) {
            prepareAndSubmitMessage(postingListingData);
        }

        if (visible) {
            var params = {
                company_id: props.listing.chat_company_id,
                listing_id: props.listing.listing_id
            }
            fetchChats(params);

            let interval = setInterval(() => {
                fetchChats(params);
            }, 3000);

            return () => clearInterval(interval);
        }
    }, [visible, postingListingData, message, props.postedProductType]);


    const fetchChats = (params) => {
        User.chats
            .fetch(params)
            .then(response => {
                if (response.data.status == "success") {
                    setMessageLength(response.data?.data?.length);
                    if (response.data.status === 'success') {
                        showSentBtnSet(true);
                    }
                    if (response.data?.data?.length === 1) {
                        setTimeout(() => {
                            setVisible(false);
                            setShowViewChat(true);
                        }, 2000);
                    }

                }
            });
    };

    const sendMessageHttpReq = (data) => {
        //sendingMessageSet(true)
        fetchingSet(true);
        var postData = {
            company_id: props.listing.chat_company_id,
            listing_id: props.listing.listing_id,
            session_company_id: user.company_id,
            message: message,
            action: data.action ? data.action : 0,
            reason_ids: [],
            price: props.isTopListings == true ? postingListingData?.price : postingListingData?.price[0]?.product_price,
            quantity: postingListingData?.quantity,
            lifting_days: props.isTopListings == true ? postingListingData?.lifting_days : postingListingData?.price[0]?.days,
            payment_id: props.isTopListings == true ? postingListingData?.payment_id : postingListingData?.price[0]?.payment_term_id,
            payment_text: props.isTopListings == true ? postingListingData?.payment_text : postingListingData?.price[0]?.payment_term_name, // todo
            delivery_term_id: props.isTopListings == true ? postingListingData?.delivery_term_id : deliveryTermId,
            advance: props.isTopListings == true ? postingListingData?.advance : postingListingData?.price[0]?.advance,
            balance: props.isTopListings == true ? postingListingData?.balance : postingListingData?.price[0]?.balance,
            no_credit_days: props.isTopListings == true ? postingListingData?.no_credit_days : postingListingData?.num_credit_days,
            validity_time: props.isTopListings == true ? postingListingData?.validity_time : 30,
            comment: props.isTopListings == true ? postingListingData?.comment : '',
            is_top_five: props.isTopListings == true ? 1 : 0,
            initiated_from: 0,
            source: 2
        }
        User.chats
            .sendMessage(postData)
            .then(response => {
                if (response.data.status === "success") {
                    //submittedNextActionSet(true)
                    setVisible(true);
                    fetchingSet(false);
                    Emitter.emit('CHAT_MESSAGE_SENT', postData);
                }
                if (response.data.status === "fail") {
                    fetchingSet(false);
                }
            })
            .finally(() => {

            });
    }

    const ListLoader = () => (
        <ContentLoader
            speed={2}
            style={{ width: '100%' }}
            //   backgroundColor="#f3f3f3"
            //   foregroundColor="#ecebeb"
            height={20}
        >
            <circle cx="5" cy="10" r="4" />
            <rect x="18" y="5" rx="0" ry="0" width="100%" height="2" />
            <rect x="18" y="10" rx="0" ry="0" width="47" height="1" />
        </ContentLoader>
    )

    // const isSelfListing = props.isTopListings && (user.active_company == props.listing.company_id)

    return (
        <>
            {props.loadingMatchedListing && [1, 2, 3].map(i => <ListLoader key={i} />)}
            {(!props.loadingMatchedListing && !props.matchesViewport) ?
                <Box component="li"
                    className={(visible || showViewChat || (props.listing.has_offer === 1 && props.isTopListings == false)) ?
                        classes.listingItem : props.isTopListings == true ? classes.listingItem1 : classes.listingItem} key={props.key}>
                    {(visible || showViewChat || props.listing.has_offer === 1) ?
                        <Box>
                            <CheckCircleIcon style={{ fontSize: '20px', fill: '#85ce1f' }} />
                        </Box>
                        : <Box>
                            {' '}
                        </Box>
                    }
                    {props.isTopListings != true &&
                        <Box>
                            <PersonOutlineIcon />
                            <Rating
                                value={(props.listing && props.listing.average_rating) && props.listing.average_rating.toPrecision(3)}
                                precision={0.5}
                                readOnly
                                size='small'
                            />
                        </Box>
                    }

                    {props.listing.price && props.listing.price.length > 0 ?
                        <Box>
                            {props.isTopListings == true && <Box className={classes.textMuted}>Payment Terms</Box>}
                            <strong>{props.listing.price[0].payment_term_name}&nbsp;</strong>
                            <strong>
                                {
                                    props.listing.price[0].payment_term_id === 1 ? "" :
                                        props.listing.price[0].payment_term_id === 3 ? (`(${props.listing.num_credit_days} Days)`) :
                                            props.listing.price[0].payment_term_id === 4 ? `(Adv: ${props.listing.cc_percentage}%)` : ''}
                            </strong>
                        </Box> : ''
                    }
                    <Box>
                        {props.isTopListings == true && <Box className={classes.textMuted}> Lifting Days</Box>}
                        <strong>{props.listing.price[0].days} Lifting Days</strong>
                    </Box>
                    <Box>
                        {props.isTopListings == true && <Box className={classes.textMuted}>Quantity</Box>}
                        <img src="/images/coal-cart-sm.svg" alt="" width='10' /> <strong>{props.listing.quantity} MT</strong>
                    </Box>
                    <Box className={props.isTopListings == true ? '' : classes.btnBadge}>
                        {props.isTopListings == true && <Box className={classes.textMuted}>Price</Box>}
                        <strong>{props.listing.currency} {props.listing.price[0].product_price}/MT</strong>
                    </Box>

                    {/* Action Buttons */}
                    <Box>
                        {/* Render labels further actions conditionally for bid/offer */}
                        {(props.listing.has_offer === 0 && !visible && !showViewChat) &&
                            <ListingBidOfferBtn
                                label={props.listing.product_type == 1 ? 'Offer' : 'Bid'}
                                fetching={fetching}
                                sessionUserId={props.listing.session_user_id}
                                onClickEvent={e => {
                                    // sendMessageHttpReq({ action: user_mode == 1 ? 2 : 1 });
                                    sendMessageHttpReq({ action: props.postedProductType });
                                }}
                                actionBtnProps={{ variant: "contained" }}
                            />
                        }

                        {/* Render this once the bid/offer is sent */}
                        {visible &&
                            <SentBtn
                                label={'Sent'}
                                style={{ backgroundColor: '#85ce1f' }}
                                onClickEvent={e => { console.log('Sent Success!!') }}
                                actionBtnProps={{ variant: "contained", startIcon: <CheckCircleIcon /> }}
                            />
                        }

                        {/* Render View chat after few seconds of rendering sent button  */}
                        {(showViewChat || props.listing.has_offer === 1) &&
                            <ViewChatLinkBtn
                                onClickEvent={e => {
                                    props.setChatData({
                                        show: true,
                                        listing_id: props.listing.listing_id,
                                        chat_company_id: props.listing.chat_company_id,
                                        product: props.listing,
                                        // product_type: user_mode == 1 ? 2 : 1,
                                        product_type: props.listing.product_type,
                                        productCompanyId: props.listing.company_id,
                                        productId: props.listing.listing_id,
                                        currency: props?.postingListingData?.currency
                                    })
                                }
                                }
                            />
                        }
                    </Box>
                </Box>
                :
                <Box component='li' className={props.matchesViewport ? `${classes.topSpacing}` : ''} sx={{ listStyle: 'none' }}>
                    <Box sx={{ display: "flex", mb: 1 }}>
                        {(visible || showViewChat || props.listing.has_offer === 1) ?
                            <Box>
                                <CheckCircleIcon style={{ fontSize: '20px', fill: '#85ce1f' }} />
                            </Box>
                            : <Box>
                                {' '}
                            </Box>
                        }
                        <PersonOutlineIcon />
                        <Rating
                            value={props.listing?.average_rating?.toPrecision(3)}
                            precision={0.5}
                            readOnly
                            size='small'
                        />
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                        <Box>{props.listing.price[0].days} Lifting Days</Box>
                        {props.listing.price && props.listing.price.length > 0 ?
                            <Box>
                                {props.listing.price[0].payment_term_name}&nbsp;
                                <small className={classes.textMuted}>
                                    {
                                        props.listing.price[0].payment_term_id === 1 ? "" :
                                            props.listing.price[0].payment_term_id === 3 ? (`(${props.listing.num_credit_days} Days)`) :
                                                props.listing.price[0].payment_term_id === 4 ? `(Adv: ${props.listing.cc_percentage}%)` : ''}
                                </small>
                            </Box> : ''
                        }
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                        <Box><img src="/images/coal-cart-sm.svg" alt="" width='10' /> {props.listing.quantity} MT</Box>
                        <Box className={classes.btnBadge}><img src="/images/rupee.svg" alt="" width='6' />  {props.listing.price[0].product_price}/MT</Box>
                    </Box>

                    {/* Action Buttons */}
                    <Box sx={{ textAlign: 'center', mt: 2, mb: 1 }}>

                        {/* Render labels further actions conditionally for bid/offer */}
                        {(props.listing.has_offer === 0 && !visible && !showViewChat) &&
                            <ListingBidOfferBtn
                                label={props.listing.product_type == 1 ? 'Offer' : 'Bid'}
                                fetching={fetching}
                                sessionUserId={props.listing.session_user_id}
                                onClickEvent={e => {
                                    // sendMessageHttpReq({ action: user_mode == 1 ? 2 : 1 });
                                    sendMessageHttpReq({ action: props.postedProductType });
                                }}
                                actionBtnProps={{ variant: "contained" }}
                            />
                        }

                        {/* Render this once the bid/offer is sent */}
                        {visible &&
                            <SentBtn
                                label={'Sent'}
                                style={{ backgroundColor: '#85ce1f' }}
                                onClickEvent={e => { console.log('Sent Success!!') }}
                                actionBtnProps={{ variant: "contained", startIcon: <CheckCircleIcon /> }}
                            />
                        }

                        {/* Render View chat after few seconds of rendering sent button  */}
                        {(showViewChat || props.listing.has_offer === 1) &&
                            <ViewChatLinkBtn
                                onClickEvent={e => {
                                    props.setChatData({
                                        show: true,
                                        listing_id: props.listing.listing_id,
                                        chat_company_id: props.listing.chat_company_id,
                                        product: props.listing,
                                        // product_type: user_mode == 1 ? 2 : 1,
                                        product_type: props.listing.product_type,
                                        productCompanyId: props.listing.company_id,
                                        productId: props.listing.listing_id,
                                        currency: props?.postingListingData?.currency
                                    })
                                }
                                }
                            />
                        }
                    </Box>
                </Box>

            }
        </>
    )
}
