import React from "react";
import { connect, useDispatch } from "react-redux";
import { checkAndLoadUser } from '../../redux/actions/userActions';
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { config } from "../../config";
import eventsTracking from "../../eventsTracking";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { ValidatorForm } from "react-material-ui-form-validator";
import { alpha } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import Masonry from "react-masonry-css";
import ErrorHandler from "../partials/ErrorHandler";
import { User } from "../../api/resources/User";
import Hidden from "@mui/material/Hidden";
import { debounce } from "lodash";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { AlertTitle, Avatar, Checkbox, FormControl, FormControlLabel, FormGroup, Typography } from "@mui/material";
import OnLoadTransition from "../partials/OnLoadTransition";
import CardLoader from "../partials/content-loaders/CardLoader";
import { useSelector } from "react-redux";
import { CommodityCommon } from "../../api/resources/admin-api/CommodityCommon";
import CommodityItem from "./CommodityItem";
import { CommodityUser } from "../../api/resources/CommodityUser";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CheckBox } from "@mui/icons-material";
import trackingEvents from "../../eventsTracking";

const useStyles = makeStyles((theme) => ({
    commodityTypeHeading: {
        background: theme.palette.primary.light,
        color: theme.palette.primary.contrastText,
        padding: "10px",
    },
    grow: {
        flexGrow: 1,
    },
    search: {
        position: "relative",
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 1),
        marginRight: theme.spacing(2),
        marginLeft: 0,
        marginTop: 0,
        width: "100%",
        overflow: "hidden",
        [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing(3),
            width: "auto",
            marginTop: theme.spacing(3),
        },
    },
    searchIcon: {
        position: "absolute",
        pointerEvents: "none",
        top: "9px",
        right: "5px",
    },
    inputRoot: {
        color: "inherit",
        display: "inline",
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 1),
        border: "1px solid #CCCCCC",
        transition: theme.transitions.create("width"),
        width: "250px",
    },
}));

function SelectCommodity(props) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const navigate = useNavigate();
    const { search } = useLocation();
    const query = new URLSearchParams(search);
    // const tickSize = useSelector((state) => state.commonReducer.globalSettings.select_markets_limit);
    const tickSize = 50;

    const pleaseSelectMarket = query.get("pleaseSelectMarket");
    const [commodities, setCommodities] = React.useState([]);
    const [commodityTypes, commodityTypesSet] = React.useState([]);
    const [selectedCommodities, selectedCommoditiesSet] = React.useState([]);
    const [commoditiesLoading, setCommoditiesLoading] = React.useState(false);
    const [submitting, setSubmitting] = React.useState(false);
    const [isAccordionExpanded, setAccordionExpanded] = React.useState(true);
    const [expandedAccordions, setExpandedAccordions] = React.useState({});
    const [preferencesSuccess, setPreferencesSuccess] = React.useState(false);
    const [preferenceId, setPreferenceId] = React.useState(null);
    const [preferenceIdDefaultVal, setPreferenceIdDefaultVal] = React.useState(null);
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [apiErrorResponse, setApiErrorResponse] = React.useState(null);
    const [searchKeyword, setSearchKeyword] = React.useState("");
    const [response, responseSet] = React.useState("");
    const [selectAllCommodity, setSelectAllCommodity] = React.useState([])
    const [redirect, setRedirect] = React.useState(true);
    const [checkboxClickSign, setCheckboxClickSign] = React.useState([])
    const [checkSignAllSelectedCommodity, setCheckSignAllSelectedCommodity] = React.useState(false)
    const [snackbarAlert, snackbarAlertSet] = React.useState({
        message: "",
        type: "success",
    });
    const [commodityTypeChecked, setCommodityTypeChecked] = React.useState(()=> {
        return commodityTypes && commodityTypes.length > 0 ?
        { [commodityTypes[0]._id] : false} : 
        {}
    });

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpenSnackbar(false);
    };

    const fetchcommodities = async (params) => {
        setCommoditiesLoading(true);
        dispatch({ type: "LOADER", payload: { loader: true } });
        params = Object.assign(
            {
                page: 1,
                limit: 1000,
            },
            params
        );

        try {
            const res = await CommodityCommon.Commodities(params);

            if (res.status == 200) {
                setCommodities(res.data.data);
                setCheckboxClickSign(res.data.data)
                
              
                let tempCommodityGroups = [];
                res.data.data.map(c => {
                    if (tempCommodityGroups.findIndex(t => t._id == c.commodityType._id) == -1) {
                        tempCommodityGroups.push(c.commodityType)
                    }
                })
                commodityTypesSet([...tempCommodityGroups.sort((a, b) => a.name > b.name ? 1 : -1)]);
          
            }

        } catch (e) {
            console.error({ commoditiesError: e });
        }
        dispatch({ type: "LOADER", payload: { loader: false } });
        setCommoditiesLoading(false);
    };
// selected length
    const fetchSelectedCommodities = async (params) => {
        setCommoditiesLoading(true);
        dispatch({ type: "LOADER", payload: { loader: true } });

        try {
            const res = await CommodityUser.userCommodities();
    
            if (res.status == 200) {
                selectedCommoditiesSet(res.data.data.selectedCommodities.map((m) => m._id));
            }
        } catch (e) {
            console.error(e);
        }
        dispatch({ type: "LOADER", payload: { loader: false } });
        setCommoditiesLoading(false);
    };

    React.useEffect(() => {
        fetchcommodities();
     
        fetchSelectedCommodities();


    }, []);

    const storeMarketsPreference = async () => {
        if (selectedCommodities.length == 0) {
            setApiErrorResponse("Please select at least 1 commodity.");
            return;
        }
        setSubmitting(true);
        if (selectAllCommodity.length > 0) {
            let params = {
                selectedCommodityIds: selectAllCommodity,
            };

            try {
                const res = await CommodityUser.storeCommodities(params);
                console.log('storeAllCommodities: ', res);
                if (res.status == 200) {
                    props.checkAndLoadUser();
                    setTimeout(() => {
                        navigate("/markets");
                    }, 1500)

                    setPreferencesSuccess(true);
                    snackbarAlertSet({
                        message: "All commodities have been selected and submitted successfully.",
                        type: "success",
                    });
                    setOpenSnackbar(true);
                    setApiErrorResponse("");
                    fetchcommodities();

                } else {
                    setApiErrorResponse(res.data.message);
                }
                if (res.data.status === "fail") {
                    setApiErrorResponse(res.data.message);
                }
                setSubmitting(false);
            } catch (e) {
                console.error(e);
            }
            setSubmitting(false);
        }
        else if (selectAllCommodity.length === 0 && selectedCommodities) {
            let params = {
                selectedCommodityIds: selectedCommodities,
            };

            try {
                const res = await CommodityUser.storeCommodities(params);
                console.log('storeEachCommodities', res)
                if (res.status == 200) {
                    props.checkAndLoadUser();
                    setTimeout(() => {
                        navigate("/markets");
                    }, 1500)

                    setPreferencesSuccess(true);
                    snackbarAlertSet({
                        message: "Your selected commodities have been submitted successfully.",
                        type: "success",
                    });
                    setOpenSnackbar(true);
                    setApiErrorResponse("");
                    fetchcommodities();

                } else {
                    setApiErrorResponse(res.data.message);
                }
                if (res.data.status === "fail") {
                    setApiErrorResponse(res.data.message);
                }
                setSubmitting(false);
            } catch (e) {
                console.error(e);
            }
            setSubmitting(false);
        }
    };

    const storeUserResponse = async () => {
        try {
            const res = await User.captureUserResponse({ platform_type: 2 });
            if (res.data.status == "success") {
                responseSet(res.data.data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    React.useEffect(() => {
        if (preferenceId !== null) {
            storeMarketsPreference();
        }
    }, [preferenceId]);

    React.useEffect(() => {
        if (pleaseSelectMarket) {
            setApiErrorResponse("Please select at least 1 commodity.");
        }
    }, [pleaseSelectMarket]);

    React.useEffect(() => {
        document.title = "Select Commodities | Mandi.trade";
        // eventsTracking.selectMarket(searchKeyword);
    }, []);

    React.useEffect(()=>{
        if (checkSignAllSelectedCommodity === true) {
            // sessionStorage.setItem("checkAllCommoditySubmit", "clicked")
            setSelectAllCommodity(commodities.map((item) => item._id));
            setCheckboxClickSign(commodities.map((item) => item._id));
        } else {
            setSelectAllCommodity([])
            setCheckboxClickSign([])
        }
    },[checkSignAllSelectedCommodity])

    const CommodityTypeAccordion = ({ commodityType }) => {
        const isExpanded = expandedAccordions[commodityType._id] || false;
        // const [checked, setChecked] = React.useState(false);
        const checked = commodityTypeChecked[commodityType._id] || false;

        const handleAccordionOpen = () => {
            setExpandedAccordions(prev => ({
                ...prev,
                [commodityType._id]: !isExpanded
            }));
        }

        const handleCommodityTypeChecked = () => {
            setCommodityTypeChecked(prev => ({
                ...prev,
                [commodityType._id]: !checked
            }));

            const filteredCommIds = commodities
            .filter(com => com.commodityTypeId === commodityType._id)
            .map(c => c._id);
            
            if(!checked) {
                selectedCommoditiesSet(prev => Array.from(new Set([...prev, ...filteredCommIds])));
            } else {
                selectedCommoditiesSet(prev => prev.filter(id => !filteredCommIds.includes(id)))
            }
        }

        // React.useEffect(() => {
        //     console.log('checekd use eff set select all commodity')
        //     if(checked === true) {
        //         const filteredCommIds = commodities.filter(c => c.commodityTypeId == commodityType._id).map(c => c._id);
        //         setSelectAllCommodity(prev => {
                    
        //         })
        //     }
        // }, [checked])

        return (
            <Accordion
                style={{ marginTop: 5}}
                mb={5}
                mt={5}
                my={2}
                key={commodityType._id}
                // expanded={isAccordionExpanded}
                // onChange={() => setAccordionExpanded(!isAccordionExpanded)}
                expanded={isExpanded}
                onChange={handleAccordionOpen}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="commodityType-content"
                    id="commodityType-header"
                >
                    <Box sx={{ display: "flex", alignItems: "center", gap: 2, }}>
                        <Box>
                            <Checkbox
                                checked={checked}
                                onChange={handleCommodityTypeChecked}
                            />
                            {/* <Button onClick={()=>console.log(selectAllCommodity)}>selectAllCommodity</Button>
                            <Button onClick={()=>console.log(selectedCommodities)}>selectedCommodities</Button> */}
                        </Box>
                        {(commodityType && commodityType.images && commodityType.images.length > 0) ? <Avatar
                            sx={{ width: 36, height: 36, objectFit: "cover", border: '1px solid #ddd' }}
                            src={`${process.env.REACT_APP_API_URL_COMMODITY}/${commodityType.images[0].url}`}
                            alt={commodityType.images[0].filename}
                        /> : <Avatar
                            sx={{ width: 36, height: 36, objectFit: "cover", border: '1px solid #ddd' }}
                            src={"https://m.economictimes.com/thumb/msid-71386127,width-1200,height-900,resizemode-4,imgsize-100771/commodity-getty.jpg"}
                            alt={commodityType.name}
                        />}
                        <Typography variant='h5'>
                            {commodityType.name}
                        </Typography>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <Masonry
                        breakpointCols={{
                            default: 4,
                            1600: 4,
                            1100: 3,
                            700: 2,
                            500: 1,
                        }}
                        className="my-masonry-grid"
                        columnClassName="my-masonry-grid_column"
                    >
                            {commoditiesLoading && [1, 2, 3, 4, 5].map((i) => <CardLoader key={i} />)}

                            {!commoditiesLoading &&
                                commodities &&
                                commodities.length > 0 &&
                                commodities.filter(c => c.commodityTypeId == commodityType._id).map((i, k) => (
                                    <React.Fragment key={k}>
                                    
                                        <ErrorHandler key={k}>
                                            <OnLoadTransition transition="fade" timeout={config.user_page_transition_timeout} key={k}>
                                                <CommodityItem
                                                    selectedCommodities={selectedCommodities}
                                                    checkboxClickSign={checkboxClickSign}
                                                    setSelectAllCommodity={setSelectAllCommodity}
                                                    selectAllCommodity={selectAllCommodity}
                                                    checkSignAllSelectedCommodity={checkSignAllSelectedCommodity}
                                                    key={k}
                                                    data={i}
                                                    selectedCommoditiesSet={(e) => {
                                                        selectedCommoditiesSet(e);
                                                        setRedirect(true);
                                                    }}
                                                    snackbarAlertSet={snackbarAlertSet}
                                                    setOpenSnackbar={setOpenSnackbar}
                                                    tickSize={tickSize}
                                                    checked={checked}
                                                    commodityTypeChecked={commodityTypeChecked}
                                                />
                                            </OnLoadTransition>
                                        </ErrorHandler>
                                    </React.Fragment>
                                ))}
                    </Masonry>
                </AccordionDetails>
            </Accordion>
        );
    };

    return (
        <Container>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <Box textAlign="center">
                        <Box mt={2} fontSize={26} fontWeight="bold">
                            Select Commodity
                        </Box>
                        <Hidden only="xs" component="span" style={{ color: "#6A6A6A" }}>
                            Select the commodities for personalized views on the platform
                        </Hidden>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            border: "1px solid #003189",
                            background: "#F1F3F8",
                            color: "#2A2A2A",
                            maxWidth: "max-content",
                            p: 1.25,
                            mx: "auto",
                            borderRadius: "4px",
                            fontSize: "13px",
                            mt: 1.25,
                        }}
                    >
                        <Box sx={{ mr: 1 }}>
                            <img src="../images/bulb-icon.svg" alt="info" width="16" />
                        </Box>
                        <Box component="span">Select at least one commodity to get started.</Box>
                    </Box>
                </Grid>

                <Grid item xs={12} sm={12}>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "baseline" }}>
                        <Box className={classes.search}>
                            <InputBase
                                placeholder="Search by Commodity Type.."
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                onChange={(e) => {
                                    setSearchKeyword(e.target.value);
                                }}
                                inputProps={{ "aria-label": "search" }}
                            />
                            <Box className={classes.searchIcon}>
                                <SearchIcon />
                            </Box>
                        </Box>
                        <Button type="submit" color="primary" disableElevation variant="contained" size="large">
                            Search
                        </Button>
                    </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                    <p className="has__error text-danger">{apiErrorResponse}</p>

                    <Snackbar
                        open={openSnackbar}
                        autoHideDuration={10000}
                        onClose={handleClose}
                        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    >
                        <MuiAlert
                            onClose={handleClose}
                            severity={snackbarAlert.type}
                            action={
                                snackbarAlert.ask && (
                                    <>
                                        <Box mr={1}>
                                            <Button
                                                variant="contained"
                                                size="small"
                                                color="primary"
                                                disableElevation
                                                onClick={(e) => {
                                                    storeUserResponse(e);
                                                    handleClose(e);
                                                }}
                                            >
                                                Yes
                                            </Button>
                                        </Box>
                                        <Button variant="outlined" size="small" color="inherit" onClick={handleClose}>
                                            No
                                        </Button>
                                    </>
                                )
                            }
                        >
                            <AlertTitle>{snackbarAlert.message}</AlertTitle>
                            {snackbarAlert.ask && `Would you like to select more than ${tickSize} markets in future?`}
                        </MuiAlert>
                    </Snackbar>
                    <Grid item sm={2} style={{display:"flex"}}> <Box  >
                        <Checkbox checked={checkSignAllSelectedCommodity===true}
                         
                         onChange={() =>{ setCheckSignAllSelectedCommodity(!checkSignAllSelectedCommodity)}} inputProps={{ 'aria-label': 'controlled' }}></Checkbox>

                    </Box><Box mt={1.5}>{checkSignAllSelectedCommodity?<b>Unselect All</b>:<b>Select All</b>}</Box></Grid>
                   
                    <ValidatorForm noValidate onSubmit={storeMarketsPreference} style={{ position: "relative" }}>


                        <Box mb={4} >{commodityTypes.map(ct => <CommodityTypeAccordion key={ct._id} commodityType={ct} />)}</Box>

                        <Box
                            style={{
                                position: "fixed",
                                bottom: 0,
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignContent: "center",
                                zIndex: 100,
                                backgroundColor: "white",
                                padding: "10px",
                            }}
                        >
                            <Button type="submit" color="primary" disableElevation variant="contained" size="large" disabled={submitting}>
                                {submitting ? (
                                    <>
                                        <CircularProgress size={20} /> &nbsp; Saving...
                                    </>
                                ) : (
                                    "Submit"
                                )}
                            </Button>
                        </Box>
                    </ValidatorForm>
                </Grid>
            </Grid>
        </Container>
    );
}

const mapStateToProps = (state) => {
    return {};
};

export default connect(mapStateToProps, { checkAndLoadUser })(SelectCommodity);
