import { Box, Container, Grid } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import trackingEvents from '../../eventsTracking';

function LazyLoadedIframe({ youtubeID }) {
    const [shouldLoadIframe, setShouldLoadIframe] = useState(false);
    const iframeContainerRef = useRef(null);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.1 // Adjust as needed
        };

        const handleIntersection = (entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setShouldLoadIframe(true);
                    observer.unobserve(entry.target);
                }
            });
        };

        const observer = new IntersectionObserver(handleIntersection, options);
        observer.observe(iframeContainerRef.current);

        return () => {
            if (observer) observer.disconnect();
        };
    }, []);
    return (
        // <Grid item sm={12} xs={12} className="pointer" style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "2vh", marginTop: "-4vh" }}
        // ref={iframeContainerRef}
        // >
        <Container ref={iframeContainerRef}>
            <Box onClick={()=>trackingEvents.startVideo()}>
                <iframe
                    id="ytplayer"
                    allowfullscreen="allowfullscreen"
                    mozallowfullscreen="mozallowfullscreen"
                    msallowfullscreen="msallowfullscreen"
                    oallowfullscreen="oallowfullscreen"
                    webkitallowfullscreen="webkitallowfullscreen"
                    style={{ borderRadius: "4%", boxShadow: "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset", overflow: "hidden" }}
                    frameBorder="0"
                    type="text/html"
                    width="100%"
                    height="360"
                    src={`https://youtube.com/embed/${youtubeID}?autoplay=0`}>
                </iframe>
            </Box>
            {/* </Grid> */}
        </Container>
    )
}

export default LazyLoadedIframe