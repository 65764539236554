import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  setLoginModal,
  setSignupModal,
} from '../../redux/actions/commonActions';
import { Helmet } from 'react-helmet';

import { Box, Container, Typography } from '@mui/material';
import wave from '../../images/waves.png';

import a from '../../images/a.png';
import brandlogo from '../../images/brandlogo1.png';
import fraightmanage from '../../images/fraightmanage1.png';
import updateprice from '../../images/updateprice1.png';
import worldmap from '../../images/worldmap.png';
// import b from "../../components/brand-store/b.png";
import c from '../../images/brbell.png';
import d from '../../images/brphone.png';
import e from '../../images/brclients.png';
import f from '../../images/multipleproducts.png';
import g from '../../images/multiplemarkets.png';
import h from '../../images/increasesales.png';
import i from '../../images/negotiation.png';
import j from "../../images/reduceheadech.png";
import k from "../../images/autodeals.png";
import l from "../../images/alotbids.png";
import m from "../../images/customerfeadback.png";

import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from 'react-material-ui-form-validator';
import { makeStyles } from '@mui/styles';
import LoadingButton from '@mui/lab/LoadingButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector } from 'react-redux';
import ContactForm from '../partials/ContactForm';
/**
 *
 * @name LoginPage
 * @alias LoginPage
 * @component
 * @exports LoginPage
 *
 * @description
 * Login Form Page and related Actions
 */

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  imgBox: {
    maxWidth: '130px',
    margin: 'auto',
    // height: "201px"
  },
  downloadBtn: {
    right: '8px',
    bottom: '16px',
    color: '#6227df',
    background: 'white',
    '&:hover': {
      backgroundColor: '#f7f7f7',
    },
  },
  BoxHeading: {
    [theme.breakpoints.down('md')]: {
      fontSize: '1.5rem',
    },
    fontWeight: 'bold',
  },

  waveImg: {
    width: '100%',
  },
  heroContent: {
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },

  heroDescription: {
    maxWidth: '50rem',
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(7),
  },
  heroTitle: {
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.75rem',
    },
  },
  gridCol3: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    alignItems: 'center',
    gridGap: '5px',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  gridCol2: {
    display: 'grid',
    gridTemplateColumns: '1fr .5fr',
    alignItems: 'center',
    gridGap: '5px',
  },
  contactFormSection: {
    backgroundSize: '100% 360px',
    backgroundPosition: 'bottom',
    backgroundImage: `url("/images/bgbrandblckfotter.png")`,
    backgroundRepeatY: 'no-repeat'
  },
  hero: {
    // height: '355px',
    backgroundColor: theme.palette.primary.main,
    backgroundImage: `url(${wave})`,
    // backgroundPosition: '0 135px',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    // backgroundSize: "contain",
    // borderBottom: '4px solid #fff',
    // backgroundPosition: '0 175px',
    // backgroundSize: "cover",
    [theme.breakpoints.up('sm')]: {
      //   height: '35rem',
      backgroundSize: '100% 200px',
      //   backgroundPosition: '0 360px',
    },
  },
  planPackages: {
    marginTop: '-22rem',
    marginBottom: theme.spacing(15),
    [theme.breakpoints.down('lg')]: {
      marginTop: '-22rem',
      marginBottom: theme.spacing(8),
    },
    [theme.breakpoints.only('xs')]: {
      marginTop: '-9rem',
      marginBottom: theme.spacing(5),
      // marginTop: "auto"
    },
  },
  tabsContainer: {
    '& .Mui-selected.MuiTab-textColorPrimary': {
      backgroundColor: '#ddd',
      color: '#2959BA',
    },
    '& .MuiTab-textColorPrimary': {
      color: '#ddd',
    },
    '& .MuiTabs-indicator': {
      backgroundColor: '#0a2b5d',
    },
  },
}));

function BrandStoreLandingPage(props) {
  const [phoneCode, phoneCodeSet] = React.useState('+91');
  const [sendingOTP, sendingOTPSet] = React.useState(null);
  const [
    contactNumberVerificationMessageError,
    contactNumberVerificationMessageErrorSet,
  ] = React.useState('');
  const matchesViewport = useMediaQuery('(max-width: 580px)');
  const [otpVerificationMessageError, otpVerificationMessageErrorSet] =
    React.useState('');
  const countries = useSelector((state) => state.commonReducer.countries);
  const [contactNumber, contactNumberSet] = React.useState('');
  const customDesign = makeStyles((theme) => ({
    customButton: {
      width: '100%',
      //   background: 'linear-gradient(to right, #2A5AB7, #01838E)',
      background: '#01838E',
      color: '#FFFFFF ',
      borderRadius: '7px',
      '&:hover': {
        background: 'linear-gradient(to right, #2A5AB7, #01838E)',
        color: '#FFFFFF',
      },
    },
  }));

  const classes = useStyles();
  useEffect(() => {
    props.setSignupModal(false);
    props.setLoginModal(false);
  }, []);

  const customDesignClass = customDesign();

  const PhoneNumber = () => {
    return (
      <Box style={{ maxWidth: '700px', margin: 'auto' }}>
        <ValidatorForm noValidate>
          <Grid container spacing={1}>
            <Grid item sm={3} xs={4}>
              <Box>
                <SelectValidator
                  value={phoneCode}
                  name="country"
                  variant="outlined"
                  placeholder="Currency"
                  style={{
                    backgroundColor: 'white',
                    color: 'black',
                    borderRadius: '7px',
                    //   width: '70%',
                    //   height: '60%',
                    //   marginRight: '-7vh',
                    //   marginLeft: '3vh',
                  }}
                  required
                  size="small"
                  fullWidth
                  onChange={(e) => {
                    phoneCodeSet(e.target.value);
                  }}
                  inputProps={{
                    id: 'open-select',
                  }}
                  validators={['required']}
                  errorMessages={['Required']}
                >
                  {countries.map((option, key) => (
                    <MenuItem value={option.phone_code} key={key}>
                      <img
                        loading="lazy"
                        width="18"
                        src={`${option.flag_img}`}
                        alt={option.iso}
                      />
                      {option.phone_code}
                    </MenuItem>
                  ))}
                </SelectValidator>
              </Box>
            </Grid>
            <Grid item sm={6} xs={8}>
              <Box className="isRelative" borderRadius="3px">
                {/* {otpVerificationMessageError && <Box component='span' className={`isAbsolute text-danger`} sx={{ top: -16, fontSize: 12 }}>{otpVerificationMessageError}</Box>} */}
                <TextValidator
                  fullWidth
                  name="phone"
                  variant="outlined"
                  placeholder="Enter Phone Number"
                  size="small"
                  value={contactNumber}
                  style={{
                    backgroundColor: 'white',
                    color: 'black',
                    borderRadius: '7px',
                  }}
                  onChange={(e) => {
                    contactNumberSet(e.target.value);
                    otpVerificationMessageErrorSet('');
                  }}
                  validators={[
                    'required',
                    'isNumber',
                    `minStringLength:${phoneCode == '+91' ? '10' : '7'}`,
                    `maxStringLength:${phoneCode == '+91' ? '10' : '15'}`,
                    //`matchRegexp:${validatorRegEx(phoneCode == '+91' ? 'mobile' : "mobile12")}`
                  ]}
                  errorMessages={[
                    'Contact number is required!',
                    'Only Numeric values accepted!',
                    `Minimum required length is ${
                      phoneCode == '+91' ? '10' : '7'
                    } digit`,
                    `Max length can not exceed ${
                      phoneCode == '+91' ? '10' : '15'
                    } digit`,
                  ]}
                />
                {contactNumberVerificationMessageError && (
                  <Box
                    textAlign="right"
                    component="span"
                    className={`isAbsolute text-danger`}
                    //   sx={{ bottom: '-30px', fontSize: 12 }}
                  >
                    {contactNumberVerificationMessageError}
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item sm={3} xs={12}>
              <LoadingButton
                className={customDesignClass.customButton}
                variant="contained"
                //   style={{ marginLeft: '-52vh', height: '99%' }}

                type="submit"
                loading={sendingOTP}
                disableElevation
              >
                BOOK A DEMO
              </LoadingButton>
            </Grid>
          </Grid>
          {/* 
                  {phoneCode == '+91' && <Box sx={{ color: '#6A6A6A', fontSize: '13px', mb: 1 }}>You will receive an OTP on your number</Box>} */}
        </ValidatorForm>
      </Box>
    );
  };

  const FeatureTile = ({ imgSrc, text }) => {
    return (
      <Box
        className="partner-logo-link rounded8TlTr rounded8BlBr is-relative"
        boxShadow={2}
        p={1}
        textAlign="center"
      >
        <a href="#!" className="text-white">
          <Box style={{ height: '100px' }} alignItems="center" mt={3}>
            <img src={imgSrc} alt={text} />
          </Box>
          <Typography
            style={{
              color: '#1C1C1C',
              fontSize: 14,
              textAlign: 'center',
              fontWeight: '400',
              wordWrap: 'break-word',
            }}
          >
            {text}
          </Typography>
        </a>
      </Box>
    );
  };

  return (
    <>
      <Box className={classes.root}>
        <Box className={classes.hero}>
          <Box
            mx="auto"
            pt={4}
            mb={2}
            className={`text-center ${classes.heroDescription} ${classes.heroContent}`}
          >
            <Box>
              <Typography
                component="h1"
                variant="h3"
                style={{ fontSize: '2.7rem' }}
                gutterBottom
                className={`${classes.heroTitle} text-white`}
              >
                Build your brand among coal
              </Typography>

              <Typography
                component="h1"
                variant="h3"
                style={{ fontSize: '2.7rem' }}
                gutterBottom
                className={`${classes.heroTitle} text-white`}
              >
                end consumers
              </Typography>
            </Box>
            <Box>
              <PhoneNumber key={0} />
            </Box>

            <Box my={5}>
              <Container>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="flex-end"
                  spacing={2}
                >
                  <Grid item sm={4} xs={12}>
                    <img src={fraightmanage} style={{ width: '80%' }} />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <img src={brandlogo} style={{ width: '100%' }} />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <img src={updateprice} style={{ width: '80%' }} />
                  </Grid>
                </Grid>
              </Container>
            </Box>

            <Box my={5}>
              <PhoneNumber key={1} />
            </Box>
          </Box>
        </Box>
        {/* grid layout start*/}

        {/* grid layout end */}

        <Box className="features">
          <Container>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={4}
            >
              <Grid item sm={3} xs={12}>
                <FeatureTile imgSrc={a} text="Send different prices" />
              </Grid>
              <Grid item sm={3} xs={12}>
                <FeatureTile imgSrc={c} text="Live Price Alerts" />
              </Grid>
              <Grid item sm={3} xs={12}>
                <FeatureTile imgSrc={d} text="Build Your Brand" />
              </Grid>
              <Grid item sm={3} xs={12}>
                <FeatureTile imgSrc={e} text="Manage your Clients" />
              </Grid>

              <Grid item sm={3} xs={12}>
                <FeatureTile imgSrc={f} text="Showcase Multiple products" />
              </Grid>
              <Grid item sm={3} xs={12}>
                <FeatureTile imgSrc={g} text="Expand in multiple markets" />
              </Grid>
              <Grid item sm={3} xs={12}>
                <FeatureTile imgSrc={h} text="Increase Sales By 10X" />
              </Grid>
              <Grid item sm={3} xs={12}>
                <FeatureTile
                  imgSrc={i}
                  text="Multiple Negotiation Effortlessly "
                />
              </Grid>

              <Grid item sm={3} xs={12}>
                <FeatureTile imgSrc={j} text="Reduce headache & Phone calls" />
              </Grid>
              <Grid item sm={3} xs={12}>
                <FeatureTile imgSrc={k} text="Automated deal documents" />
              </Grid>
              <Grid item sm={3} xs={12}>
                <FeatureTile imgSrc={l} text="Assess lost bids" />
              </Grid>
              <Grid item sm={3} xs={12}>
                <FeatureTile
                  imgSrc={m}
                  text="Gather Customer’s feedback"
                />
              </Grid>
            </Grid>
          </Container>
        </Box>

        <Box>
          <Box my={5}>
            <PhoneNumber key={2} />
          </Box>
        </Box>
        <Container>
          <Box>
            <Grid
              container
              // spacing={5}
              justifyContent="center"
              alignItems="center"
              my={4}
              style={{ border: '1px solid #ddd', borderRadius: '10px' }}
            >
              <Grid
                xs={12}
                sm={6}
                item
                textAlign="center"
                style={{ borderRight: 'thin solid #ddd' }}
              >
                <Box my={3}>
                  <Typography
                    variant="h6"
                    component="h2"
                    display="block"
                    gutterBottom
                  >
                    <strong style={{ color: '#2959BA' }}>9000+</strong>
                  </Typography>
                  <Box sx={{ color: '#6A6A6A', py: 0.5, fontSize: '16px' }}>
                    Customer's Worldwide
                  </Box>
                </Box>
              </Grid>
              <Grid xs={12} sm={6} item textAlign="center">
                <Box my={3}>
                  <Typography
                    variant="h6"
                    component="h2"
                    display="block"
                    gutterBottom
                  >
                    <strong style={{ color: '#2959BA' }}>8+</strong>
                  </Typography>
                  <Box sx={{ color: '#6A6A6A', py: 0.5, fontSize: '16px' }}>
                    Years in this industry
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
        {/* <Box style={{ marginTop: "-100px" }}>
                    <Container style={{ border: "2px red solid", height: "400px", margin: "auto" }}></Container>

                </Box> */}
        <Box className={classes.contactFormSection}>
        <Grid
          container
          // spacing={5}
          justifyContent="center"
          alignItems="center"
          mt={4}
          backgroundColor="#fff"
          maxWidth={'70%'}
          margin="auto"
          spacing={2}
          style={{ border: '1px solid #ddd', borderRadius: '5px' }}
        >
          <Grid xs={12} sm={5} item>
            <Box my={3}>
              <Typography
                style={{
                  color: '#151E2B',
                  fontSize: 36,
                  fontWeight: '400',
                  wordWrap: 'break-word',
                }}
              >
                Coalshastra Office Presence
              </Typography>
              <Typography
                style={{
                  color: '#151E2B',
                  fontSize: 20,
                  fontWeight: '400',
                  wordWrap: 'break-word',
                }}
              >
                Head Office
              </Typography>
              <Typography
                style={{
                  color: '#5E6366',
                  fontSize: 14,
                  fontWeight: '400',
                  wordWrap: 'break-word',
                }}
              >
                8E, Big Jo's Tower, Netaji Subash Place,Pitam Pura, Delhi - 110
                034
              </Typography>

              <Typography
                style={{
                  color: '#151E2B',
                  fontSize: 20,
                  fontWeight: '400',
                  wordWrap: 'break-word',
                }}
              >
                Branch Office
              </Typography>
              <Typography
                style={{
                  color: '#5E6366',
                  fontSize: 14,
                  fontWeight: '400',
                  wordWrap: 'break-word',
                }}
              >
                1st Floor, C-91, 1st, 7, Block B,Wazirpur Industrial Area, Delhi
                - 110 052
              </Typography>
              <Typography
                style={{
                  color: '#151E2B',
                  fontSize: 20,
                  fontWeight: '400',
                  wordWrap: 'break-word',
                }}
              >
                Support
              </Typography>
              <Typography
                style={{
                  color: '#5E6366',
                  fontSize: 14,
                  fontWeight: '400',
                  wordWrap: 'break-word',
                }}
              >
               +91 93159 50241 
              </Typography>
              <Typography
                style={{
                  color: '#5E6366',
                  fontSize: 14,
                  fontWeight: '400',
                  wordWrap: 'break-word',
                }}
              >
                support@Mandi.trade
              </Typography>

              <img src={worldmap} />
            </Box>
          </Grid>
          
          {/* <Grid  xs={12} sm={7} item textAlign="center">
            <Box pr={2}>
              <ContactForm />
            </Box>
          </Grid> */}
          
        </Grid>
        </Box>
      </Box>
    </>
  );
}

export default connect(
  (state) => {
    return {};
  },
  { setLoginModal, setSignupModal }
)(BrandStoreLandingPage);
