import React from 'react'
import makeStyles from '@mui/styles/makeStyles';
import InputLabel from '@mui/material/InputLabel';
import Divider from '@mui/material/Divider';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import AnimatedSuccess from "./../partials/AnimatedSuccess";
import { connect } from "react-redux";
import { checkAndLoadUser } from "../../redux/actions/userActions";
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { validatorRegEx } from './../../helpers'
import OtpInput from "react-otp-input";
import Slide from '@mui/material/Slide';
import { User } from "./../../api/resources/User";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
    dialogHeader: {
        padding: theme.spacing(1),
        marginTop: theme.spacing(1.5),
        '& h2': {
            fontSize: 24,
            fontWeight: 'bolder'
        }
    },
    otpInputField: {
        width: "40px!important",
        height: "40px",
        lineHeight: "40px",
        border: "0",
        background: "rgba(0, 0, 0, 0.05)",
        borderBottom: '2px #1d2a72 solid'
    },
    otpInputWrapper: {
        width: '176px',
        margin: 'auto',
        position: 'relative'
    },
    success: {
        backgroundColor: '#7cb342 !important',
        color: '#fff !important'
    }
}))

function UpdateProfileContact(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [updateContactSuccess, setUpdateContactSuccess] = React.useState(false);
    const [responseKeyData, responseKeyDataSet] = React.useState('')
    const [contactNumber, setContactNumber] = React.useState("");
    const [otpSent, otpSentSet] = React.useState(false);
    const [otpVerified, otpVerifiedSet] = React.useState(null);
    const [contactVerified, contactVerifiedSet] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [otpVerificationMessageError, otpVerificationMessageErrorSet] = React.useState('');
    const [updateErrorMessage, updateErrorMessageSet] = React.useState('');
    const [otp, otpSet] = React.useState('');
    const [sendingOTP, sendingOTPSet] = React.useState(null);
    const [submitting, submittingSet] = React.useState(null);

    const handleClickOpen = modalName => {
        setOpen(modalName);
    };
    const handleClose = () => {
        setOpen(false);
    };

    /**
     *
     * @name sendVerificationCode
     * @alias sendVerificationCode
     * @component
     * @exports sendVerificationCode
     *
     * @description
     * When user wants to change his profile contact, send an 4 digit OTP
     * to existing number to verify user is authentic and has right
     * to change the profile contact
    */
    const sendVerificationCode = async () => {
        sendingOTPSet(true)
        try {
            const res = await User.sendVerificationCode(
            {
                type: 2,
                // contact_number: props.prevContact,
                contact_number: contactNumber,
                message: 'Your reference code for updating your CoalShastra profile contact number is - '
            })
            if(res.data.status && res.data.status == 'success')
            {
                otpSentSet(true)
                responseKeyDataSet(res.data.data)
                // setMessage(res.data.message)
                setMessage('4 digit reference code has been successfully sent.')
                otpSet(null)
                sendingOTPSet(false)
                setOpen('openOTPModal');
            }
            if (res.data.status == 'fail') {
                otpVerificationMessageErrorSet(res.data.errors.contact_number[0])
                setOpen('openProfileContactModal');
            }
        }
        catch(e) {
            console.error(e)
        }
        sendingOTPSet(false)
    }

    /**
     *
     * @name verifyReferenceCode
     * @alias verifyReferenceCode
     * @component
     * @exports verifyReferenceCode
     *
     * @description
     * Verify user has right to change profile number by validating
     * 4 digit reference code to his mobile number
    */
    const verifyReferenceCode = async () => {
        otpVerifiedSet(true)
        try {
            const res = await User.verifyOtp(
                {
                    key: responseKeyData,
                    value: otp
                }
            )

            if(res.data.status && res.data.status == 'success')
            {
                contactVerifiedSet(true)
                setOpen('openProfileContactModal')
                otpVerificationMessageErrorSet('')
            }
            // contactVerifiedSet(false)
        }
        catch(e) {
            console.error(e)
        }
        otpVerifiedSet(false)
    }
    // console.log(otpVerified)

    /**
     *
     * @name updateProfileContactNumber
     * @alias updateProfileContactNumber
     * @component
     * @exports updateProfileContactNumber
     *
     * @description
     * After verifying reference code, allow user to change another
     * valid contact number of his/her choice
    */
    const updateProfileContactNumber = async params => {
        submittingSet(true);
        var params = Object.assign(
            {
                contact_number: contactNumber,
            }
        );

        try {
            const res = await User.updateUserProfileContact(params);
            if (res.data.status == "success") {
                props.checkAndLoadUser()
                setUpdateContactSuccess(true);
                setContactNumber("");
                otpVerificationMessageErrorSet('')
                submittingSet(false);
            }
            if (res.data.status == 'fail') {
                updateErrorMessageSet(res.data.errors.contact_number[0])
            }
        } catch (e) {
            console.error(e);
        }
        submittingSet(false);
    };

  return (
    <>
        <IconButton
            aria-label="Update your profile contact"
            onClick={
                e => {
                    handleClickOpen('openProfileContactModal', e);
                    // sendVerificationCode(e)
                }
            }
            edge="end"
        >
            <EditIcon fontSize="small" />
        </IconButton>

        {/* OPT Verification modal */}
        <Dialog
            open={open === "openOTPModal"}
            keepMounted
            maxWidth='xs'
            TransitionComponent={Transition}
            onClose={e => {
                handleClose(e);
            }}
        >
            {otpVerified && (
                <>
                    <DialogTitle
                        align="center"
                        className={`${"text-white"}`}
                    >
                        <AnimatedSuccess />
                    </DialogTitle>
                    <DialogContent>
                        <Box sx={{ textAlign: 'center' }} mb={1}>
                            <Typography variant="h5">Great!!</Typography>
                        </Box>
                        <Box mb={2}>
                            <Typography variant="subtitle1">
                                Reference code validated successfully!!
                            </Typography>
                        </Box>
                        <Box mb={2}>
                            <Button
                                style={{
                                    background: "#4CAF50",
                                    color: "#fff"
                                }}
                                type="button"
                                variant="contained"
                                size="small"
                                fullWidth
                            >
                                Done
                            </Button>
                        </Box>
                    </DialogContent>
                </>
            )}
            {!otpVerified && (
                <>
                    <IconButton
                        aria-label="close"
                        className="closeBtn"
                        size="small"
                        onClick={e => {
                            handleClose(e)
                        }}
                    >
                        <CancelIcon />
                    </IconButton>
                    <DialogTitle id="alert-dialog-title" className={`text-center ${classes.dialogHeader}`}>
                        Verify Contact Number
                    </DialogTitle>
                    <DialogContent dividers={true}>
                        <Box component="p" sx={{textAlign: 'center'}}>Please type your 4 digit reference code <br /> sent to <strong>{contactNumber}</strong></Box>
                        <Box className={classes.otpInputWrapper}>
                            {sendingOTP && <Box sx={{position: 'absolute', p: 1.25, left: '-12px', width: '200px', background: 'white'}}><CircularProgress size={15} /> Sending reference code..</Box>}
                            <OtpInput
                                onChange={e => otpSet(e)}
                                autoFocus
                                numInputs={4}
                                inputStyle={classes.otpInputField}
                                separator={
                                    <span
                                    style={{ display: "inlineBlock", marginRight: "5px" }}
                                    ></span>
                                }
                                value={otp}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                size="large"
                                variant="contained"
                                sx={{mt: 2}}
                                color="primary"
                                disabled={(otp && (otp.length == 4)) ? false : true}
                                onClick={e => {verifyReferenceCode(e)}}
                            >
                                {submitting ? <><CircularProgress size={20} /> &nbsp; Verifying</> : 'Verify'}
                            </Button>

                        </Box>
                        {/* {message} */}
                    </DialogContent>
                </>
            )}
        </Dialog>


        {/* Add new profile contact */}
        <Dialog
            open={open === "openProfileContactModal"}
            keepMounted
            maxWidth='sm'
            TransitionComponent={Transition}
            onClose={e => {
                setUpdateContactSuccess(false); handleClose(e); contactVerifiedSet(false);
            }}
        >
            {updateContactSuccess && (
                <>
                    <DialogTitle
                        align="center"
                        className={`${"text-white"}`}
                    >
                        <AnimatedSuccess />
                    </DialogTitle>
                    <DialogContent>
                        <Box sx={{ textAlign: 'center' }} mb={1}>
                            <Typography variant="h5">Great!!</Typography>
                        </Box>
                        <Box mb={2}>
                            <Typography variant="subtitle1">
                                Profile contact updated successfully!!
                            </Typography>
                        </Box>
                        <Box mb={2}>
                            <Button
                                style={{
                                    background: "#4CAF50",
                                    color: "#fff"
                                }}
                                type="button"
                                onClick={e => { setUpdateContactSuccess(false); handleClose(e);  contactVerifiedSet(false);  updateErrorMessageSet('')}}
                                variant="contained"
                                size="small"
                                fullWidth
                            >
                                Done
                            </Button>
                        </Box>
                    </DialogContent>
                </>
            )}
            {!updateContactSuccess && (
                <>
                    <IconButton
                        aria-label="close"
                        className="closeBtn"
                        size="small"
                        //color="secondary"
                        onClick={e => {
                            setUpdateContactSuccess(false); handleClose(e);
                        }}
                    >
                        <CancelIcon />
                    </IconButton>
                    <DialogTitle id="alert-dialog-title" className={`text-center ${classes.dialogHeader}`}>
                        Update Contact Number
                    </DialogTitle>
                    <DialogContent dividers={true}>
                        <DialogContentText>
                            <small>Existing Contact - {props.prevContact}</small>
                        </DialogContentText>
                        <Box sx={{my: 1}}><Divider /></Box>
                        {/* <ValidatorForm onSubmit={e => {props.onUpdate()}} noValidate> */}
                        <ValidatorForm onSubmit={e => updateProfileContactNumber(e)} noValidate>
                            <Box mb={.5}><InputLabel>Enter New Contact Number</InputLabel></Box>
                            <Box sx={{display: 'flex'}}>
                                <TextValidator
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    value={contactNumber}
                                    onChange={e => { setContactNumber(e.target.value); contactVerifiedSet(false); updateErrorMessageSet(false);  updateErrorMessageSet(''); otpVerificationMessageErrorSet('') }}
                                    validators={[
                                        "required",
                                        "isNumber",
                                        "minStringLength:10",
                                        "maxStringLength:11",
                                        `matchRegexp:${validatorRegEx("mobile")}`
                                    ]}
                                    errorMessages={[
                                        "Contact number is required!",
                                        "Only Numeric values accepted!",
                                        "Minimum required length is 10 digit",
                                        "Max length can not exceed 10 digit",
                                        "Mobile number can not start with 0"
                                    ]}
                                />
                                <Button
                                    variant="contained"
                                    disableElevation
                                    sx={{ml: 1}}
                                    color="secondary"
                                    disabled={ contactVerified || contactNumber.length !== 10 }
                                    size='small'
                                    startIcon={contactVerified && <CheckIcon />}
                                    onClick={
                                        e => {
                                            sendVerificationCode(e);
                                        }
                                    }
                                >
                                    {contactVerified ? 'Verified' : 'Verify'}
                                </Button>
                            </Box>
                            {updateErrorMessage && <Box component='p' sx={{color: 'red', mt: 0, fontSize: 'small'}}>{updateErrorMessage}</Box>}
                            {otpVerificationMessageError && <Box component='p' sx={{color: 'red', mt: 0, fontSize: 'small'}}>{otpVerificationMessageError}</Box>}
                            <Box sx={{my: 1.5, display: 'flex', justifyContent: 'space-between'}}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    disableElevation
                                    disabled={submitting}
                                    size="small"
                                    onClick={e => { handleClose(e); contactVerifiedSet(false); updateErrorMessageSet(false)}}
                                    >
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    sx={{ml:1}}
                                    disableElevation
                                    disabled={ contactNumber.length != 10 || submitting || !contactVerified}
                                    size="small"
                                    // onClick={e => {handleClickOpen('otpVerifyModal', e)} }
                                    type="submit"
                                >
                                    {submitting ? <><CircularProgress size={20} /> &nbsp; Updating</> : 'Update'}
                                </Button>
                            </Box>
                        </ValidatorForm>
                    </DialogContent>
                </>
            )}
        </Dialog>

    </>
  )
}
const mapStateToProps = state => {
    return {}
}

export default connect(mapStateToProps, { checkAndLoadUser })(UpdateProfileContact);