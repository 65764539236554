import { Autocomplete, Box, Button, Grid, MenuItem, Typography, TextField } from '@mui/material';
import React from 'react'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { SelectValidator } from 'react-material-ui-form-validator';
import { CommodityCommon } from '../../api/resources/admin-api/CommodityCommon';
import { ConsoleCommodity } from '../../api/resources/admin-api/ConsoleCommodity';
import { useMediaQuery } from '@mui/material';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { useSelector } from 'react-redux';
import { CommodityUser } from '../../api/resources/CommodityUser';
import { Link } from 'react-router-dom';
const AddCompanyWithNoGst = (props) => {
  // const [companyName,setCompanyName]=React.useState('')
  // const [aadharName,setAdharName]=React.useState(null)
  // const [address,setAddress]=React.useState('')
  const [country, setCountry] = React.useState(null)
  const [state, setState] = React.useState(null)
  const [city, setCity] = React.useState(null)
  const [allCountries, setAllCountries] = React.useState([])
  const [allStates, setAllStates] = React.useState([])
  const [allCity, setAllCity] = React.useState([])
  // const [selectedCityId, setSelectedCityId] = React.useState('')
  // const [pincode,setPincode] = React.useState(null)
  const isSm = useMediaQuery('(max-width:576px)');

  const { companyNoGstChildStates } = props;
  const { companyName, aadharName, address, selectedCityId, pincode } = companyNoGstChildStates;


  const submit = () => {
    console.log(companyName)
  }
  const getCountry = async () => {
    try {
      const res = await CommodityCommon.Countries()
      if (res.status == 200) {
        console.log('country', res.data.data)
        setAllCountries(res.data.data)

      }
    } catch (error) {

    }
  }
  React.useEffect(() => {
    getCountry()
  }, [])
  const getCity = async (params) => {
    var params = Object.assign({
      state_id: state.id
    }, params)

    try {
      const res = await CommodityCommon.getAllCity(params)
      console.log('params', params)
      if (res.status === 200) {
        console.log(res.data.data)
        setAllCity(res.data.data)
        // props.sendStatesToParent({...companyNoGstChildStates,city:state.id});
        // setSelectedCityId(state.id)
      }
    } catch (error) {

    }
  }
  const getState = async (params) => {
    var params = Object.assign({
      country_id: country.id,

    }, params)

    try {
      // props.loader(true);

      const states = await CommodityCommon.getALLStates(params);
      if (states.status == 200) {

        setAllStates(states.data.data);

      } else {

        setAllStates([]);
      }

      // props.loader(false);
    } catch (error) {
      console.error(error);
    }
    // props.loader(false);
  };
  React.useEffect(() => {
    if (country) {
      getState()
    }
  }, [country])
  React.useEffect(() => {
    if (state) {
      getCity()
    }
  }, [state])
  
  React.useEffect(() => {
    props.fetchIndustry()
  }, [])



  const sendStatesToParentOnClick = () => {

    const newStates = {
      companyName,
      aadharName,
      selectedCityId,
      address,
      pincode
    };

    console.log('newStates', newStates);
    props.sendStatesToParent(newStates);
  };
  return (
    <>
      <ValidatorForm noValidate onSubmit={props.submitForm} style={{ marginTop: "10vh" }}>



        <Grid container rowGap={2} spacing={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Grid item sm={11} style={{ display: 'flex', alignItems: "center", justifyContent: 'center' }}> <Typography ><strong>Kindly provide your {`${props.userIndustry.toLowerCase()}`} company details:</strong></Typography>
          </Grid>
          <Grid item sm={5} xs={10} >

            <TextValidator
              variant="outlined"
              required
              fullWidth
              label="Enter your Company/Firm Name"
              validators={['required']}
              errorMessages={['Company Name is required']}
              size="small"
              value={companyName}
              onChange={(e) => {
                props.sendStatesToParent({ ...companyNoGstChildStates, companyName: e.target.value });
                // props.onChange()
              }}
            />
          </Grid>
          <Grid item sm={5} xs={10} >

            <TextValidator
              variant="outlined"
              required
              fullWidth
              label="Aadhar Number"
              validators={["required", "isNumber","minStringLength:12",  "maxStringLength:12"]}
            errorMessages={[
                'Aadhar Number is required',
                "Aadhar Number must be an integer",
                'Aadhar Number Cannot be less than 12 digits',
                'Aadhar Number Cannot be more than 12 digits'

               
            ]}
              size="small"
              value={aadharName}
              onChange={(e) => {
                props.sendStatesToParent({ ...companyNoGstChildStates, aadharName: e.target.value });
                // props.onChange()
              }}
            />
          </Grid>
          <Grid item sm={10} xs={10} >

            <TextValidator
              variant="outlined"
              required
              fullWidth
              label="Address"
              validators={['required']}
              errorMessages={['Address is required']}
              size="small"
              value={address}
              onChange={(e) => {
                props.sendStatesToParent({ ...companyNoGstChildStates, address: e.target.value });
                // props.onChange()
              }}
            />
          </Grid>

          <Grid container rowGap={2} spacing={2} style={{ display: 'flex', alignItems: "center", justifyContent: 'center', marginTop: "0.25vh" }}>

            <Grid item sm={2.45} xs={9.75} style={!isSm ? { marginLeft: "20px" } : { marginLeft: "15px" }}>
              <Autocomplete
                id="tags-outlined"
                // limitTags={1}
                // style={{padding: '4px'}}

                options={allCountries}

                getOptionLabel={(option) => option.name}
                value={country}

                size="small"
                onChange={(event, newValue) => {
                  setCountry(newValue);
                  //  console.log(e)
                }}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    label="Country"
                    variant="outlined"
                    fullWidth
                    size="small"
                    required
                    placeholder="Search by Country"
                    validators={country ? [] : ['required']}
                    errorMessages={country ? [] : ['Country is required']}

                  ><TextField
                      fullWidth
                      placeholder="Search by Country"
                    /></TextValidator>

                )}
              />
              {/* <SelectValidator
                   native="true"
                   required
                   variant="outlined"
                   label='Country' 
                  freesolo
                   fullWidth
                  //  defaultValue="United States"
                   validators={["required"]}
                   errorMessages={["Country field is required"]}
                   size="small"x
                   value={country}
                   onChange={e => {
                     setCountry(e.target.value);
                      console.log(e)
                   }}
               >
                   {allCountries&&allCountries.map((country, key) => (
                       <MenuItem
                           value={country}
                           key={key}
                       >
                           {country.name}
                       </MenuItem>
                   ))}
               </SelectValidator> */}


            </Grid>
            <Grid item sm={2.45} xs={9.75} style={!isSm ? {} : { marginLeft: "15px" }}>
              <Autocomplete id="tags-outlined"
                // limitTags={1}
                // style={{padding: '4px'}}

                options={allStates}

                getOptionLabel={(option) => option.name}
                value={state}

                size="small"

                onChange={(event, newValue) => {
                  setState(newValue)
                }}
                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    label="State"
                    variant="outlined"
                    fullWidth
                    size="small"
                    required
                    placeholder="Search by State"
                    validators={state ? [] : ['required']}
                    errorMessages={state ? [] : ['Country is required']}

                  ><TextField
                      fullWidth
                      placeholder="Search by State"
                    /></TextValidator>

                )} />
              {/* <SelectValidator
                   native="true"
                   required
                   variant="outlined"
                   label='State' 
                    freesolo
                  
                   fullWidth
                   validators={["required"]}
                   errorMessages={["State field is required"]}
                   size="small"
                   value={state}
                   onChange={e => {
                     setState(e.target.value)
              
                   }}
               >
                   {allStates&&allStates.map((st, key) => (
                       <MenuItem
                           value={st}
                           key={key}
                       >
                           {st.name}
                       </MenuItem>
                   ))}
               </SelectValidator> */}
            </Grid>
            <Grid item sm={2.45} xs={9.75} style={!isSm ? {} : { marginLeft: "15px" }}>
              <Autocomplete id="tags-outlined"
                // limitTags={1}
                // style={{padding: '4px'}}

                options={allCity}

                getOptionLabel={(option) => option.name}
                value={city}

                size="small"
                onChange={(event, newValue) => {
                  setCity(newValue)
                  props.sendStatesToParent({
                    ...companyNoGstChildStates,
                    selectedCityId: newValue._id
                  });
                }}

                renderInput={(params) => (
                  <TextValidator
                    {...params}
                    label="City"
                    variant="outlined"
                    fullWidth
                    size="small"
                    required
                    placeholder="Search by City"
                    validators={city ? [] : ['required']}
                    errorMessages={city ? [] : ['Country is required']}

                  ><TextField
                      fullWidth
                      placeholder="Search by City"
                    /></TextValidator>

                )} />
              
            </Grid>
            <Grid item sm={2.55} xs={9.75} style={!isSm ? {} : { marginLeft: "15px" }}>

              <TextValidator
                variant="outlined"
                required
                fullWidth
                label="Pincode"
                validators={['required']}
                errorMessages={['Pincode is required']}
                size="small"
                value={pincode}
                onChange={(e) => {
                  props.sendStatesToParent({ ...companyNoGstChildStates, pincode: e.target.value });
                  // props.onChange()
                }}
              />
            </Grid>
          </Grid>
          <Box style={{display:'flex', justifyContent:'center', width:"100%"}}>
            <Button size='large' style={{ backgroundColor: "#19243B", color: '#FFF', marginTop: '5vh' }} type='submit'>Submit</Button>
            <a href="/markets" style={{ color: '#1748b1', marginLeft:'20px', textDecoration:'underline', marginTop: '7vh', textTransform:'capitalize' }}>skip for now</a>
          </Box>
        </Grid>

      </ValidatorForm>
      <Box style={{ display: 'flex', justifyContent: 'center', margin: '0', padding: '0' }}>
  {/* <Button size='large' style={{ backgroundColor: "#19243B", marginTop: '5vh' }} type='click'>
    <Link to="/markets" style={{ color: '#FFF' }}>skip for now</Link>
  </Button> */}
</Box>

      <Box sx={{ mt: 3, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Box style={{ marginTop: "-20px", marginRight: 5 }}> <SupportAgentIcon /></Box>
        <Box style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography color={`text.secondary`} variant='body1' component='p'>
            In case you need any help, call us
          </Typography>
          <Typography style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 4 }}>+91 93159 50241</Typography>
        </Box>
      </Box>
    </>

  )
}

export default AddCompanyWithNoGst