import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Button, CardMedia } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

/**
 *
 * @name CommodityItem
 * @alias CommodityItem
 * @component
 * @exports CommodityItem
 *
 * @description
 * Admin created market is populated in the form of selection input. Client can select preferred market(s) and
 * save his preferences.
 *
 */

const useStyles = makeStyles(theme => ({
	card: {
		border: "2px black solid",
		backgroundColor:"#FFF"
	},
	title: {
		fontSize: 12,
		fontWeight: "bold",
	},
	divider: {
		borderBottom: "1px solid #ddd ",
	},
	selected: {
		border: "2px green solid",
		backgroundColor:	"#DEB887"
	},

	shadow: {
		boxShadow: "0 4px 6px 0 rgba(0, 0, 0, 0.1)",
	},
	customPadding: {
		padding: "6px 16px",
	},
	textSubtitle: {
		fontSize: 11,
		fontWeight: "bold",
	},
	dataText: {
		fontSize: 11,
		color: "#2a2a2a",
	},
}));

export default function CommodityItem(props) {
	const classes = useStyles();
	const tickSize = props.tickSize;

    const [selectedItem, selectedItemSet] = React.useState(false);
const [classState, classStateSet] = React.useState(false);
	/**
	 * @function changeMultipleCheckboxEvent
	 * @name changeMultipleCheckboxEvent
	 * @description
	 * A named function to select and handle multiple checkboxes and verify required validations
	 *
	 * @async
	 */

	function changeMultipleCheckboxEvent(value) {
		// value is commodityId
		const maxAllowedSelection = parseInt(tickSize);

		if (!selectedItem && props.selectedCommodities.length === maxAllowedSelection) {
			props.setOpenSnackbar(true);
			props.snackbarAlertSet({ message: `You can only select ${tickSize} commodities`, type: "info", ask: true });
			selectedItemSet(false);
			return false;
		}

		let checkedArray = props.selectedCommodities;
		let selectedValue = value;

		if (!selectedItem && props.checkSignAllSelectedCommodity===false) {
			checkedArray.push(selectedValue);
			props.selectedCommoditiesSet(checkedArray);
			selectedItemSet(true);
		} else if (!selectedItem && props.checkSignAllSelectedCommodity===true) {
				props.selectAllCommodity.push(selectedValue)
				props.setSelectAllCommodity(props.selectAllCommodity)
			selectedItemSet(true);
		}
		else if (selectedItem && props.checkSignAllSelectedCommodity===true) {
			let valueIndex = checkedArray.indexOf(selectedValue);
			props.selectAllCommodity.splice(valueIndex, 1)
			props.setSelectAllCommodity(props.selectAllCommodity)
			selectedItemSet(false);
		}
		else if (selectedItem && props.checkSignAllSelectedCommodity===false) {
			let valueIndex = checkedArray.indexOf(selectedValue);
			checkedArray.splice(valueIndex, 1);
			props.selectedCommoditiesSet(checkedArray);
			selectedItemSet(false);
		}
	}

	React.useEffect(()=>{
	if(props.checkSignAllSelectedCommodity===true){
		selectedItemSet(true);
	}else if(props.checkSignAllSelectedCommodity===false){
		selectedItemSet(false);
	}},[props.checkSignAllSelectedCommodity])


	// check this, this is running everytime page renders. need to work upon it for unselect all commodities
	React.useEffect(()=>{
		if(props.selectedCommodities.includes(props?.data?._id)) {
			props.checkSignAllSelectedCommodity===false?selectedItemSet(true):selectedItemSet(true)
		}
	},[props.selectedCommodities])

	return (
		<>
		{/* <Button onClick={()=>console.log(selectedItem)}>selItem</Button>
		<Button onClick={()=>console.log(props.checked)}>checkd</Button>
		<Button onClick={()=>console.log(props.selectedCommodities)}>chkdArr</Button>
		<Button onClick={()=>console.log(props.checkSignAllSelectedCommodity)}>checkSignAll</Button>
		<Button onClick={()=>console.log(props.data.commodityTypeId in props.commodityTypeChecked)}>existss</Button>
		<Button onClick={()=>{
			const exists = props.data.commodityTypeId in props.commodityTypeChecked
			// props.commodityTypeChecked[props.data.commodityTypeId]
			if( exists && props.commodityTypeChecked[props.data.commodityTypeId] === true) {
				console.log('set selected item to true')
				selectedItemSet(true)
			}
		}}>calc</Button> */}
			<Card
				variant="outlined"
				style={{borderRadius:"10px"}}
				sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
				className={selectedItem===true? classes.selected : classes.card}

				onClick={() => {
					changeMultipleCheckboxEvent(props.data._id);
					
				}}
			>
				{(props.data && props.data.images && props.data.images.length > 0) &&
					<CardMedia
						component="img"
						sx={{ width: 130, height: 110, objectFit: "cover" }}
						image={`${process.env.REACT_APP_API_URL_COMMODITY}/${props.data.images[0]?.url}`}

						alt={props.data.name}
					/>
				}
				<CardContent className={['cardContent']} sx={{ flex: '1 0 auto', position: 'relative',width: 130, height: 110 }} p={0} pb={0}  >
					<Typography variant="h6" component="h6">{props.data.name}</Typography>
					<Typography variant="overline" component="p">{props.data.commodityType.name}</Typography>
					<Box style={{ position: 'absolute', top: 0, right: '5px', fontSize: '20px' }}>
						{selectedItem
							? <CheckCircleIcon />
							: <RadioButtonUncheckedIcon />
						}
					</Box>
				</CardContent>
			</Card></>
	);
}
