import { fcmAnalytics } from './firebase'
import store from './redux/store'
// import { validatorRegEx } from "./helpers";
var uaParser = require("ua-parser-js");
const parsedUA = new uaParser();
const platform = {
    OS: parsedUA.getOS().name,
    browser: parsedUA.getBrowser().name,
    device: parsedUA.getDevice().vendor
};
const prefix = 'web_';
const state = store.getState();
const isLoggedIn = state.userReducer.isLoggedIn;
const user = state.userReducer.user;

const logEventWithCommonData = (eventName, params = {}) => {
    const userType = isLoggedIn ? 'user' : 'guest';
    // console.log({user})
    let userDetails = {};
    if(user) {
        // userDetails = {name: `${user?.firstName} ${user?.lastName}`, company: user?.activeCompany.name}
    }
    const mergedParams = {...params, ...platform, ...userDetails};
    fcmAnalytics.logEvent(`${eventName}_${userType}`, mergedParams);
}

const trackingEvents = {
    testEvent: () => {
        const eventName = 'test_event';
        logEventWithCommonData(eventName);
    },
    homeSearchbarClick: () => {
        const eventName = `home_searchbar_click`
        logEventWithCommonData(eventName);
    },
    latestBuyersTabClick: () => {
        const eventName = 'home_latest_buyers_tab_click'
        logEventWithCommonData(eventName);
    },
    latestSellersTabClick: () => {
        const eventName = 'home_latest_sellers_tab_click'
        logEventWithCommonData(eventName);
    },
    listingCarouselCardCLick : (data) => {
        console.log('card click called')
        const eventName = 'home_listing_carousel_card_click'
        logEventWithCommonData(eventName, data);
    },
    searchItemClick : (data) => {
        const eventName = 'home_product_search_item_click'
        logEventWithCommonData(eventName, data);
    },
    phoneCodeChangeRegister : (data) => {
        const eventName = 'home_phone_code_change_register_form'
        logEventWithCommonData(eventName, data);
    },
    registrationFormSubmit : () => {
        const eventName = 'home_register_button_click'
        logEventWithCommonData(eventName);
    },
    contactNumberFieldClick : () => {
        const eventName = 'home_contact_number_field_click'
        logEventWithCommonData(eventName);
    },
    termsOfServiceClick : () => {
        const eventName = 'home_terms_of_service_click'
        logEventWithCommonData(eventName);
    },
    privacyPolicyClick : () => {
        const eventName = 'home_privacy_policy_click'
        logEventWithCommonData(eventName);
    },
    refundPolicyClick : () => {
        const eventName = 'home_refund_policy_click'
        logEventWithCommonData(eventName);
    },
    readMore : () => {
        const eventName = 'home_read_more_button_click'
        logEventWithCommonData(eventName);
    },
    startVideo : () => {
        const eventName = 'home_tutorial_video_click'
        logEventWithCommonData(eventName);
    },
    checkOutProducts : () => {
        const eventName = 'home_check_out_products_click'
        logEventWithCommonData(eventName);
    },
    footerHomePage : () => {
        const eventName = 'footer_home_click'
        logEventWithCommonData(eventName);
    },
    footerProducts : () => {
        const eventName = 'footer_products_click'
        logEventWithCommonData(eventName);
    },
    aboutUs : () => {
        const eventName = 'footer_about_us_click'
        logEventWithCommonData(eventName);
    },
    contactUs : () => {
        const eventName = 'footer_contact_us_click'
        logEventWithCommonData(eventName);
    },
    contactNumber : () => {
        const eventName = 'footer_contact_number_click'
        logEventWithCommonData(eventName);
    }, 
    emailId : () => {
        const eventName = 'footer_email_id_click'
        logEventWithCommonData(eventName);
    },
    footerContracts : (data) => {
        const eventName = 'footer_contract_click'
        logEventWithCommonData(eventName, data);
    },
    navbarHomeButton : () => {
        const eventName = 'navbar_home_button_click'
        logEventWithCommonData(eventName);
    },
    navbarProducts : () => {
        const eventName = 'navbar_products_button_click'
        logEventWithCommonData(eventName);
    },
    loginButton : () => {
        const eventName = 'navbar_login_button_click'
        logEventWithCommonData(eventName);
    },
    registerButton : () => {
        const eventName = 'navbar_register_button_click'
        logEventWithCommonData(eventName);
    },
    homeButton : () => {
        const eventName = 'navbar_home_button_click'
        logEventWithCommonData(eventName);
    },
    myListings : () => {
        const eventName = 'navbar_my_listings_button_click'
        logEventWithCommonData(eventName);
    },
    productsPage : () => {
        const eventName = 'navbar_products_page_button_click'
        logEventWithCommonData(eventName);
    },
    myInbox : () => {
        const eventName = 'navbar_my_inbox_button_click'
        logEventWithCommonData(eventName);
    },
    myDeals : () => {
        const eventName = 'navbar_my_deals_button_click'
        logEventWithCommonData(eventName);
    },
    sellButton : () => {
        const eventName = 'navbar_sell_button_click'
        logEventWithCommonData(eventName);
    },
    buyButton : () => {
        const eventName = 'navbar_buy_button_click'
        logEventWithCommonData(eventName);
    },
    notificationsIcon : () => {
        const eventName = 'navbar_notifications_icon_click'
        logEventWithCommonData(eventName);
    },
    userProfileIcon : () => {
        const eventName = 'navbar_user_profile_icon_click'
        logEventWithCommonData(eventName);
    },
    userLogoutFromNavbar : () => {
        const eventName = 'navbar_user_logout_button_click'
        logEventWithCommonData(eventName);
    },
    userCompanyDetails : () => {
        const eventName = 'navbar_user_company_name_click'
        logEventWithCommonData(eventName);
    },
    userCompaniesExpand : () => {
        const eventName = 'navbar_user_companies_expand_icon_click'
        logEventWithCommonData(eventName);
    },
    userAddCompany : () => {
        const eventName = 'navbar_user_add_company_click'
        logEventWithCommonData(eventName);
    },
    searchProductsPage : () => {
        const eventName = 'products_page_search_click'
        logEventWithCommonData(eventName);
    },
    commodityTypeTabClick : () => {
        const eventName = 'products_page_commodity_type_tab_click'
        logEventWithCommonData(eventName);
    },
    selectCOmmoditiesClick : () => {
        const eventName = 'products_page_select_commodities_click'
        logEventWithCommonData(eventName);
    },
    productOpen : () => {
        const eventName = 'products_page_product_card_open_click'
        logEventWithCommonData(eventName);
    },
    shareButtonClick : (data) => {
        const eventName = 'products_page_share_button_click'
        logEventWithCommonData(eventName, data);
    },
    contractImageClick : () => {
        const eventName = 'products_page_contract_image_click'
        logEventWithCommonData(eventName);
    },
    negotiateWithBuyerClick : (data) => {
        const eventName = 'products_page_negotiate_with_buyer_click'
        logEventWithCommonData(eventName, data);
    },
    negotiateWithSellerClick : (data) => {
        const eventName = 'products_page_negotiate_with_seller_click'
        logEventWithCommonData(eventName, data);
    },
    buyListingButton : (data) => {
        const eventName = 'products_page_buy_listing_button_click'
        logEventWithCommonData(eventName, data);
    },
    sellListingButton : (data) => {
        const eventName = 'products_page_sell_listing_button_click'
        logEventWithCommonData(eventName, data);
    },
    addGst : () => {
        const eventName = 'add_gst_button_click'
        logEventWithCommonData(eventName);
    },
    logoutFromNavbar : () => {
        const eventName = 'logout_from_navbar'
        logEventWithCommonData(eventName);
    }
}

export default trackingEvents;