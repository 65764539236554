import resource from 'resource-axios';
import axios from '../baseCommodity';
import { config } from '../../config';

export const CommodityUserLogin = resource(config.api_commodity_login, {
    userRegister: (params) => {
        console.log({paramsInRegister:params})
        return axios.post(`${config.api_commodity_login}/register`, params)
    },
    userLogin: (params) => {
        return axios.post(`${config.api_commodity_login}/login`, params)
    },
    userLoginWithMobile: (params) => {
        return axios.post(`${config.api_commodity_login}/login-with-mobile-otp`, params)
    },

    contactNumberExistCheck: (params) => {
        return axios.post(`${config.api_commodity_login}/check-contact-number-exists`, params)
    },
    emailExistCheck: (params) => {
        return axios.post(`${config.api_commodity_login}/check-email-exists`, params)
    },

}, axios)