import React, { useState, useEffect } from "react";
import Emitter from '../../services/emitter';
import eventsTracking from '../../eventsTracking';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router"
import { cEncrypt, fireBidOfferSentEvent } from "../../helpers";
import { Link } from "react-router-dom";
import MinimizeIcon from '@mui/icons-material/Minimize';
import ThemeButton from "../partials/ThemeButton";
import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ReactGA from "react-ga4";
import FeedbackIcon from '@mui/icons-material/Feedback';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { User } from "../../api/resources/User";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import makeStyles from '@mui/styles/makeStyles';
import { Product } from "../../api/resources/Product";
import AcceptRejectCounterOptionsAndMessage from './chat/AcceptRejectCounterOptionsAndMessage'
import OfferAndBidMessage from './chat/OfferAndBidMessage'
import RePostListingModal from "../virtualOffice/partials/RePostListingModal";
import dateformat from "dateformat";
import Slide from '@mui/material/Slide';
// import Badges from "../partials/Badges"
import ReferencePopUp from "./chat/ReferencePopUp";
import CurrencyValidationDialog from "./chat/CurrencyValidationDialog";
import MutualContactsDialog from "../partials/MutualContactsDialog";
import DealRejectionForm from "./chat/DealRejectionForm";

const useStyles = makeStyles(theme => ({
    chatHeader: {
        background: theme.palette.common.white,
        padding: theme.spacing(1.5),
    },
    chatInfo: {
        backgroundColor: theme.palette.primary.main
    },
    chatInfoWhite: {
        backgroundColor: theme.palette.common.white
    },
    gridCol2Icon: {
        display: "grid",
        gridGap: '5px',
        gridTemplateColumns: '18px auto',
        '& img': {
            width: 12
        }
    },
    gridCol2Button: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        alignItems: "center",
    },
    gridCol2IconStatic: {
        // display: "grid",
        // gridGap: '5px',
        //gridTemplateColumns: '12px auto',
        alignItems: 'center',
        '& img': {
            width: 12
        }
    },
    gridCol2Static: {
        display: "grid",
        gridTemplateColumns: "1.5fr .5fr",
        alignItems: "center",
        [theme.breakpoints.only("xs")]: {
            gridTemplateColumns: "1fr",
        },
    },
    inputRadio: {
        padding: theme.spacing(.7),
        paddingBottom: theme.spacing(.7),
        paddingRight: theme.spacing(2),
        textAlign: 'left',
        marginBottom: theme.spacing(1),
        '&.MuiFormControlLabel-root': {
            marginLeft: theme.spacing(0),

        },
        '& .MuiButtonBase-root': {
            padding: 5
        }
    },
    inputBG: {
        background: '#eef2f5',
    },
    chatBody__smDown: {
        // maxHeight: '20rem;',
        [theme.breakpoints.only("xs")]: {
            maxHeight: '100%',
            paddingBottom: '180px'
        }
    },
    chatModal: {
        background: '#eef2f5',
        fontSize: '14px',
        //width: '360px',
        //position: 'fixed',
        //right: '35px',
        maxHeight: '700px',
        //overflowY: 'auto',
        bottom: 0,
        zIndex: 1200,
        boxShadow: '-1px 0 5px #55555569'
    },
}));

const closeModalBtnStyle = {
    position: "absolute",
    top: 8,
    right: 4,
    backgroundColor: "white"
}

function usePrevious(value) {
    const ref = React.useRef();
    React.useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

const SentMessageBubble = ({ children, chat, is_system }) => {
    return <div className="chat-thread-response" style={is_system == true ? { background: 'linear-gradient(0deg, #E0E0E0, #E0E0E0), #FFFFFF', margin: 'auto', clear: 'both', float: 'none', marginBottom: '12px' } : chat?.communication?.buyer_user_id == chat?.company_user_id ? { background: 'linear-gradient(0deg, rgba(78, 159, 61, 0.3), rgba(78, 159, 61, 0.3)), #FFFFFF' } : { background: 'linear-gradient(0deg, rgba(255, 0, 0, 0.2), rgba(255, 0, 0, 0.2)), #FFFFFF' }}>{children}</div>
}

const ReceivedMessageBubble = ({ children, chat, is_system }) => {
    return <div className="chat-thread-initiated" style={is_system == true ? { background: 'linear-gradient(0deg, #E0E0E0, #E0E0E0), #FFFFFF', margin: 'auto', clear: 'both', float: 'none', marginBottom: '12px' } : chat?.communication?.buyer_user_id == chat?.company_user_id ? { background: 'linear-gradient(0deg, rgba(78, 159, 61, 0.3), rgba(78, 159, 61, 0.3)), #FFFFFF' } : { background: 'linear-gradient(0deg, rgba(255, 0, 0, 0.2), rgba(255, 0, 0, 0.2)), #FFFFFF' }}>{children}</div>
}

const MessageBubble = ({ children, chat, user, is_system }) => {
    if (chat.company_id == user.company_id) {
        return <SentMessageBubble chat={chat} is_system={is_system}>{children}</SentMessageBubble>
    }

    return <ReceivedMessageBubble chat={chat} is_system={is_system}>{children}</ReceivedMessageBubble>
}

const NextActionOptions = ({ bid, offer, onSubmit }) => {
    const [action, actionSet] = React.useState(null)
    const classes = useStyles();

    return <FormControl component="fieldset" color="primary">
        <Box mb={1}>Are you interested in this {bid && 'bid'}{offer && 'offer'}</Box>
        <RadioGroup aria-label="action" value={action} name="action" onChange={e => { actionSet(e.target.value); onSubmit({ action: e.target.value }) }} style={{ display: 'block' }}>
            <FormControlLabel className={`${classes.inputRadio} ${classes.inputBG} w-100`} value='3' control={<Radio size="small" color="primary" />} label="Accept" />
            <FormControlLabel className={`${classes.inputRadio} ${classes.inputBG} w-100`} value='4' control={<Radio size="small" color="primary" />} label={`${action == '4' ? 'Reject due to' : 'Reject'}`} />
            {bid && <FormControlLabel className={`${classes.inputRadio} ${classes.inputBG} w-100`} value='2' control={<Radio size="small" color="primary" />} label="Counter Offer" />}
            {offer && <FormControlLabel className={`${classes.inputRadio} ${classes.inputBG} w-100`} value='1' control={<Radio size="small" color="primary" />} label="Counter Bid" />}
        </RadioGroup>
    </FormControl>
}


const NextActionMessageBubbles = ({ chatCount, product, price, payment_term_id, cc_percentage, days, currency, productCompanyId, product_type, user, lastMessage, firstMessage, onSubmit, sendingMessage, sendingMessageSet, submittedNextAction, minimumOrderQuantity, quantityTickSize, quantityRangeMax, quantityRangeMin, priceTickSize, listing, creditDays, userReferenceList, userReferenceListSet, specialRequest, paymentDays, lastMessageAction, brandStore }) => {
    // 1 => Bid, 2 => Offer, 3 => Accept, 4 => Reject, 5 => Close, 6 => Withdraw, 7 => Award, 8 => View quote
    const [action, actionSet] = React.useState('');
    const [reOffer, reOfferSet] = React.useState(false);
    const [reBid, reBidSet] = React.useState(false);

    React.useEffect(() => {
        if (submittedNextAction == true) {
            actionSet('')
        }
    }, [submittedNextAction])

    if (chatCount == 1 && lastMessage.company_id == user.company_id) {
        return <></>
    }

    if (
        (product_type == 1 && productCompanyId == user.company_id) ||
        (product_type == 2 && productCompanyId != user.company_id)
    ) {
        // Current user is buyer
        return <>
            {lastMessage.action == 2 &&
                <SentMessageBubble>
                    <NextActionOptions
                        offer={true}
                        onSubmit={e => actionSet(e.action)}
                        productCompanyId={productCompanyId}
                        product={product}
                        user={user}
                        product_type={product_type}
                        lastMessage={lastMessage} />
                </SentMessageBubble>
            }
            {(lastMessage.action == 2 && (action == 1 || action == 3 || action == 4)) &&
                <SentMessageBubble>
                    <AcceptRejectCounterOptionsAndMessage
                        offer={true}
                        action={action}
                        productCompanyId={productCompanyId}
                        product_type={product_type}
                        product={product}
                        brandStore={brandStore}
                        lastMessage={lastMessage}
                        price={firstMessage?.message_log?.data?.price ? firstMessage?.message_log?.data?.price : price}
                        paymentTermId={firstMessage?.message_log?.data?.payment_id ? firstMessage?.message_log?.data?.payment_id : payment_term_id}
                        ccPercentage={firstMessage?.message_log?.data?.advance ? firstMessage?.message_log?.data?.advance : cc_percentage}
                        liftingDays={firstMessage?.message_log?.data?.lifting_days ? firstMessage?.message_log?.data?.lifting_days : days}
                        quantity={firstMessage?.message_log?.data?.quantity ? firstMessage?.message_log?.data?.quantity : product.quantity}
                        currency={currency}
                        minimumOrderQuantity={minimumOrderQuantity}
                        quantityTickSize={quantityTickSize}
                        quantityRangeMax={quantityRangeMax}
                        quantityRangeMin={quantityRangeMin}
                        priceTickSize={priceTickSize}
                        creditDays={lastMessageAction?.message_log?.data?.no_credit_days ? lastMessageAction?.message_log?.data?.no_credit_days : creditDays} paymentDays={lastMessageAction?.message_log?.data?.payment_days ? lastMessageAction?.message_log?.data?.payment_days : paymentDays}
                        specialRequest={specialRequest}
                        sendingMessageSet={e => sendingMessageSet(e)}
                        sendingMessage={sendingMessage}
                        userReferenceListSet={e => userReferenceListSet(e)}
                        userReferenceList={userReferenceList}
                        onSubmit={e => {
                            if (action == 4) {
                                onSubmit({ action: action, message: e.message, reason_ids: e.reasons, data: e.data, autoQuote: e.autoQuote })
                            }
                            else {
                                onSubmit({ action: action, message: e.message, data: e.data, autoQuote: e.autoQuote })
                            }
                        }} />
                </SentMessageBubble>
            }
            {product.project_status == 0 && (lastMessage.action == 4 || lastMessage.action == 6 || (lastMessage.action == 0 && dateformat(new Date(), "yyyy-mm-dd HH:MM:ss") > dateformat(lastMessage.created_at, "yyyy-mm-dd HH:MM:ss")) && lastMessage.reject_reason_id == null) &&
                <>
                    {(product.project_status == 0 &&
                        ((lastMessage.action == 4) ||
                            (lastMessage.action == 6) || (lastMessage.action == 0 && dateformat(new Date(), "yyyy-mm-dd HH:MM:ss") > dateformat(lastMessage.created_at, "yyyy-mm-dd HH:MM:ss")))) &&
                        <SentMessageBubble>
                            {((lastMessage.action == 4 || lastMessage.action == 6) && action != 1) &&
                                <Box textAlign="center">
                                    <Button size="small" variant="contained" color="primary" onClick={e => { actionSet(1); reBidSet(true); }}>
                                        SEND YOUR BID
                                    </Button>
                                </Box>
                            }
                            {action == 1 &&
                                <OfferAndBidMessage
                                    productCompanyId={productCompanyId}
                                    product={product}
                                    product_type={product_type}
                                    price={price}
                                    paymentTermId={payment_term_id}
                                    ccPercentage={cc_percentage}
                                    liftingDays={days}
                                    reBid={reBid}
                                    brandStore={brandStore}
                                    currency={currency}
                                    creditDays={lastMessageAction?.message_log?.data?.no_credit_days ? lastMessageAction?.message_log?.data?.no_credit_days : creditDays}
                                    paymentDays={lastMessageAction?.message_log?.data?.payment_days ? lastMessageAction?.message_log?.data?.payment_days : paymentDays}
                                    quantity={listing.quantity}
                                    minimumOrderQuantity={minimumOrderQuantity}
                                    quantityTickSize={quantityTickSize}
                                    quantityRangeMax={quantityRangeMax}
                                    quantityRangeMin={quantityRangeMin}
                                    priceTickSize={priceTickSize}
                                    sendingMessage={sendingMessage}
                                    sendingMessageSet={e => sendingMessageSet(e)} userReferenceListSet={e => userReferenceListSet(e)} userReferenceList={userReferenceList}
                                    specialRequest={specialRequest}
                                    onSubmit={e => onSubmit({ message: e.message, action: action, data: e.data })}
                                />
                            }
                        </SentMessageBubble>
                    }
                </>
            }
        </>
    }
    else {
        // Current user is seller
        if (lastMessage.action == 1 && lastMessage.company_id != user.company_id) {
            return <>
                <SentMessageBubble>
                    <NextActionOptions
                        bid={true}
                        onSubmit={e => actionSet(e.action)}
                        product={product}
                        productCompanyId={productCompanyId}
                        user={user}
                        product_type={product_type}
                        lastMessage={lastMessage} />
                </SentMessageBubble>
                {(action == 1 || action == 2 || action == 3 || action == 4) &&
                    <SentMessageBubble>
                        <AcceptRejectCounterOptionsAndMessage
                            bid={true}
                            action={action}
                            product_type={product_type}
                            product={product}
                            productCompanyId={productCompanyId}
                            lastMessage={lastMessage}
                            brandStore={brandStore}
                            price={firstMessage?.message_log?.data?.price ? firstMessage?.message_log?.data?.price : price}
                            paymentTermId={firstMessage?.message_log?.data?.payment_id ? firstMessage?.message_log?.data?.payment_id : payment_term_id}
                            ccPercentage={firstMessage?.message_log?.data?.advance ? firstMessage?.message_log?.data?.advance : cc_percentage}
                            liftingDays={firstMessage?.message_log?.data?.lifting_days ? firstMessage?.message_log?.data?.lifting_days : days}
                            quantity={firstMessage?.message_log?.data?.quantity ? firstMessage?.message_log?.data?.quantity : product.quantity}
                            currency={currency}
                            creditDays={lastMessageAction?.message_log?.data?.no_credit_days ? lastMessageAction?.message_log?.data?.no_credit_days : creditDays} paymentDays={lastMessageAction?.message_log?.data?.payment_days ? lastMessageAction?.message_log?.data?.payment_days : paymentDays}
                            minimumOrderQuantity={minimumOrderQuantity}
                            quantityTickSize={quantityTickSize}
                            quantityRangeMax={quantityRangeMax}
                            quantityRangeMin={quantityRangeMin}
                            priceTickSize={priceTickSize}
                            specialRequest={specialRequest}
                            sendingMessageSet={e => sendingMessageSet(e)}
                            sendingMessage={sendingMessage}
                            userReferenceListSet={e => userReferenceListSet(e)}
                            userReferenceList={userReferenceList}
                            onSubmit={e => {
                                if (action == 4) {
                                    onSubmit({ action: action, message: e.message, reason_ids: e.reasons, data: e.data, autoQuote: e.autoQuote })
                                }
                                else {
                                    onSubmit({ action: action, message: e.message, data: e.data, autoQuote: e.autoQuote })
                                }
                            }} />
                    </SentMessageBubble>
                }
            </>
        }
        if (product.project_status == 0 && (lastMessage.action == 4 || lastMessage.action == 6 || (lastMessage.action == 0 && dateformat(new Date(), "yyyy-mm-dd HH:MM:ss") > dateformat(lastMessage.created_at, "yyyy-mm-dd HH:MM:ss"))) && lastMessage.reject_reason_id == null) {
            if (product.project_status == 0 &&
                (lastMessage.action == 4) ||
                (lastMessage.action == 6) || (lastMessage.action == 0 && dateformat(new Date(), "yyyy-mm-dd HH:MM:ss") > dateformat(lastMessage.created_at, "yyyy-mm-dd HH:MM:ss")) && lastMessage.reject_reason_id == null) {
                return <SentMessageBubble>
                    {((lastMessage.action == 4 || lastMessage.action == 6) && action != 2) &&
                        <Box textAlign="center">
                            <Button size="small" variant="contained" color="primary" onClick={e => { actionSet(2); reOfferSet(true); }}>
                                SEND YOUR OFFER
                            </Button>
                        </Box>
                    }
                    {action == 2 &&
                        <OfferAndBidMessage
                            sendingMessage={sendingMessage}
                            sendingMessageSet={e => sendingMessageSet(e)}
                            userReferenceListSet={e => userReferenceListSet(e)}
                            userReferenceList={userReferenceList}
                            productCompanyId={productCompanyId}
                            product={product}
                            product_type={product_type}
                            price={price}
                            brandStore={brandStore}
                            paymentTermId={payment_term_id}
                            ccPercentage={cc_percentage}
                            liftingDays={days}
                            reOffer={reOffer}
                            currency={currency}
                            quantity={listing.quantity}
                            minimumOrderQuantity={minimumOrderQuantity}
                            quantityTickSize={quantityTickSize}
                            quantityRangeMax={quantityRangeMax}
                            quantityRangeMin={quantityRangeMin}
                            priceTickSize={priceTickSize}
                            creditDays={lastMessageAction?.message_log?.data?.no_credit_days ? lastMessageAction?.message_log?.data?.no_credit_days : creditDays} paymentDays={lastMessageAction?.message_log?.data?.payment_days ? lastMessageAction?.message_log?.data?.payment_days : paymentDays} specialRequest={specialRequest}
                            onSubmit={e => onSubmit({ message: e.message, data: e.data, action: action })}
                        />
                    }
                </SentMessageBubble>
            }
        }
        return <></>
    }
}

/**
* @name ChatModalV3
* @alias ChatModalV3
* @component
* @description
* Chat box for user 
*
*/

export default function ChatModalV3(props) {
    const navigate = useNavigate();
    const classes = useStyles();
    const [chatThreadInit, chatThreadInitSet] = React.useState(null)
    const [chatVsibility, chatVisibilitySet] = useState(false);
    const user = useSelector(state => state.userReducer.user);
    const globalSetting = useSelector(state => state.commonReducer.globalSettings);
    const [chats, setChats] = useState([]);
    const [chatData, setChatData] = useState({});
    const prevChats = usePrevious(chats);
    const [newMessage, setNewMessage] = useState("");
    const [commYes, commYesSet] = useState(null);
    const [firstMessage, firstMessageSet] = useState(null);
    const [lastMessage, lastMessageSet] = useState(null);
    const [lastMessageAction, lastMessageActionSet] = useState(null);
    const [data, setData] = useState(props.data)
    const [sendingMessage, sendingMessageSet] = React.useState(false)
    const [userReferenceList, userReferenceListSet] = React.useState([]);
    const [referencePopUp, referencePopUpSet] = React.useState(false);
    const [currencyValidationDialog, currencyValidationDialogSet] = React.useState(false);
    const [submittedNextAction, submittedNextActionSet] = React.useState(null)
    const [blockDate, setBlockDate] = useState(null)
    const [isVisible, setIsVisible] = React.useState(false);
    const [badgesData, setBadgesData] = React.useState();
    const [currentUserType, currentUserTypeSet] = React.useState('')
    const [communicationId, communicationIdSet] = React.useState(null)
    const [listing, listingSet] = React.useState(null)
    const [mutualContactLoading, setMutualContactLoading] = React.useState(false);
    const [commYesNoLoading, setCommYesNoLoading] = React.useState(false);
    const [mutualContactModalOpen, mutualContactModalOpenSet] = React.useState(false);
    const [mutualContactData, mutualContactDataSet] = React.useState([]);
    const [commNoDialog, setCommNoDialog] = React.useState(false);

    React.useEffect(() => {
        setIsVisible(true)
        eventsTracking.chatOpened(user)
    }, [])

    React.useEffect(() => {
        // if (props.data.listing_id || (lastMessage?.action == 0 && dateformat(new Date(), "yyyy-mm-dd HH:MM:ss") > dateformat(lastMessage?.created_at, "yyyy-mm-dd HH:MM:ss"))) {
        //     Product.viewProductDetails({
        //         listing_id: props.data.listing_id,
        //         chat_company_id: props.data?.chat_company_id
        //     })
        //         .then(response => {
        //             listingSet(response.data.data);
        //         })
        //         .catch(error => {
        //             console.log(error);
        //         });
        // }
    }, [lastMessage])


    React.useEffect(() => {
        if (firstMessage && communicationId == null) {
            communicationIdSet(firstMessage.communication_id)
        }
    }, [firstMessage])

    React.useEffect(() => {
        // Redirect user to add company page if no company is added
        if (!user.company_id || !user.active_company) {
            navigate('/add-company-eKyc')
        }
    }, [user])

    React.useEffect(() => {
        setIsVisible(true)
    }, [])

    React.useEffect(() => {
        // Redirect user to add company page if no company is added
        if (!user.company_id || !user.active_company) {
            navigate('/add-company-eKyc')
        }
    }, [user])

    React.useEffect(() => {
        if (listing && ((listing.product_type == 1 && listing.company_id == user.company_id) ||
            (listing.product_type == 2 && listing.company_id != user.company_id))) {
            ReactGA.event({ category: 'User', action: 'Buyer opened chat window' });
            currentUserTypeSet('buyer');
        }
        else {
            ReactGA.event({ category: 'User', action: 'Seller opened chat window' });
            currentUserTypeSet('seller');
        }
    }, [listing])

    useEffect(() => {
        var params = {
            company_id: props.data?.chat_company_id,
            listing_id: props.data.listing_id
        }

        fetchChats(params);

        let interval = setInterval(() => {
            fetchChats(params);
        }, 3000);

        return () => clearInterval(interval);

    }, [
        props.data?.chat_company_id,
        props.data?.listing_id, ,

    ]);

    const fetchChats = (params) => {
        User.chats
            .fetch(params)
            .then(response => {
                if (response.data.data) {
                    if (response.data.data.length != chats.length && response.data.data.length > 0) {
                        var resData = response.data.data.reverse()
                        setChats(resData);
                        setChatData(response.data.chat_data);
                        setBlockDate(response.data.first_block_date);
                        if (resData.length === 1) {
                            firstMessageSet(resData[0]);
                        } else {
                            firstMessageSet(resData[resData.length - 2]);
                        }
                        lastMessageSet(resData[resData.length - 1]);
                        const lastActionMsg = resData.filter(e => {
                            return e.action == 1 || e.action == 2;
                        })
                        lastMessageActionSet(lastActionMsg[lastActionMsg.length - 1])

                        if (sendingMessage == true) {
                            sendingMessageSet(false)
                        }
                    }

                    if (response.data.data.length > 0 && chatThreadInit != true) {
                        chatThreadInitSet(false)
                        if (sendingMessage == true) {
                            sendingMessageSet(false)
                        }
                    }
                }
                else {
                    chatThreadInitSet(true)
                }
            });
    };

    React.useEffect(() => {
        Emitter.on("OVERSHOOT_QUANTITY", (e) => {
            sendingMessageSet(false)
        });
    }, [])

    React.useEffect(() => {
        if (prevChats != undefined && chats.length != prevChats.length) {
            const lastmsg = chats[chats.length - 1]

            if (sendingMessage == true) {
                sendingMessageSet(false)
            }
        }
    }, [chats]);

    React.useEffect(() => {
        if (prevChats != undefined && chats.length != prevChats.length) {
            var chatBody = document.getElementById("chatBody");
            if (chatBody) {
                chatBody.scrollTop = chatBody.scrollHeight;
            }
        }
        return () => { }
    }, [chats, props.data, props.data.listing_id, listing])

    React.useEffect(() => {
        setChats([])
        setNewMessage('')
        lastMessageSet(null)
        firstMessageSet(null);
        chatThreadInitSet(null)
        setData(props.data)
    }, [props.data?.listing_id])

    const sendMessage = e => {
        e.preventDefault();
        sendMessageHttpReq({ message: newMessage })
    };

    const sendMessageHttpReq = (data) => {
        if ((user?.country_mobile_prefix != '+91' && listing.currency != 'INR') || (user?.country_mobile_prefix == '+91' && listing.currency)) {
            if(window.location.pathname.includes('/console/admin')){
                sendingMessageSet(true)
                var postData = {
            



                   action: 1,
   listingId:props.data.listing_id,
   quantity: data?.data?.quantity,
   price: data?.data?.price,
   validity:  data?.data?.validityTime,
   paymentTermId: data?.data?.paymentModeId,
   liftingDays:data?.data?.lifting,
   paymentTermVarValue: 12,
   comments:data?.data?.comment,
   adminUserId: props.userid._id
                }
                console.log('postData',postData)
                User.chats
                    .sendMessage(postData)
                    .then(response => {
                        
                        if (communicationId == null) {
                            communicationIdSet(response.data.communication_id)
                        }
                        setNewMessage("");
                        fetchChats();
                        if (props.bestListingOffer) {
                            props.onSubmitForm();
                        }
                        submittedNextActionSet(true);
                        if ((data.action == 1 || data.action == 2) && response.data.status == 'success') {
                            // Show top 5 Buy/Sell
                            fireBidOfferSentEvent({
                                vessel_attribute_id: listing.vessel_attribute_id,
                                type: listing.vessel_attribute.vessel.type,
                                product_id: listing.listing_id,
                                action: data.action,
                                quantity: data?.data?.quantity
                            })
    
                            setTimeout(() => {
                                referencePopUpSet(true);
                            }, 8000);
                        } else {
                            referencePopUpSet(false);
                        }
                    })
                    .finally(() => {
                    });
            }else{
                sendingMessageSet(true)
                var postData = {
                    company_id: props.data?.chat_company_id,
                    listing_id: props.data.listing_id,
                    session_company_id: user.company_id,
                    message: data.message,
                    action: data.action ? data.action : 0,
                    reason_ids: data.reason_ids ? data.reason_ids : [],
                    price: data?.data?.price,
                    quantity: data?.data?.quantity,
                    lifting_days: data?.data?.lifting,
                    payment_id: data?.data?.paymentModeId,
                    payment_text: data?.data?.paymentMode, // todo
                    delivery_term_id: data?.data?.deliveryTermId,
                    advance: (data?.data?.paymentModeId == 1 || data?.data?.paymentModeId == 4) ? data?.data?.advance : '',
                    balance: (data?.data?.paymentModeId == 1 || data?.data?.paymentModeId == 4) ? data?.data?.balance : '',
                    no_credit_days: (data?.data?.paymentModeId == 2 || data?.data?.paymentModeId == 3) ? data?.data?.creditDays : '',
                    payment_days: data?.data?.paymentModeId == 4 ? data?.data?.paymentDays : '',
                    validity_time: data?.data?.validityTime,
                    comment: data?.data?.comment,
                    initiated_from: props.data.initiated_from ? props.data.initiated_from : 0,
                    auto_quote: data?.autoQuote,
                    communication_id: communicationId
                }
                User.chats
                    .sendMessage(postData)
                    .then(response => {
                        if (communicationId == null) {
                            communicationIdSet(response.data.communication_id)
                        }
                        setNewMessage("");
                        fetchChats();
                        if (props.bestListingOffer) {
                            props.onSubmitForm();
                        }
                        submittedNextActionSet(true);
                        if ((data.action == 1 || data.action == 2) && response.data.status == 'success') {
                            // Show top 5 Buy/Sell
                            fireBidOfferSentEvent({
                                vessel_attribute_id: listing.vessel_attribute_id,
                                type: listing.vessel_attribute.vessel.type,
                                product_id: listing.listing_id,
                                action: data.action,
                                quantity: data?.data?.quantity
                            })
    
                            setTimeout(() => {
                                referencePopUpSet(true);
                            }, 8000);
                        } else {
                            referencePopUpSet(false);
                        }
                    })
                    .finally(() => {
                    });
            }
          
        } else {
            currencyValidationDialogSet(true);
        }
    }

    const getMutualContactsList = (communication_id) => {
        setMutualContactLoading(true);
        Product.getCommMutualReferenceList({
            communication_id: communication_id,
        })
            .then(response => {
                if (response.data.status == "success") {
                    mutualContactModalOpenSet(true);
                    mutualContactDataSet(response.data.data);
                }
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => {
                setMutualContactLoading(false);
            });
    };

    const actionCommunicationYes = () => {
        setCommYesNoLoading(true);
        User.chats.communicationYes({
            communication_id: communicationId,
            listing_id: props.data.listing_id
        })
            .then(response => {
                if (response.data.status == "success") {
                    setCommYesNoLoading(true);
                }
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => {
                setCommYesNoLoading(false);
            });
    };

    const getRatingBadges = async params => {
        var params = Object.assign({ company_id: props.data?.chat_company_id }, params);
        try {
            const res = await User.profileBadges(params);
            if (res.data.status == "success") {
              //  console.log(res.data.data)
                setBadgesData(res.data.data)
            }
        } catch (e) {
            console.error(e);
        }
    };

    // React.useEffect(() => {
    //     if (props.data.chat_company_id) {
    //         getRatingBadges();
    //     }
    // }, [props.data?.chat_company_id]);

    const handleChatVisibility = () => {
        chatVisibilitySet(!chatVsibility)
    }

    if (!listing) {
        return <></>
    }

    return (
        <>
            <Slide direction="up" in={isVisible} mountOnEnter unmountOnExit>
                <div className={props?.static === true ? classes.chatModal : `chatModal`} style={chatVsibility === true ? { top: 'calc( 100vh - 45px )' } : ''}>
                    <Box className={props?.static === true ? classes.chatInfoWhite : `${classes.chatInfo} text-white`} sx={{ p: 1.5, borderBottom: '1px solid #ccc' }} >

                        <Box className={(props?.type === "expired-listings" || props?.type == "inbox-chat-history" || props?.type == "inbox") ? classes.gridCol2Button : ''}>

                            <Box textAlign={'left'}> {props.data && (`Listing ID: ${props.data.listing_id}`)}
                                {(user && user.reference_contacts_count != null && user.reference_contacts_count >= globalSetting.settings_mutual_badge_min_count && listing.is_trusted_contact == true) &&
                                    <> | <Tooltip
                                        title={`This company is in your trusted contact`}
                                        aria-label="additional info"
                                    >
                                        {props?.static === true ? <img src={`/images/trusted-company-listing.svg`} style={{ width: '20px', marginRight: '5px' }} className={'vAlignWithIcon'} alt='trusted listing' /> : <Box sx={{ display: 'inline', backgroundColor: '#fff', borderRadius: 2, padding: '2px' }}><img src={`/images/trusted-company-listing.svg`} style={{ width: '20px' }} className={'vAlignWithIcon'} alt='trusted listing' /></Box>}

                                    </Tooltip>
                                    </>
                                }
                            </Box>

                            <Box textAlign='right'>
                                {props?.type == "inbox-chat-history" && props.footerButtons
                                }
                                {props?.type == "inbox" && props.footerButtons
                                }
                                {props?.type === "expired-listings" &&
                                    <RePostListingModal
                                        selectedListingID={props.data.listing_id}
                                        selectedQuantity={listing.quantity}
                                        isAuction={listing?.vessel_attribute?.vessel?.is_auction}
                                    />
                                }
                            </Box>
                        </Box>
                    </Box>
                    {props?.static === true ?
                        //chat dialog view for my inbox/my listings
                        <Box className={classes.chatHeader} mb={1} >
                            <Box className={`${classes.gridCol2Static}`} sx={{ mb: .75 }}>
                                <Box>
                                    <Typography component="strong" variant="subtitle2">
                                        {(lastMessage && lastMessage.action == 8) ? badgesData?.company_name : 'Coalshastra User'} |  {listing?.coal_type}
                                    </Typography>
                                    <Box className={classes.gridCol2IconStatic} sx={{ mb: .75, display: { xs: 'block', sm: 'flex' } }}>
                                        {/* {chatData?.communication &&
                                            <Badges
                                                isChatModalV3={true}
                                                label={user.id == chatData?.communication?.seller_user_id ? 'Buyer Info' : 'Seller Info'}
                                                requestData={user.id == chatData?.communication?.seller_user_id ? chatData?.communication?.buyer_company?.user : chatData?.communication?.seller_company?.user}
                                                reviewLength={badgesData?.rating_data?.rating_comments.length}
                                                payment_term_id={listing.price[0].payment_term_id}
                                            />
                                        } */}

                                    </Box>
                                </Box>

                                {(props.type == "inbox" || props.type == "inbox-chat-history") &&
                                    <Box>
                                        <ThemeButton type='button' color="primary" size="small" sx={{ textDecoration: 'underline', textTransform: 'inherit' }}
                                            to={`/view-product-details/${cEncrypt(
                                                listing.listing_id
                                            )}`}
                                            component={Link}
                                        > View listing details
                                        </ThemeButton>
                                    </Box>
                                }
                            </Box>

                            <Box sx={{ fontSize: 10, color: '#2a2a2a', borderBottom: '1px solid #ddd', px: 1, py: .6, backgroundColor: '#ff00000a', borderRadius: '4px', }}><strong>Disclaimer</strong>: If the user is in your blocked list then the message wouldn’t be shown to the user.</Box>

                        </Box>
                        :
                        //chat dialog view for markets page and notification 
                        <Box className={classes.chatHeader} style={{ marginBottom: '4px', padding: '2px 10px' }}>
                            <Box sx={{ mb: .75 }}>
                                <Box>
                                    <Typography component="strong" variant="subtitle2">
                                        {(lastMessage && lastMessage.action == 8) ? badgesData?.company_name : 'Mandi.trade User'}
                                    </Typography>
                                    <Typography variant="body2"
                                        className={classes.fsXS} gutterBottom>
                                        <img src={listing?.contract?.origin_vessel?.flag_img} alt="flag" width="18" />{" "}{listing.country && listing.country} |{" "}
                                        <img src='/images/location-icon.svg' style={{ verticalAlign: 'text-bottom' }} />
                                        {listing?.origin_of_coal == 'Imported' ? listing?.port_name : listing?.vessel_city} {" "} |{" "}
                                        {listing.coal_type_id_fk === 2 ? listing.additional_parameter + "% Sulphur" : listing.coal_type_id_fk === 4 ? listing.additional_parameter + "% Ash" : listing.additional_parameter + (listing.additional_parameter && (listing.calorific_value_type == 1) ? ' GAR' : (listing.calorific_value_type == 2) ? ' GAD/GCV' : ' NAR') + " (Kcal/Kg)"}
                                    </Typography>

                                    <Box sx={{ fontWeight: 'bold', display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #ddd', paddingBottom: '5px', marginBottom: '5px' }}>
                                        <Box>
                                            {listing && listing.quantity && listing.quantity} MT {' '} ({`${listing?.price[0]?.days} Lifting`})
                                        </Box>
                                        <Box>
                                            {listing && listing.currency && listing.currency}{' '}{listing && listing.price && listing.price[0] && listing.price[0]?.product_price}/MT
                                            {listing.price[0].payment_term_id === 1 ? "" :
                                                listing.price[0].payment_term_id === 2 ? `${listing.num_credit_days == null ? 0 : `(${listing.num_credit_days} Days - LC)`}` :
                                                    listing.price[0].payment_term_id === 3 ? (listing.num_credit_days == null ? 0 : `(${listing.num_credit_days} Days)`) :
                                                        listing.price[0].payment_term_id === 4 ? `(${listing.cc_percentage}% Adv.) ` : ''}
                                        </Box>
                                    </Box>
                                    <Box className={classes.gridCol2IconStatic} sx={{ mb: .75, display: { xs: 'block' } }}>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Box sx={{ fontWeight: 'bold' }}>{listing && listing.coal_type && listing.coal_type}</Box>
                                            <Box>
                                                <Link color="primary" style={{ textDecoration: 'underline', textTransform: 'inherit' }}
                                                    to={`/view-product-details/${cEncrypt(
                                                        listing.listing_id
                                                    )}`}
                                                    target="_blank"
                                                    rel="noopener"
                                                    component={Link}
                                                > View listing details
                                                </Link>
                                            </Box>
                                        </Box>

                                        <Box sx={{ display: 'flex', alignItems: 'center', fontSize: '12px' }}>
                                            {/* {props?.data?.badges_data ?
                                                <Badges
                                                    isChatModalV3={true}
                                                    label={props?.data?.badges_label}
                                                    requestData={props?.data?.badges_data}
                                                    reviewLength={badgesData?.rating_data?.rating_comments.length}
                                                    payment_term_id={listing.price[0].payment_term_id}
                                                />
                                                :
                                                <Badges
                                                    isChatModalV3={true}
                                                    label={user.id == chatData?.communication?.seller_user_id ? 'Buyer Info' : 'Seller Info'}
                                                    requestData={user.id == chatData?.communication?.seller_user_id ? chatData?.communication?.buyer_company?.user : chatData?.communication?.seller_company?.user}
                                                    reviewLength={badgesData?.rating_data?.rating_comments.length}
                                                    payment_term_id={listing.price[0].payment_term_id}
                                                />
                                            } */}
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>

                            <Box align="right">
                                <IconButton
                                    aria-label="close"
                                    size="small"
                                    sx={{ position: 'absolute', top: 0, right: '50px', top: 8 }}
                                    onClick={e => {
                                        handleChatVisibility(e);
                                    }}
                                >
                                    <MinimizeIcon fontSize='small' style={{ color: 'white' }} />
                                </IconButton>

                                <IconButton
                                    aria-label="close"
                                    size="small"

                                    onClick={e => {
                                        setIsVisible(false);
                                        ReactGA.event({ category: 'User', action: `${currentUserType == 'buyer' ? 'Buyer' : 'Seller'} closed chat window` });
                                        setTimeout(() => {
                                            props.onClose(e);
                                        }, 500);
                                    }}
                                    style={closeModalBtnStyle}
                                >
                                    <img src={`/images/close-icon.png`} alt="close" width='22' />
                                </IconButton>
                            </Box>

                            <Box sx={{ fontSize: 10, color: '#2a2a2a', borderBottom: '1px solid #ddd', px: 1, py: .6, backgroundColor: '#ff00000a', borderRadius: '4px', }}><strong>Disclaimer</strong>: If the user is in your blocked list then the message wouldn’t be shown to the user.</Box>
                        </Box>
                    }

                    {/* Chat body */}
                    <div id="chatBody" className={`chat-body clearfix ${classes.chatBody__smDown}`}>
                        {/* To show listings comments */}
                        {listing?.special_requests &&
                            <Box className={classes.gridCol2Icon} sx={{ fontSize: 10, color: '#2a2a2a', border: '1px solid #EA9129', p: .6, backgroundColor: '#FFF5EA', borderRadius: '4px', m: 1, mt: 0.2 }}>
                                <FeedbackIcon size="small" style={{ color: "#EA9129", fontSize: 20 }} />
                                <Typography style={{ fontSize: 12 }}>{listing.special_requests}</Typography></Box>
                        }

                        {/* To show fetch messages */}
                        {chats.length > 0 &&
                            chats.map((chat, index) => (
                                <>
                                    <MessageBubble productCompanyId={listing?.company_id} chat={chat} key={index} user={user} chatData={chatData} is_system={chat.is_system}>
                                        {(chat.is_system == false && chat.company_id === user.company_id) && (
                                            <strong><small>{chat.company.name}</small><br /></strong>
                                        )}
                                        {chat.company_id !== user.company_id && (
                                            <Box mb={1}>
                                                <strong>
                                                    {chat.is_system == false && chat &&
                                                        (!chat.communication ||
                                                            (chat.communication &&
                                                                chat.communication.initiated_from === 0)) && (
                                                            <>CoalShastra User</>
                                                        )}
                                                    {chat.is_system == false && chat &&
                                                        chat.communication &&
                                                        chat.communication.initiated_from === 1 && (
                                                            <>{chat.company.name}</>
                                                        )}
                                                </strong>
                                            </Box>
                                        )}
                                        <span style={{ whiteSpace: 'pre-wrap', fontSize: '12px' }}>{chat.message}</span>

                                        {(lastMessage && lastMessage.id == chat.id && (chat.action == 1 || chat.action == 2) && chat.company_id === user.company_id) &&
                                            <Box mt={2}>
                                                <Button disabled={sendingMessage} size='small' type='button' color="primary" variant='contained' onClick={e => { sendMessageHttpReq({ message: 'Unfortunately, we are withdrawing the ' + (lastMessage.action == 1 ? 'bid ' : 'offer') + ' due to unforeseen circumstances.', action: 6, data: {} }) }}>
                                                    {sendingMessage && <><CircularProgress size={24} /> &nbsp; </>}Withdraw
                                                </Button>
                                            </Box>
                                        }
                                        <br />

                                        <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'end' }}>
                                            {chat.sender_user.id == user.id && <>
                                                {(chat && chat.receiver_status && chat.receiver_status !== null && chat.receiver_status == 1) ? <DoneAllIcon style={{ fontSize: '20px', marginRight: '4px', verticalAlign: 'bottom', color: '#2959BA' }} /> : <DoneIcon style={{ verticalAlign: 'bottom', marginRight: '4px', fontSize: '20px' }} />
                                                }</>
                                            }

                                            <span style={{ whiteSpace: 'pre-wrap', fontSize: '10px', textAlign: 'right' }}>{chat.created_at && dateformat(chat.created_at.replace(/-/g, "/"), 'dd mmm yyyy | h:MM tt')}</span>
                                        </Box>

                                        {/* To show View Quote Button */}
                                        {((index == chats.length - 1) && chat.action != 0 && (chat.communication && chat.communication.quotation_status == 1 && chat.communication.quotation_id) &&
                                            (chat && chat.last_quote_status && ((chat.last_quote_status == 3 && currentUserType == 'buyer') || chat.last_quote_status != 3))) &&
                                            <Box mt={2}>
                                                <Link
                                                    to={`/view-quotation/${encodeURI(
                                                        cEncrypt(chat.communication.quotation_id)
                                                    )}`}
                                                    target="_blank"
                                                    rel="noopener"
                                                >
                                                    <Button
                                                        size="small"
                                                        fullWidth
                                                        variant="contained"
                                                        color="primary"
                                                    >
                                                        View Quote
                                                    </Button>
                                                </Link>
                                            </Box>
                                        }
                                    </MessageBubble>

                                    {(chat.action == 3) &&
                                        <div style={{ margin: 'auto', clear: 'both', float: 'none', marginBottom: '12px' }} className={`chat-thread-system`}>
                                            <Box mb={.5} style={{ fontSize: '12px' }}>
                                                <span>Details of {user.id == chatData?.communication?.seller_user_id ? 'buyer' : 'seller'} are as follows:</span><br />
                                                <span><strong>Company Name: </strong>{user.id == chatData?.communication?.seller_user_id ? chatData?.communication?.buyer_company?.name : chatData?.communication?.seller_company?.name}</span><br />
                                                <span><strong>Address:</strong> {user.id == chatData?.communication?.seller_user_id ? chatData?.communication?.buyer_company?.address : chatData?.communication?.seller_company?.address}</span><br />


                                                <span>To check their references </span>
                                                <Button
                                                    size="small"
                                                    variant="text"
                                                    color="primary"
                                                    onClick={(e) => {
                                                        getMutualContactsList(communicationId);
                                                    }}
                                                >
                                                    Click Here
                                                </Button><br />
                                                {(lastMessage && lastMessage?.action != 4 && ((chatData?.communication?.buyer_user_id == user.id &&
                                                    chatData?.communication?.buyer_yes_at == null) ||
                                                    (chatData?.communication?.seller_user_id == user.id &&
                                                        chatData?.communication?.seller_yes_at ==
                                                        null))) &&
                                                    <>
                                                        <FormControl component="fieldset" color="primary">
                                                            <Box mb={1}>Do you wish to proceed with {user.id == chatData?.communication?.seller_user_id ? 'buyer' : 'seller'}?</Box>
                                                            <RadioGroup aria-label="action" value={commYes} name="action" onChange={e => { commYesSet(e.target.value); }} style={{ display: 'block' }}>
                                                                <FormControlLabel className={`${classes.inputRadio} ${classes.inputBG}`} value='1' control={<Radio size="small" color="primary" />} label="Yes" />
                                                                <FormControlLabel className={`${classes.inputRadio} ${classes.inputBG}`} value='2' control={<Radio size="small" color="primary" />} label="No" />
                                                            </RadioGroup>
                                                        </FormControl>
                                                        <Box textAlign="center">
                                                            {commYes &&
                                                                <Button
                                                                    size="small"
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={(e) => {
                                                                        if (commYes == 1) {
                                                                            actionCommunicationYes(commYes);
                                                                        }
                                                                        if (commYes == 2) {
                                                                            setCommNoDialog(true);
                                                                        }
                                                                    }}
                                                                >
                                                                    Submit
                                                                </Button>
                                                            }
                                                        </Box>
                                                    </>
                                                }

                                            </Box>
                                        </div>
                                    }
                                </>
                            ))
                        }

                        {/* To show next action msg bubbles like accept, reject or counter bid/offer */}
                        {(chats.length > 0 && lastMessage) && blockDate == null && <NextActionMessageBubbles
                            sendingMessageSet={e => sendingMessageSet(e)}
                            sendingMessage={sendingMessage}
                            userReferenceListSet={e => userReferenceListSet(e)}
                            userReferenceList={userReferenceList}
                            productCompanyId={listing.company_id}
                            chatCount={chats.length}
                            product={listing}
                            brandStore={props?.data?.brandStore}
                            product_type={listing.product_type}
                            price={listing?.price[0]?.product_price}
                            payment_term_id={firstMessage?.message_log?.data?.payment_id ? firstMessage?.message_log?.data?.payment_id : listing?.price[0]?.payment_term_id}
                            cc_percentage={listing?.cc_percentage}
                            paymentDays={listing?.no_payment_days}
                            days={listing?.price[0]?.days}
                            creditDays={listing?.num_credit_days}
                            currency={listing.currency}
                            minimumOrderQuantity={listing.contract ? listing.contract.min_order_qty : 25}
                            quantityTickSize={listing.contract ? listing.contract.qty_tick_size : 1} quantityRangeMax={listing.contract ? listing.contract.price_range_max : 2.5}
                            quantityRangeMin={listing.contract ? listing.contract.price_range_min : 0.5}
                            priceTickSize={listing.contract ? listing.contract.tick_size : 1}
                            listing={listing}
                            specialRequest={listing.special_requests}
                            lastMessageAction={lastMessageAction}
                            user={user}
                            lastMessage={lastMessage}
                            firstMessage={firstMessage}
                            onSubmit={e => { sendMessageHttpReq({ message: e.message, action: e.action, reason_ids: e.reason_ids, data: e.data, autoQuote: e.autoQuote }) }}
                            submittedNextAction={submittedNextAction}
                        />}

                        {/* To show bid/offer form */}
                        {(chatThreadInit === true && chats.length == 0) &&
                            <OfferAndBidMessage
                                sendingMessageSet={e => sendingMessageSet(e)}
                                sendingMessage={sendingMessage}
                                userReferenceListSet={e => userReferenceListSet(e)} userReferenceList={userReferenceList}
                                specialRequest={listing.special_requests}
                                productCompanyId={listing.company_id}
                                product={listing}
                                brandStore={props?.data?.brandStore}
                                product_type={listing.product_type}
                                price={listing?.price[0]?.product_price}
                                paymentTermId={listing?.price[0]?.payment_term_id}
                                ccPercentage={listing?.cc_percentage}
                                paymentDays={listing?.no_payment_days}
                                liftingDays={listing?.price[0]?.days}
                                creditDays={listing?.num_credit_days}
                                quantity={listing.quantity}
                                currency={listing.currency}
                                minimumOrderQuantity={listing.contract ? listing.contract.min_order_qty : 25}
                                quantityTickSize={listing.contract ? listing.contract.qty_tick_size : 1}
                                quantityRangeMax={listing.contract ? listing.contract.price_range_max : 2.5}
                                quantityRangeMin={listing.contract ? listing.contract.price_range_min : 0.5}
                                priceTickSize={listing.contract ? listing.contract.tick_size : 1} lastMessageAction={lastMessageAction}
                                onSubmit={e => { sendMessageHttpReq({ message: e.message, data: e.data, action: listing.product_type == 1 ? 2 : 1 }) }}
                            />
                        }
                    </div>

                    {/* To show chat footer */}
                    <div className="chat-footer">
                        {(lastMessage && lastMessage.last_quote_status == 2) &&
                            <form
                                onSubmit={e => {
                                    sendMessage(e);
                                    setNewMessage("");
                                }}
                            >
                                <div className="input-action">
                                    <input
                                        className="chat-input"
                                        name="message"
                                        value={newMessage}
                                        onChange={e => setNewMessage(e.target.value)}
                                        required
                                    />
                                    <button type="submit">
                                        <SendIcon className="text-white h-100" />
                                    </button>
                                </div>
                            </form>
                        }
                    </div>
                </div>
            </Slide>

            {(referencePopUp === true) &&
                <ReferencePopUp
                    show={userReferenceList.length < 25}
                    length={userReferenceList.length}
                />
            }

            {(currencyValidationDialog === true) &&
                <CurrencyValidationDialog
                    show={currencyValidationDialog}
                    listing={false}
                    currency={user?.country_mobile_prefix}
                    currencyValidationDialogSet={currencyValidationDialogSet}
                />
            }

            {(mutualContactModalOpen == true && mutualContactData) && <MutualContactsDialog
                contactList={mutualContactData}
                show={mutualContactModalOpen}
                onClose={e => mutualContactModalOpenSet(false)}
            />
            }

            {(commNoDialog === true) &&
                <DealRejectionForm
                    show={commNoDialog}
                    communicationId={communicationId}
                    setCommNoDialog={setCommNoDialog}
                />
            }
        </>
    );
}
