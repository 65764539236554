const packages = {
    staging: [
        // {
        //     "id": 1, planId: "pay_as_you_go", "plan_title": "pay as you go",
        //     "price": "20.00", "tax_info": "0", "amount": "0",
        //     "ad_credit": 'as required', "ad_credit_validity": '90 days', "best_seller": false,
        //     "paymentButton": { payment_button_id: "pl_IJVN9cSsnpZPVR" }
        // },
        // {
        //     "id": 2, planId: "silver_mini", "plan_title": "silver mini",
        //     "price": "12.50", "tax_info": "5,000.00", "amount": "5900.00",
        //     "ad_credit": '400', "ad_credit_validity": '90 days', "best_seller": false,
        //     "paymentButton": { payment_button_id: "pl_IJTwgSkHjwuVhC" }
        // },
        // {
        //     "id": 3, planId: "silver", "plan_title": "silver",
        //     "price": "12.50", "tax_info": "15,000.00", "amount": "17700.00",
        //     "ad_credit": '1,200', "ad_credit_validity": '1 year', "best_seller": false,
        //     "paymentButton": { payment_button_id: "pl_IJVBo3CidkMZtz" }
        // },
        {
            "id": 4, planId: "silver-unlimited", "plan_title": "silver unlimited",
            "price": "12.50", "tax_info": "15,000.00", "amount": "17700.00",
            "ad_credit": '200', "ad_credit_validity": '1 year', "best_seller": false,
            "paymentButton": { payment_button_id: "pl_IJVBo3CidkMZtz" }
        },
        {
            "id": 5, planId: "gold", "plan_title": "gold",
            "price": "10.00", "tax_info": "51,000.00", "amount": "60180.00",
            "ad_credit": '5,100', "ad_credit_validity": '1 year', "best_seller": true,
            "paymentButton": { payment_button_id: "pl_IJVDRGGL15eBDp" }
        },
        {
            "id": 6, planId: "platinum", "plan_title": "platinum",
            "price": "8.00", "tax_info": "96,000.00", "amount": "113280.00",
            "ad_credit": '12,000', "ad_credit_validity": '1 year', "best_seller": false,
            "paymentButton": { payment_button_id: "pl_IJVEwmALpNsfvd" }
        },
        {
            "id": 7, planId: "diamond", "plan_title": "diamond",
            "price": "7.00", "tax_info": "182,000.00", "amount": "214760.00",
            "ad_credit": '26,000', "ad_credit_validity": '1 year', "best_seller": false,
            "paymentButton": { payment_button_id: "pl_IJVGlc62tdk6vS" }
        },
        {
            "id": 8, planId: "Sapphire", "plan_title": "Sapphire",
            "price": "5.00", "tax_info": "500,000.00", "amount": "590000.00",
            "ad_credit": '100,000', "ad_credit_validity": '1 year', "best_seller": false,
            "paymentButton": { payment_button_id: "pl_IJrjSkds4F1ZB1" }
        }
    ],
    production: [
        // {
        //     "id": 1, planId: "pay_as_you_go", "plan_title": "pay as you go",
        //     "price": "20.00", "tax_info": "0", "amount": "0",
        //     "ad_credit": 'as required', "ad_credit_validity": '90 days', "best_seller": false,
        //     "paymentButton": { payment_button_id: "pl_IJrbzT3ygxFgLI" }
        // },
        // {
        //     "id": 2, planId: "silver_mini", "plan_title": "silver mini",
        //     "price": "12.50", "tax_info": "5,000.00", "amount": "5900.00",
        //     "ad_credit": '400', "ad_credit_validity": '90 days', "best_seller": false,
        //     "paymentButton": { payment_button_id: "pl_IJrfirr0BiJ7KV" }
        // },
        // {
        //     "id": 3, planId: "silver", "plan_title": "silver",
        //     "price": "12.50", "tax_info": "15,000.00", "amount": "17700.00",
        //     "ad_credit": '1,200', "ad_credit_validity": '1 year', "best_seller": false,
        //     "paymentButton": { payment_button_id: "pl_IJrgeF0YZFd513" }
        // },
        {
            "id": 4, planId: "silver-unlimited", "plan_title": "silver unlimited",
            "price": "12.50", "tax_info": "15,000.00", "amount": "17700.00",
            "ad_credit": '200', "ad_credit_validity": '1 year', "best_seller": false,
            "paymentButton": { payment_button_id: "pl_IJVBo3CidkMZtz" }
        },
        {
            "id": 5, planId: "gold", "plan_title": "gold",
            "price": "10.00", "tax_info": "51,000.00", "amount": "60180.00",
            "ad_credit": '5,100', "ad_credit_validity": '1 year', "best_seller": true,
            "paymentButton": { payment_button_id: "pl_IJrhoQtfz6CmWU" }
        },
        {
            "id": 6, planId: "platinum", "plan_title": "platinum",
            "price": "8.00", "tax_info": "96,000.00", "amount": "113280.00",
            "ad_credit": '12,000', "ad_credit_validity": '1 year', "best_seller": false,
            "paymentButton": { payment_button_id: "pl_IJrit9bCPUBoP1" }
        },
        {
            "id": 7, planId: "diamond", "plan_title": "diamond",
            "price": "7.00", "tax_info": "182,000.00", "amount": "214760.00",
            "ad_credit": '26,000', "ad_credit_validity": '1 year', "best_seller": false,
            "paymentButton": { payment_button_id: "pl_IJrjSkds4F1ZB1" }
        },
        {
            "id": 8, planId: "Sapphire", "plan_title": "Sapphire",
            "price": "5.00", "tax_info": "500,000.00", "amount": "590000.00",
            "ad_credit": '100,000', "ad_credit_validity": '1 year', "best_seller": false,
            "paymentButton": { payment_button_id: "pl_IJrjSkds4F1ZB1" }
        }
    ]
}


export default (process.env.REACT_APP_ENV == 'staging' || process.env.REACT_APP_ENV == 'development') ? packages.staging : packages.production