import { Autocomplete, Box, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import React from 'react'
import SearchIcon from '@mui/icons-material/Search';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  noOptions : {
    "& .MuiAutocomplete-noOptions":{
      display: 'none',
      opacity: 0,
    }
  }
}))
const CentralSearch = ({ contracts, loadingContracts, setSearchKeyword, setcontractData = () => {}, clusterSet = () => {}, contractData = null, vesselsSet, searchKeyword, isBuyer, handleKeyPress }) => {
  const classes = useStyles();
  return (
    <Box
    mt={2}
    textAlign="center"
    sx={{ width: { xs: '100%', sm: 400 }
    // , mx: 'auto' 
  }}
  >
    <Autocomplete
    className={classes.noOptions}
      id="tags-outlined"
      loading={loadingContracts}
      style={{ padding: '4px' }}
      options={searchKeyword && searchKeyword?.length > 0 ? contracts : []}
      noOptionsText={'Start typing to search for products'}
      onInputChange={(event, newKeyword) => {
        if (newKeyword.length > 1) {
          setSearchKeyword(newKeyword);
        }
      }}
      renderOption={(props, contract) => (
        <Box component="li" {...props}>
          <Grid container spacing={1}>
            <Grid item xs={3}>
                <Box style={{height: '60px', overflowY: 'hidden'}}>
                {(contract.images && contract.images.length > 0) 
                  ? <img style={{width: '100%'}} src={`${process.env.REACT_APP_API_URL_COMMODITY}/${contract.images[0].url}`} alt={contract.name} title={contract.name} />
                  : <Box style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#ccc', color: '#111'}}>
                      <small>No Image</small>
                  </Box>}
                </Box>
            </Grid>
            <Grid item xs={9}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="h6">
                    {contract?.name}
                  </Typography>
                </Grid>
                <Grid item xs={6} textAlign="right">
                  <Typography variant="caption">
                    {contract?.commodity?.commodityType &&
                      contract?.commodity?.commodityType
                        ?.name && (
                        <>
                          {
                            contract?.commodity?.commodityType
                              ?.name
                          }
                          {' '}
                          {' '}
                        </>
                      )}
                  </Typography>
                  <Typography variant="overline">
                    {contract?.commodity?.name}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">
                    {contract.clusterName.plural}:{' '}
                    {contract?.publishedClusters
                      .map((pc) => pc.name)
                      .join(', ')}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
      filterOptions={(x) => x}
      onChange={(e, data) => {
          setcontractData(data)
          clusterSet(null);
          vesselsSet(data?.publishedClusters);
      //   console.log({ data });
      }}
      getOptionLabel={(option) => option.name}
      value={contractData}
      filterSelectedOptions
      size="small"
      renderInput={(params) => (
        <TextField
          {...params}
          label ={`Search Products to ${isBuyer ? 'Add Requirements' : 'sell'}`}
          placeholder={`Search Products to ${isBuyer ? 'Add Requirements' : 'sell'}`}
          variant="outlined"
          fullWidth
          // onKeyDown={handleKeyPress}
          size="small"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loadingContracts ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {/* {params.InputProps.endAdornment} */}
                <SearchIcon />
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  </Box>
  )
}

export default CentralSearch