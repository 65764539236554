import React from 'react';
import { Link } from "react-router-dom";
import ThemeButton from "../partials/ThemeButton";
import {
    Box,
    Dialog,
    DialogTitle,
    // DialogContent,
    Slide,
    IconButton
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import CancelIcon from '@mui/icons-material/Close';
import { connect } from "react-redux";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
    dialogPaper: {
        maxWidth: '745px'
    },
    '& .MuiDialog-paper': {
        // width: 'calc(100% - 190px)'
        backgroundColor: 'red'
    },
    '& .MuiCircularProgress-svg': {
        color: '#cccc'
    },
    mututalContacts: {
        '&:not(:last-child)': {
            borderBottom: '1px solid #ddd',
        },
        padding: '12px 5px'
    }
}))

function MutualContactsDialog(props) {
    const classes = useStyles();
    const [show, showSet] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = modalName => {
        setOpen(modalName);
    };

    const handleClose = () => {
        setOpen(false);
        if (props.onClose) {
            props.onClose()
        }
    };

    React.useEffect(() => {
        showSet(props.show);

        if (props.show === true) {
            handleClickOpen("popUp")
        }

    }, [props.show])

    return (
        <>
            {show === true &&
                <Dialog
                    open={open === "popUp"}
                    TransitionComponent={Transition}
                    keepMounted
                    classes={{ paper: classes.dialogPaper }}
                    maxWidth='sm'
                    onClose={e => {
                        handleClose(e);
                        // props.showPopUpDialogSet(false);
                    }}
                >
                    <IconButton
                        aria-label="close"
                        className="closeBtn"
                        size="small"
                        onClick={e => {
                            handleClose(e)
                        }}
                    >
                        <CancelIcon />
                    </IconButton>

                    <Box>
                        <DialogTitle sx={{ pt: 4, pb: 0 }}>
                            {(props.contactList.length != 0) && <Box sx={{ fontSize: '15px', color: '#6a6a6a', mb: 1 }}> Great {props.data?.buyer_company_name}! You have one-last step left to grab the deal with your seller {props.data?.seller_company_name}.<br /><Box component='strong' sx={{ fontSize: 18, color: '#000' }}>You may perform a reference check with the following companies from your trusted circle for your ongoing deal..</Box></Box>}
                        </DialogTitle>

                        <Box component="ul" sx={{ listStyle: 'none', py: 0, pl: 3 }}>
                            {(props.contactList && props.contactList.length > 0) ? props.contactList.map((contact, index) => (
                                <Box component="li" key={index} className={classes.mututalContacts}>
                                    <img src='/images/trusted-company-listing.svg' width="25" style={{ marginRight: '5px' }} className='vAlignBottom' /> {contact}
                                </Box>
                            ))
                                :
                                <Box>
                                    <h3> Great {props.data?.buyer_company_name}! You have one-last step left to grab the deal with your seller {props.data?.seller_company_name}. </h3>
                                    <p>Unfortunately, the system couldn't find any common references. Add more companies to your trusted circle to increase possibilities of positive reference checks.</p>
                                    <ThemeButton
                                        disableElevation
                                        component={Link}
                                        variant="contained"
                                        size="small"
                                        to={`/my-safety-circle`}>
                                        ADD REFERENCES
                                    </ThemeButton>
                                </Box>
                            }
                        </Box>
                    </Box>
                </Dialog>
            }
        </>
    );
}

const mapStateToProps = state => {
    return {
        isLoading: state.commonReducer.loader
    };
};

export default connect(mapStateToProps)(MutualContactsDialog);