import axios from 'axios';
import { config } from '../config';
import { getAdminCommUserToken, getUserToken } from '../helpers'

const HOST_URL = config.api_url;

let headers = {
    'Content-Type': 'application/json'
}

// var userToken = getUserToken()
// if(userToken) {
//     headers.token = userToken
// }

// configure an axios instance
const instance = axios.create({
    baseURL: HOST_URL,
    headers: headers
});


instance.interceptors.request.use(function (config) {
    const consoleUserToken = getAdminCommUserToken('commconsole');

    const firebase_web_token = localStorage.getItem('firebase_web_token')
    var params = {}
    // params['firebase_web_token'] = firebase_web_token
    if(config.params) {
        params = config.params
    }
    if(consoleUserToken) {
        config.headers.Authorization= `Bearer ${consoleUserToken}`
    }
    config.params = params

    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});


instance.interceptors.response.use(function (response) {
    // if(response.data.success == false && (response.data.hasOwnProperty('auth') && response.data.auth == false))
    // {
    //     localStorage.clear();
    //     window.location = '/console';
    // }
    return response;
}, function (error) {
    // if(error.response && error.response.status === 401) {
    //     localStorage.clear();
    //     window.location.reload(true);
    // }

    return Promise.reject(error);
});

export default instance;
