import firebase from 'firebase/app';
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import 'firebase/messaging'
import 'firebase/analytics'
import Emitter from './services/emitter';
import 'firebase/messaging';

const devVapidKey = "BHW_R1wR2G761LozW7lFrsguecJhxuGM0rVjZcMujmL3teLjwQRarj0fzR9JcNtlHfzaMql5vJu5xI1jU8PAGos"

const prodVapidKey = "BCKKAYBGrCZwa0riTYP0d0LEAuGtFPkXT1GJkLyiRChcDxOnQVBST6ytqKheCyTZn2IvA86eyWHysb-KVTEMivg"

const firebaseDevConfig = {
    apiKey: "AIzaSyAPfywytcR2VUen4jCiLQxI_EXtvOws52o",
    authDomain: "mandi-staging.firebaseapp.com",
    projectId: "mandi-staging",
    storageBucket: "mandi-staging.appspot.com",
    messagingSenderId: "270010079171",
    appId: "1:270010079171:web:b5fcd36ce65564d40c4f76",
    measurementId: "G-ZS98PEBZ29"
}

const firebaseProdConfig = {
    apiKey: "AIzaSyCNgOX1gBoMsR6sfbDJO1uA0ZO9oVNycdo",
    authDomain: "mandi-live.firebaseapp.com",
    projectId: "mandi-live",
    storageBucket: "mandi-live.appspot.com",
    messagingSenderId: "35698624129",
    appId: "1:35698624129:web:2e86f52ee532ce5fb16f80",
    measurementId: "G-E9RGM8FSRF"  
  };

const isDev = (
    window.location.origin.includes('localhost') ||
    window.location.origin.includes('staging')
);

let initializedFirebaseApp = null;
let fcmAnalytics = null;
let messaging = null;
if(!window.location.pathname.includes("console")) {
    initializedFirebaseApp = firebase.initializeApp(isDev ? firebaseDevConfig : firebaseProdConfig)
    // analytics = getAnalytics(initializedFirebaseApp);
    console.log({initializedFirebaseApp})
// console.log('firebase.messaging',firebase.messaging)
    if(firebase.messaging.isSupported())
        {
            console.log('is supported')
            messaging = initializedFirebaseApp.messaging()

        //     Notification.requestPermission().then((permission) => {
        //         if(permission = 'granted') {
        //             console.log('permission granted')
        //             messaging.getToken({ vapidKey: isDev? devVapidKey : prodVapidKey }).then((currentToken) => {
        //                 if(currentToken) {

        //                     console.log('firebase current token - ', currentToken);
        //                     localStorage.setItem('firebase_web_token', currentToken);
        //                 } else {
        //                     console.log('no registration token available. Request permission to generate one.')
        //                 }
        //             }).catch(error => console.error('An error occurred while retrieving the registration token', error))
        //         } else {
        //             console.log("notification permission denied")
        //         }
        //     })
            // initFirebase();
        }
        else {
            console.log("Firebase messaging is not supported in this browser");
        }
    
        fcmAnalytics = initializedFirebaseApp.analytics();
}

const initFirebase = () => {
    // messaging.requestPermissions()
    //     .then(async function() {
    //         const token = await messaging.getToken();
    //         localStorage.setItem('firebase_web_token', token)
    //     })
    //     .catch(function(err) {
    //         console.log("Unable to get permission to notify.", err);
    //     });

    // Project Settings => Cloud Messaging => Web Push certificates
// console.log({messaging})
    if(!messaging)
    {
        return;
    }

    Notification.requestPermission(permission => {
        console.log('permission', permission)
        if(permission == 'granted') {
            messaging.getToken({ vapidKey: isDev ? devVapidKey : prodVapidKey }).then((currentToken) => {
                if (currentToken) {
                    console.log('firebase web token - ', currentToken)
                    localStorage.setItem('firebase_web_token', currentToken)
                } else {
                  console.log('No registration token available. Request permission to generate one.');
                }
              }).catch((err) => {
                console.error('An error occurred while retrieving token. ', err);
              });
        } else {
            console.log("Firebase messaging is not supported in this browser");
        }
    });

    navigator.serviceWorker.addEventListener("message", (message) => {
        if(isDev)
        {
            console.log("FCM - From worker - ")
            console.log(message)
        }
        if(fcmAnalytics)
        {
            fcmAnalytics.logEvent('notification_received', {notification_title: message.data.notification.title});
        }

        Emitter.emit('SNACKBAR_NOTIFICATION', { 
            message: message.data.notification.body, 
            params: { variant: 'success' } 
        });
        
        // console.log('message ', message)
        Emitter.emit('FIREBASE_NOTIFICATION', message);
    });
}

export {fcmAnalytics, initFirebase, initializedFirebaseApp}