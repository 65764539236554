import { LOGIN_PROCESS, LOGIN_USER, CHECK_AND_LOAD_USER, LOGOUT_USER,
    CHANGE_USER_MODE, UPDATE_NOTIFICATIONS_AND_MESSAGES, USER_NOT_VERIFIED, UNREAD_NOTIFICATION_COUNT, LOAD_USER_COMMODITES }
from '../actions/types';

const initalState = {
    user: {},
    loginLoader: false,
    isUserChecked: false,
	isLoggedIn: false,
	loggingIn: false,
	loginStatus: '',
    loginMessage: '',
    user_mode: null,
    noCompany: false,
    activeCompany: {},
    activeCompanyId: null,
    companies: [],
    company_ids: [],
    notifications: [],
	messagesCount: 0,
	unreadNotificationCount: 0,
    unreadNotifications: [],
    userNotVerified: false,
    userToBeVerified: {},
    selectedCommodites: [],
    selectedCommodityTypes: [],
}

export default function userReducer(state = initalState, action) {
    switch(action.type) {
        case UNREAD_NOTIFICATION_COUNT:
            return {
                ...state,
                unreadNotificationCount: action.payload.unreadNotificationCount,
            }
        case LOGIN_PROCESS:
            return {
                ...state,
                loginLoader: action.payload.loginLoader,
                loginMessage: action.payload.loginMessage
            }
        case LOGIN_USER:
            return {
                ...state,
                user: action.payload.user,
                isLoggedIn: action.payload.isLoggedIn,
                loginStatus: action.payload.loginStatus,
                // user_mode: state.user_mode ? state.user_mode : action.payload.user_mode,
                user_mode: action.payload.user_mode,
                noCompany: action.payload.noCompany,
                isUserChecked: action.payload.isUserChecked,
                activeCompanyId: action.payload.activeCompanyId,
                company_ids: action.payload.company_ids

            }
        case CHECK_AND_LOAD_USER:
            return {
                ...state,
                user: action.payload.user,
                isLoggedIn: action.payload.isLoggedIn,
                user_mode: state.user_mode ? state.user_mode : action.payload.user_mode,
                // user_mode: action.payload.user_mode,
                isUserChecked: action.payload.isUserChecked,
                noCompany: action.payload.noCompany,
                activeCompanyId: action.payload.activeCompanyId,
                company_ids: action.payload.company_ids
            }
        case LOGOUT_USER:
            return {
                ...state,
                user: {},
                isLoggedIn: false,
                loginMessage: action.payload.loginMessage
            }
        case CHANGE_USER_MODE:
            return {
                ...state,
                user_mode: action.payload.user_mode
            }
        case LOAD_USER_COMMODITES:
            return {
                ...state,
                selectedCommodites: action.payload.selectedCommodites,
                selectedCommodityTypes: action.payload.selectedCommodityTypes,
          
            }
        case UPDATE_NOTIFICATIONS_AND_MESSAGES:
            return {
                ...state,
                notifications: action.payload.notifications,
				messagesCount: action.payload.messagesCount,
				unreadNotificationCount: action.payload.unreadNotificationCount,
				unreadNotifications: action.payload.unreadNotifications
            }
        case USER_NOT_VERIFIED:
            return {
                ...state,
                userNotVerified: action.payload.userNotVerified,
                userToBeVerified: action.payload.userToBeVerified
            }
        // case 'SWITCH_COMPANY':
        //     return {
        //         ...state,
        //         user: action.payload.user,
        //         activeCompanyId: action.payload.activeCompanyId,
        //     }
        default:
            return state;
    }
}
