import React from 'react'
import { User } from '../../../api/resources/User';
import { CommodityUserLogin } from '../../../api/resources/CommodityUserLogin';
import { Grid, Fab, Card, CardContent, Button, InputLabel, Box, MenuItem, InputAdornment, Autocomplete } from "@mui/material";
import Flag from '../../partials/Flag';
import makeStyles from '@mui/styles/makeStyles';
import LanguageTranslate from './../../partials/UnderScoreLanguage'
import { Edit } from "@mui/icons-material";
import { ValidatorForm, TextValidator, SelectValidator } from "react-material-ui-form-validator";
import { useSelector, connect } from 'react-redux'
import LoadingButton from '@mui/lab/LoadingButton';
import UpdateProfileContact from "../UpdateProfileContact";
import Emitter from '../../../services/emitter';
import { checkAndLoadUser } from '../../../redux/actions/userActions';
import { CommodityProduct } from '../../../api/resources/CommodityProduct';
import { CommodityUser } from '../../../api/resources/CommodityUser';

const useStyles = makeStyles(theme => ({
    formGrid: {
        display: "grid",
        gridTemplateColumns: "95px auto",
        alignItems: 'flex-start',
        gridGap: '5px',
    },
    gridCol2: {
        display: "grid",
        gridTemplateColumns: "1fr .5fr",
        alignItems: 'center',
        gridGap: '5px'
    },
    iconBg: {
        position: 'relative',
        borderTop: '1px solid #ccc',
        borderBottom: '1px solid #ccc',
    },
    bgGray: {
        backgroundColor: '#F6F6F6'
    },
    marginBottom: {
        marginBottom: 1,
    },
}));

function ProfileForm({ user, cancelButton, onClose,checkAndLoadUser }) {
    const classes = useStyles();
    
    const countries = useSelector(state => state.commonReducer.countries);
    const [firstName, firstNameSet] = React.useState(user?.firstName);
    const [lastName, lastNameSet] = React.useState(user?.lastName);
    const [contactNumber, contactNumberSet] = React.useState(user?.contact_number);
    const [phoneCode, phoneCodeSet] = React.useState({ phone_code: '+91', iso2: 'IN'  });
    const [loading, loadingSet] = React.useState(false);
    const [email, emailSet] = React.useState(user?.email)
    const [sendOtp,setSendOtp] = React.useState(false)
    const [otp,setOtp]=React.useState("")
    const [verifyOtp,setVerifyOtp] = React.useState("")
    const [disableSubmitButton,setDisableSubmitButton] = React.useState(true)
    const [sendVerifyOtp,setSendVerifyOtp] = React.useState(false)
    const [responseKeyData, responseKeyDataSet] = React.useState(null);
    const [otpVerificationMessageError,setOtpVerificationMessageError] = React.useState("")
    const sendVerificationCode=async()=>{
        try {
            const res = await CommodityUser.otpSend({ channel: 'MOBILE', subject: contactNumber,phoneCode:phoneCode.phone_code})
            console.log('rer',res)
            if(res.data.statusText&&res.data.statusText==="success"){
                setSendOtp(true)
                setDisableSubmitButton(true)
                // responseKeyDataSet(res.data.data);
                Emitter.emit('SNACKBAR_NOTIFICATION', { message: `OTP send successfully!`, params: { variant: 'success' } });
            }
        } catch (error) {
        console.log(error)
        }
    }
    const verifyReferenceCode= async()=>{
        try {
            const res = await CommodityUser.otpverification(
                {
                    subject: phoneCode.phone_code+contactNumber,
                    channel: "MOBILE",
                    otp: parseInt(otp)
                }
            )
            if(res.data.statusText&&res.data.statusText==="success"){
                setSendVerifyOtp("")
                setSendVerifyOtp(false)
                setDisableSubmitButton(false)

                Emitter.emit('SNACKBAR_NOTIFICATION', { message: `Mobile Number has been verified successfully`, params: { variant: 'success' } });
            }
            
        } catch (error) {
            console.log(error)
        }
    }
    const storeUserUpdateProfileAction = async () => {
        loadingSet(true);
    
        try {
            let userData = {
                firstName: firstName,
                lastName: lastName,
                contact_number: contactNumber,
                email : email
            };
    
        
    
            const res = await CommodityProduct.updateUserProfile(userData);
    
            // console.log('res', res);
    
            if (res.data.statusCode === 200 && res.data.statusText === 'success') {
                setTimeout(() => {
                    checkAndLoadUser();
                }, 1000);
    
                onClose();
                setSendOtp("")
                setOtp("")
                Emitter.emit('SNACKBAR_NOTIFICATION', {
                    message: `User Details updated successfully!`,
                    params: { variant: 'success' }
                });
            }
        } catch (e) {
            console.error('err',e);
        }
    
        loadingSet(false);
    };
    

    return (
        <>
            <ValidatorForm
                noValidate
                onSubmit={e => {                 storeUserUpdateProfileAction()}}
             
            >
                <Grid container spacing={2}>
                   {/* <Button onClick={()=>console.log(user)}>click</Button> */}
                    <Grid item xs={12} sm={6}>
                        <InputLabel className={classes.marginBottom}><LanguageTranslate s='First Name' /><sup style={{ color: "red" }}>*</sup></InputLabel>
                        <TextValidator
                            fullWidth
                            
                            size='small'
                            variant="outlined"
                            name="first_name"
                            value={firstName}
                            onChange={e => { firstNameSet(e.target.value) }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputLabel className={classes.marginBottom}><LanguageTranslate s='Last Name' /><sup style={{ color: "red" }}>*</sup></InputLabel>
                        <TextValidator
                            fullWidth
                            size='small'
                            variant="outlined"
                            name="last_name"
                            value={lastName}
                            onChange={e => { lastNameSet(e.target.value) }}
                        />
                    </Grid>
                    {/* <Button onClick={()=>console.log(email)}>click</Button> */}
                    <Grid item xs={12} sm={12}>
                        <InputLabel className={classes.marginBottom}><LanguageTranslate s='Email' /><sup style={{ color: "red" }}>*</sup></InputLabel>
                        <TextValidator
                            fullWidth
                            size='small'
                            variant="outlined"
                            name="email"
                            value={email?email:"Email Address"}
                            // InputProps={{
                            //     readOnly: true,
                            // }}
                            onChange={(e)=>{emailSet(e.target.value)}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <InputLabel>Enter Phone Number<sup className='nota-bene'>*</sup></InputLabel>

                        
                        <Box sx={{ mb: 2 }}>
                            <Box className={classes.formGrid}>
                                <Box sx={{ backgroundColor: '#F6F6F6' }}>
                                <Autocomplete
                    value={phoneCode}
                    name="country"
                    size="small"
                    // 
                    forcePopupIcon={false}
                    fullWidth
                    variant="outlined"
                    label="Country Code"
                    placeholder="country"
                    required
                    filterSelectedOptions
                    options={countries}
                    renderOption={(props, code) => {
                        return (
                          <Box component="li" {...props} >
                            {code.iso2 ? (
                              <Flag
                                code={code.iso2}
                                iconWidth={80}
                                width={15}
                              />
                            ) : (
                              <></>
                            )}{' '}
                            {code.phone_code}
                          </Box>
                        );
                      }}
                    onChange={(e) => {
                        phoneCodeSet(e.target.value);
                    }}
                    getOptionLabel={(type) =>
                      type.phone_code ? type.phone_code : ''
                    }
                    renderInput={(params) => (
                      <TextValidator
                        {...params}
                        variant="outlined"
                        required
                        fullWidth
                        validators={['required']}
                        errorMessages={['phone code is required']}
                        value={phoneCode}
                        InputProps = {{
                            ...params.InputProps,
                            startAdornment : phoneCode?(<Flag iconWidth={80}
                                width={15}  code={phoneCode.iso2} />):null
                           }} 
                          
                      />
                    )}
                  />
                                </Box>

                                <Box className='isRelative'>
                                    <TextValidator variant="outlined"
                                        fullWidth
                                        name="phone"
                                        placeholder="Enter Phone Number"
                                        size="small"
                                        value={contactNumber}
                                        onChange={(e) => { contactNumberSet(e.target.value)}}
                                        validators={[
                                            "required",
                                            "isNumber",
                                            
                                            "minStringLength:10",
                                            "maxStringLength:10",
                                        ]}
                                        errorMessages={[
                                            "Contact number is required!",
                                            "Only Numeric values accepted!",
                                            `Minimum required length is 10 digit`,
                                            `Max length can not exceed 10 digit`,
                                        ]}
                                  
                                    />
                                </Box>
                            </Box>

                             <Box sx={{ color: '#6A6A6A', fontSize: '14px', mb: 1 }}>You will receive an OTP on your number</Box>

                        </Box>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item sm={12}> {sendOtp&&!sendVerifyOtp&&   <TextValidator
                                variant="outlined"
                                fullWidth
                                name="phone"
                                label="Enter OTP"
                                required
                            size="small"
                            value={otp}
                            onChange={(e)=>{setOtp(e.target.value);setOtpVerificationMessageError("")}}
                            validators={[
                                "required",
                                "isNumber"
                            ]}
                            errorMessages={[
                                "OTP is required",
                                "Only Numeric values accepted!"
                            ]}
                        />}
                        <Box ml={2}>                      {!sendOtp&&   <Button size='small' style={{border:"0.5px grey solid",marginTop:"1vh"}}  onClick={()=>sendVerificationCode()}>Send OTP</Button>}</Box>

                <Box ml={2}>  {  sendOtp&&!sendVerifyOtp&&    <Button size='small' style={{border:"0.5px grey solid",marginTop:"1vh"}} ml={3} onClick={()=>verifyReferenceCode()}>Verify OTP</Button>}</Box>
                        {/* {sendVerifyOtp&&   <TextValidator
                                variant="outlined"
                                fullWidth
                                name="phone"
                                label="Verify  OTP"
                                required
                            size="small"
                            value={verifyOtp}
                            onChange={(e)=>{setVerifyOtp(e.target.value);setOtpVerificationMessageError("")}}
                            validators={[
                                "required",
                                "isNumber"
                            ]}
                            errorMessages={[
                                "OTP is required",
                                "Only Numeric values accepted!"
                            ]}
                        />} */}
                        </Grid>
                    </Grid>
                    
                </Grid>
                <Box sx={{ textAlign: 'right' }}>
                    {cancelButton == true &&
                        <Button
                            disableElevation
                            size="large"
                            variant="text"
                            color="inherit"
                            onClick={e => {
                                onClose(e);
                            }}
                        >
                            Cancel
                        </Button>
                    }
                    <LoadingButton
                        disableElevation
                        size="large"
                        loading={loading}
                        disabled={disableSubmitButton}
                        color="primary"
                        variant="text"
                        type='submit'
                    >
                        Submit
                    </LoadingButton>

                </Box>
            </ValidatorForm>

        </>
    )
}

const mapStateToProps = state => {
    return {
        isLoading: state.commonReducer.loader
    };
};

export default connect(mapStateToProps, { checkAndLoadUser })(ProfileForm);
