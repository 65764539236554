import { LOAD_COMMON_DATA, LOADER, LOGIN_MODAL, SIGNUP_MODAL, LANGUAGE, LANGUAGES, TOP_PROGRESSBAR } from './types';
import { Product } from '../../api/resources/Product'
import { Extras } from '../../api/resources/Extras'
import { CommodityCommon } from '../../api/resources/admin-api/CommodityCommon';

export const loadLanguageTranslations = (languageCode) => dispatch => {
    dispatch({
        type: TOP_PROGRESSBAR,
        payload: {topProgressBar: true}
	})
    Extras.getTranslations(languageCode)
        .then((res) => {
            if(res.data.status === 'success')
            {
                dispatch({
                    type: LANGUAGE,
                    payload: {
                        languageCode: res.data.data.language.code,
                        translations: res.data.data.language.translations
                    }
                })
            }
        })
        .finally(() => {
            dispatch({
                type: TOP_PROGRESSBAR,
                payload: {topProgressBar: false}
            })
        })
}

export const loadAvailableLanguages = (availableLanguages) => dispatch => {
    dispatch({
        type: LANGUAGES,
        payload: {
            availableLanguages: availableLanguages,
        }
    })
}



export const loadCommonData = (status) => dispatch => {
    const getData = async () => {
        try {
            const countriesRes = await CommodityCommon.Countries()
         
            // const globalSettingsRes = await Product.getGlobalSettings()
        // console.log('frhfr',globalSettingsRes)
            dispatch({
                type: LOAD_COMMON_DATA,
                payload: {
                    countries: countriesRes.data.data,
                    // coalTypes: [],
                    // mineCompanies: [],
                    states: [],
                    // globalSettings: globalSettingsRes.data.data,
                    paymentTerms: [],
                    ekycIndustries: []
                }
            })
        }
        catch(e) {
            console.error('Error loading common data.')
            console.error(e)
        }
    }
    getData()
}

export const loader = (status) => dispatch => {
    dispatch({
        type: LOADER,
        payload: {
            loader: status
        }
	})
}

export const topProgressBarStatus = (status) => dispatch => {
    dispatch({
        type: TOP_PROGRESSBAR,
        payload: {
            topProgressBar: status
        }
	})
}

export const setLoginModal = (status) => dispatch => {
    dispatch({
        type: LOGIN_MODAL,
        payload: {
            loginModal: status
        }
	})
}

export const setSignupModal = (status) => dispatch => {
    dispatch({
        type: SIGNUP_MODAL,
        payload: {
            signupModal: status
        }
	})
}
