import { LOGIN_PROCESS, LOGIN_USER, LOGOUT_USER, CHANGE_USER_MODE, UPDATE_NOTIFICATIONS_AND_MESSAGES, USER_NOT_VERIFIED, UNREAD_NOTIFICATION_COUNT,CHECK_AND_LOAD_USER, LOAD_USER_COMMODITES } from './types';
import { config } from '../../config';
import axios from 'axios'
import { User } from '../../api/resources/User'
import { storeUserToken, storeCommuserToken, uniqueBy } from '../../helpers'
import { fcmAnalytics } from "../../firebase";
import fcmEvents from "../../eventsTracking";
import { CommodityUser } from '../../api/resources/CommodityUser';
import trackingEvents from '../../eventsTracking';
// import CryptoJS from 'crypto-js';
export const fetchNotificationsAction = () => dispatch => {
    fetchNotifications(dispatch)
}

export const fetchUnreadNotificationCount = () => dispatch  => {

    const fetchUnreadCount = async () => {
        const res = await User.unreadNotificationCount()
console.log('noti',res)
        if(res.data.status === 'success') {
            dispatch({
                type: UNREAD_NOTIFICATION_COUNT,
                payload: {
                    unreadNotificationCount: res.data.data.unread_notification_count,
                }
            })
        }
    }

    fetchUnreadCount()
}


const fetchNotifications = (dispatch) => {
	User.fetchNotifications()
		.then(response => {
            console.log(response)
			if(response.status == 200) {
				// response = response.data.data.notifications
                console.log(response)
				dispatch({
					type: UPDATE_NOTIFICATIONS_AND_MESSAGES,
					payload: {
						notifications: response.data.data.notifications,
						messagesCount: 1,
						unreadNotificationCount: 1,
						unreadNotifications: 1
					}
                })

                if(response.unread_notification_count || response.unread_notification_count > -1)
                {
                    dispatch({
                        type: UNREAD_NOTIFICATION_COUNT,
                        payload: {
                            unreadNotificationCount: response.unread_notification_count,
                        }
                    })
                }
			}
		})
}


export const changeUserMode = (mode) => dispatch => {
    dispatch({
        type: CHANGE_USER_MODE,
        payload: {
            user_mode: mode
        }
    })
}

export const clearNotVerifiedUser = () => dispatch => {
    dispatch({
        type: USER_NOT_VERIFIED,
        payload: {
            userNotVerified: false,
            userToBeVerified: {}
        }
    })
}
export const loginCommUser = (params) => dispatch => {
    dispatch({
        type: LOGIN_PROCESS,
        payload: {
            loginLoader: true,
            loginMessage: ''
        }
    })
	axios.post(config.api_commodity_login+'/login', params)
		.then(response => {
			if (response.status == 201) {
                if(response.data.message === 'User is blocked') {
                    dispatch({
                        type: LOGIN_PROCESS,
                        payload: {
                            loginLoader: false,
                            loginMessage: 'This account is blocked. Contact the admin to request unblock'
                        }
                    });
                } else {
                    let user = response.data.data.user
    
                    // const userLoggedInEvent = new CustomEvent('userLoggedIn', { userId: user.id });
                    // window.dispatchEvent(userLoggedInEvent)
                    storeCommuserToken('user', response.data.data.access_token)
    
                    dispatch({
                        type: LOGIN_USER,
                        payload: {
                            user: user,
                            company_id: user.activeCompanyId,
                            isLoggedIn: true,
                            loginStatus: response.statusTexts,
                            unreadNotificationCount: 0,
                            user_mode: 2,
                            noCompany: user.activeCompanyId == '' ? true : false,
                            isUserChecked: true,
                            companyIds: (user.company_ids && user.company_ids.length > 0  && Array.isArray(user.company_ids)) ? user.company_ids.map(company => company._id) : [],
                        }
                    })
    

                    dispatch({
                        type: LOGIN_PROCESS,
                        payload: {
                            loginLoader: false,
                            loginMessage: response.data.message
                        }
                    })
                    //window.location = '/virtual-office/inbox'
                    window.location = '/'
                }
			}else  {
				localStorage.removeItem('user_token')
				dispatch({
					type: LOGIN_USER,
					payload: {
						loginStatus: response.status,
                        isLoggedIn: false,
                        isUserChecked: false
					}
                })
                dispatch({
                    type: LOGIN_PROCESS,
                    payload: {
                        loginLoader: false,
                        loginMessage: response.message
                    }
                })

                throw new Error({error: 'unauthenticated'});
			}
        })
        .catch(error => {
            dispatch({
                type: LOGIN_PROCESS,
                payload: {
                    loginLoader: false,
                    loginMessage: 'Incorrect Email ID or Password. Please try again.'
                }
            })
            throw error;
        })
        .finally(() => {
            setTimeout(() => {
                dispatch({
                    type: LOGIN_PROCESS,
                    payload: {
                        loginLoader: false,
                        loginMessage: ""
                    }
                })
            }, 3000)
        })
}
export const loginWithEmail = (params) => dispatch => {
    dispatch({
        type: LOGIN_PROCESS,
        payload: {
            loginLoader: true,
            loginMessage: ''
        }
    })
    const expoToken = localStorage.getItem('expoToken');
    const firebaseToken = localStorage.getItem('firebase_web_token');
    Object.assign(params, { fcm_token: expoToken || firebaseToken });
	axios.post(config.api_commodity_login+'/login-with-email-otp', params)
		.then(response => {
			if (response.status == 201) {
                if(response.data.message === 'User is blocked') {
                    dispatch({
                        type: LOGIN_PROCESS,
                        payload: {
                            loginLoader: false,
                            loginMessage: 'This account is blocked. Contact the admin to request unblock'
                        }
                    });
                } else {
                    let user = response.data.data.user
    
                    const userLoggedInEvent = new CustomEvent('userLoggedIn', { userId: user.id });
                    window.dispatchEvent(userLoggedInEvent)
    
                    storeCommuserToken('user', response.data.data.access_token)

                    dispatch({
                        type: LOGIN_USER,
                        payload: {
                            user: user,
                            company_id: user.activeCompanyId,
                            isLoggedIn: true,
                            loginStatus: response.statusTexts,
                            unreadNotificationCount: 0,
                            user_mode: 2,
                            noCompany: user.activeCompanyId == '' ? true : false,
                            isUserChecked: true,
                            companyIds: (user.company_ids && user.company_ids.length > 0  && Array.isArray(user.company_ids)) ? user.company_ids.map(company => company._id) : [],
                        }
                    })

                    dispatch({
                        type: LOGIN_PROCESS,
                        payload: {
                            loginLoader: false,
                            loginMessage: response.data.message
                        }
                    })
                    // window.location = '/virtual-office/inbox'
                    window.location = '/'
                }
			} else {
				localStorage.removeItem('user_token')
				dispatch({
					type: LOGIN_USER,
					payload: {
						loginStatus: response.status,
                        isLoggedIn: false,
                        isUserChecked: false
					}
                })
                dispatch({
                    type: LOGIN_PROCESS,
                    payload: {
                        loginLoader: false,
                        loginMessage: response.message
                    }
                })

                throw new Error({error: 'unauthenticated'});
			}
        })
        .catch(error => {
            dispatch({
                type: LOGIN_PROCESS,
                payload: {
                    loginLoader: false,
                    loginMessage: 'Incorrect Email ID or Password. Please try again.'
                }
            })
            throw error;
        })
        .finally(() => {
            setTimeout(() => {
                dispatch({
                    type: LOGIN_PROCESS,
                    payload: {
                        loginLoader: false,
                        loginMessage: ""
                    }
                })
            }, 3000)
        })
}
export const loginWithPhone = (params) => dispatch => {
    dispatch({
        type: LOGIN_PROCESS,
        payload: {
            loginLoader: true,
            loginMessage: ''
        }
    })
    const expoToken = localStorage.getItem('expoToken');
    const firebaseToken = localStorage.getItem('firebase_web_token');
    Object.assign(params, {fcm_token: expoToken || firebaseToken });
	axios.post(config.api_commodity_login+'/login-with-mobile-otp', params)
		.then(response => {
            console.log({responseLogin:response})
			if (response.status == 201) {
                if(response.data.message === 'User is blocked') {
                    dispatch({
                        type: LOGIN_PROCESS,
                        payload: {
                            loginLoader: false,
                            loginMessage: 'This account is blocked. Contact the admin to request unblock'
                        }
                    });
                } else {
                    let user = response.data.data.user
    
                    const userLoggedInEvent = new CustomEvent('userLoggedIn', { userId: user.id });
                    window.dispatchEvent(userLoggedInEvent)
    
                    storeCommuserToken('user', response.data.data.access_token)

                    dispatch({
                        type: LOGIN_USER,
                        payload: {
                            user: user,
                            company_id: user?.activeCompanyId,
                            isLoggedIn: true,
                            loginStatus: response.statusText,
                            unreadNotificationCount: 0,
                            user_mode: 2,
                            noCompany: user.activeCompanyId == '' ? true : false,
                            isUserChecked: true,
                            companyIds: (user.company_ids && user.company_ids.length > 0  && Array.isArray(user.company_ids)) ? user.company_ids.map(company => company._id) : [],
                        }
                    })
    
    
                    dispatch({
                        type: LOGIN_PROCESS,
                        payload: {
                            loginLoader: false,
                            loginMessage: response.data.message
                        }
                    })
                    // window.location = '/virtual-office/inbox'
                    window.location = '/'
                }
			}else  {
				localStorage.removeItem('user_token')
				dispatch({
					type: LOGIN_USER,
					payload: {
						loginStatus: response.status,
                        isLoggedIn: false,
                        isUserChecked: false
					}
                })
                dispatch({
                    type: LOGIN_PROCESS,
                    payload: {
                        loginLoader: false,
                        loginMessage: response.message
                    }
                })

                throw new Error({error: 'unauthenticated'});
			}
        })
        .catch(error => {
            console.log('error logging in',error)
            dispatch({
                type: LOGIN_PROCESS,
                payload: {
                    loginLoader: false,
                    loginMessage: 'Incorrect Email ID or Password. Please try again.'
                }
            })
            throw error;
        })
        .finally(() => {
            setTimeout(() => {
                dispatch({
                    type: LOGIN_PROCESS,
                    payload: {
                        loginLoader: false,
                        loginMessage: ""
                    }
                })
            }, 3000)
        })
}
export const loginUser = (params) => dispatch => {
    dispatch({
        type: LOGIN_PROCESS,
        payload: {
            loginLoader: true,
            loginMessage: ''
        }
    })
	axios.post(config.login_url, params)
		.then(response => {
			if (response.data.status === 'fail') {
				localStorage.removeItem('user_token')
				dispatch({
					type: LOGIN_USER,
					payload: {
						loginStatus: response.data.status,
                        isLoggedIn: false,
                        isUserChecked: false
					}
                })
                dispatch({
                    type: LOGIN_PROCESS,
                    payload: {
                        loginLoader: false,
                        loginMessage: response.data.message
                    }
                })
			}
			if (response.data.status === 'success') {
                let user = response.data.data
                if(user.user_status < 2 && user.parent_user !== 1)
                {
                    dispatch({
                        type: USER_NOT_VERIFIED,
                        payload: {
                            userNotVerified: true,
                            userToBeVerified: user
                        }
                    })
                }
                else {
                    const userLoggedInEvent = new CustomEvent('userLoggedIn', { userId: user.id });
                    window.dispatchEvent(userLoggedInEvent)
                    if ('Hansel' in window) {
                        window.Hansel.getUser().setUserID(user.id);
                    } else {
                        window.addEventListener('pxSdkReady', () => {
                            window.Hansel.getUser().setUserID(user.id);
                        })
                    }
                    // fcmAnalytics.setUserID(user.id)
                    fcmAnalytics.logEvent("web_login", {
                        user_id: user.id,
                        user_role: user.user_type == 1 ? 'buyer' : 'seller',
                    });

                    fcmAnalytics.setUserProperties({type: user.user_type == 1 ? 'Buyer' : 'Seller'});
                    // localStorage.setItem('user_token', CryptoJS.AES.encrypt(response.data.data.token, config.crypto_key))
                    storeUserToken('user', response.data.data.token)
                    dispatch({
                        type: LOGIN_USER,
                        payload: {
                            user: user,
                            company_id: user.company_id,
                            isLoggedIn: true,
                            loginStatus: response.data.status,
                            unreadNotificationCount: response.data.unread_notification_count,
                            user_mode: user.user_type === 1 ? 1 : 2,
                            noCompany: user.company_id === 0 ? true : false,
                            isUserChecked: true,
                            companyIds: (user.companies && user.companies.length > 0  && Array.isArray(user.companies)) ? user.companies.map(company => company.id) : [],
                        }
                    })

                    if(response.data.unread_notification_count || response.data.unread_notification_count > -1)
                    {
                        dispatch({
                            type: UNREAD_NOTIFICATION_COUNT,
                            payload: {
                                unreadNotificationCount: response.data.unread_notification_count,
                            }
                        })
                    }
                    dispatch({
                        type: LOGIN_PROCESS,
                        payload: {
                            loginLoader: false,
                            loginMessage: response.data.message
                        }
                    })

                    // fetchNotifications(dispatch)
                }
				//window.location = '/virtual-office/inbox'
			}
        })
        .finally(() => {
            setTimeout(() => {
                dispatch({
                    type: LOGIN_PROCESS,
                    payload: {
                        loginLoader: false,
                        loginMessage: ""
                    }
                })
            }, 1000)
        })
}

export const logoutUser = () => dispatch => {
    console.log('logged out called')
    // User.logout()
    //     .finally(() => {
            // fcmAnalytics.logEvent("logout");
            // fcmEvents.userLogout();
            trackingEvents.userLogoutFromNavbar();
			// localStorage.removeItem('user_token')
			localStorage.removeItem('comm_user_token')

            dispatch({
                type: LOGOUT_USER,
                payload: {
                    user: {},
                    company_id: null,
                    isLoggedIn: false,
                    loginStatus: false,
                    loginMessage: "You have logged out successfully",
                    user_mode: null,
                    noCompany: false,
                    loggingIn: false,
                    company: {},
                    companies: [],
                    companyIds: [],
                    notifications: [],
                    messagesCount: 0
                }
            })

            setTimeout(() => {
                dispatch({
                    type: LOGOUT_USER,
                    payload: {
                        user: {},
                        company_id: null,
                        isLoggedIn: false,
                        loginStatus: false,
                        loginMessage: "",
                        user_mode: null,
                        noCompany: false,
                        loggingIn: false,
                        company: {},
                        companies: [],
                        companyIds: [],
                        notifications: [],
                        messagesCount: 0
                    }
                })
			}, 3000)

			//window.history.pushState("", "", '/login');
			//window.location = '/login'
        // })
}

export const checkAndLoadUser = (params) => dispatch => {
    // return CommodityUser.checkAndLoadUser()
    // .then((resposne) =>{
    //     console.log(resposne.data)
    // })

    return CommodityUser.checkAndLoadUser()
    .then((response) => {
  
        if (!response) {
			// localStorage.removeItem('user_token')
            dispatch({
                type: CHECK_AND_LOAD_USER,
                payload: {
                    user: {},
                    isLoggedIn: false,
                    isUserChecked: true
                }
            })
        }
        else if (response && response.status == 200) {
            // console.log('response:' , response)
            var user = response.data.data.user
            CommodityUser.userCommodities()
            .then((response) => {
                
                if (response && response.status == 200) {
                        // console.log('op',response)
                        const uniqueCommodityTypes = uniqueBy(
                            response.data.data.selectedCommodities.map((commodity) => commodity.commodityType),
                            "_id"
                        );
                        // console.log({uniqueCommodityTypes})
                        dispatch({
                            type: LOAD_USER_COMMODITES,
                            payload: {
                                selectedCommodites: response.data.data.selectedCommodities,
                                selectedCommodityTypes: uniqueCommodityTypes,
                                
                            }
                        })
                    }
                })
                .catch(e => console.error(e))
            // const userDataLoadedEvent = new CustomEvent('userDataLoaded', { userId: user.id });
            // window.dispatchEvent(userDataLoadedEvent)
            // if ('Hansel' in window) {
            //     window.Hansel.getUser().setUserID(user.id);
            // } else {
            //     window.addEventListener('pxSdkReady', () => {
            //         window.Hansel.getUser().setUserID(user.id);
            //     })
            // }

            // user.token = response.data.user_token
            // var user_mode = user.user_type === 1 ? 1 : 2
            // changeUserMode(user_mode)
            dispatch({
                type: CHECK_AND_LOAD_USER,
                payload: {
                    user: user,
                    company_id: user.activeCompanyId,
                    isLoggedIn: true,
                    user_mode: 2,
                    isUserChecked: true,
                    noCompany: user.activeCompany.length == 0 ? true : false,
                    companyIds: (user.companies && user.companies.length > 0  && Array.isArray(user.companies)) ? user.companies.map(company => company._id) : [],
                }
            })
            // fcmAnalytics.setUserProperties({type: user.user_type == 1 ? 'Buyer' : 'Seller'});

            // if(response.data.unread_notification_count || response.data.unread_notification_count > -1)
            // {
            //     dispatch({
            //         type: UNREAD_NOTIFICATION_COUNT,
            //         payload: {
            //             unreadNotificationCount: response.data.unread_notification_count,
            //         }
            //     })
            // }

            // fetchNotifications(dispatch)
            // if(params && params.redirect)
            // {
            //     console.log('redirect')
            //     window.history.pushState({urlPath: params.redirect},"", params.redirect)
            // }
        }
    })
    .catch(e => console.error(e))
	// const url = config.api_url + '/getprofile'
	// const stored_user_token = localStorage.getItem('user_token')
	// //let user = {}
	// //let isLoggedIn = false
	// if (stored_user_token) {

	// 	var bytes = CryptoJS.AES.decrypt(stored_user_token.toString(), config.crypto_key);
	// 	var user_token = bytes.toString(CryptoJS.enc.Utf8);

	// 	axios.post(url, { token: user_token })
	// 		.then(response => {
	// 			if (response.data.status === 'fail') {
	// 				dispatch({
	// 					type: CHECK_AND_LOAD_USER,
	// 					payload: {
	// 						user: {},
	// 						isLoggedIn: false
	// 					}
	// 				})
	// 			}
	// 			if (response.data.status === 'success') {
    //                 var user = response.data.data
    //                 user.token = user_token
    //                 var user_mode = user.user_type === 1 ? 1 : 2
	// 				dispatch({
	// 					type: CHECK_AND_LOAD_USER,
	// 					payload: {
	// 						user: user,
    //                         isLoggedIn: true,
    //                         user_mode: user_mode
	// 					}
	// 				})
	// 			}
	// 		})

	// }
}
export const loadUserCommodites = (params) => dispatch => {

    return CommodityUser.userCommodities()
    .then((response) => {
        console.log('response:ktpktpkp' , response)
        if (response && response.status == 200) {
            const uniqueCommodityTypes = uniqueBy(
                response.data.data.selectedCommodities.map((commodity) => commodity.commodityType),
                "_id"
            );
            dispatch({
                type: LOAD_USER_COMMODITES,
                payload: {
                    selectedCommodites: response.data.data.selectedCommodities,
                    selectedCommodityTypes: uniqueCommodityTypes,
                }
            })
        }
    })
    .catch(e => console.error(e))
}



// export const switchCompanySilently = (params) => dispatch => {
//     return User.checkAndLoad()
//     .then((response) => {
//         const user = response.data.data
//         dispatch({
//             type: 'SWITCH_COMPANY_SILENTLY',
//             payload: {
//                 user: user,
//                 company_id: user.company_id,
//             }
//         })
//     })
// }
