import React from 'react'
import makeStyles from '@mui/styles/makeStyles';
// import IconButton from '@mui/material/IconButton';
// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import useMediaQuery from "@mui/material/useMediaQuery";
// import ItemsCarousel from 'react-items-carousel';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';


const useStyles = makeStyles(theme => ({
    itemWidth: {
        width: 'fit-content !important',
        marginBottom: theme.spacing(2.5)
    },
    btn: {
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.dark
        }
    },
    wrapper: {
        "& .react-multiple-carousel__arrow--right": {
            right: 'calc(0% + 1px)',
            "&.react-multiple-carousel__arrow": {
                borderTopLeftRadius: 5,
                borderTopRightRadius: 0,
                borderBottomLeftRadius: 5,
                borderBottomRightRadius: 0,
            },
        },
        "& .react-multiple-carousel__arrow--left": {
            left: 'calc(0% + 1px)',
            "&.react-multiple-carousel__arrow": {
                borderTopLeftRadius: 0,
                borderTopRightRadius: 5,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 5,
            },
        },
    }
}))

const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };


export default function CarouselSlide(props) {
    const classes = useStyles();
    // const [activeItemIndex, setActiveItemIndex] = React.useState(0);
    // const matchesViewport = useMediaQuery("(max-width:580px)");
  return (
    <div className={classes.wrapper}>
        <Carousel 
        // autoPlay={true}
        autoPlaySpeed={4000}
        partialVisible={true} 
        // centerMode={true} 
        infinite={true}
        responsive={responsive}
        itemClass="carousel-item-padding-40-px"
        >
            {props.children}
        </Carousel>
        {/* <ItemsCarousel
            infiniteLoop={false}
            // enablePlaceHolder={true}
            // placeHolderItem={<SmCardLoader loaderProps={{ height: "115px", width: '200px' }} />}
            gutter={8}
            activePosition={'center'}
            chevronWidth={60}
            disableSwipe={false}
            alwaysShowChevrons={false}
            numberOfCards={matchesViewport ? 1 : 4}
            carouselItems={5}
            slidesToScroll={1}
            outsideChevron={false}
            showSlither={true}
            firstAndLastGutter={false}
            activeItemIndex={activeItemIndex}
            classes={{itemWrapper: `${classes.itemWidth}`}}
            requestToChangeActive={setActiveItemIndex}
            leftChevron={<IconButton className={`text-white ${classes.btn}`}><ChevronLeftIcon fontSize='small' className="vAlignBottom" /></IconButton>}
            rightChevron={<IconButton className={`text-white ${classes.btn}`}><ChevronRightIcon fontSize='small' className="vAlignBottom" /></IconButton>}
        >
            {props.children}
        </ItemsCarousel> */}
    </div>

  )
}
