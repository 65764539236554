import React, { Component } from "react";
import {
    Grid,
    Box
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
// import { Link } from 'react-router-dom';
import fbImg from '../../_fb.png'
import instaImg from '../../_instagram.png'
import twitImg from '../../_twitter.png'
import linkImg from '../../_linkedIn.png'
import ytImg from '../../_youtube.png'

const useStyles = makeStyles(theme => ({
    zIndex1: {
        maxWidth: '220px',
        zIndex: 1
    },
}));
export default function SocialAvailability() {
    const classes = useStyles();

    return (
        <Box className={`${classes.zIndex1} is-relative`} >
            <Grid container spacing={0}>
                <Grid item >
                    <Box>
                        <a href='https://www.linkedin.com/company/mandi-trade' aria-label="LinkedIn" target="_blank" rel="noopener"><img src={linkImg} alt="Mandi.trade on LinkedIn" width='36px' height='36px' /></a>
                    </Box>
                </Grid>
                <Grid item >
                    <Box>
                        <a href='https://www.facebook.com/people/Manditrade/61555501797136/' aria-label="Facebook" target="_blank" rel="noopener"><img src={fbImg} alt="Mandi.trade on Facebook" width='36px' height='36px' /></a>
                    </Box>
                </Grid>
                <Grid item >
                    <Box>
                        <a href='https://twitter.com/Manditrade' aria-label="Twitter" target="_blank" rel="noopener"><img src={twitImg} alt="Mandi.trade on Twitter" width='36px' height='36px' /></a>
                    </Box>
                </Grid>
                <Grid item >
                    <Box>
                        <a href='https://www.instagram.com/mandi.trade/' aria-label="Instagram" target="_blank" rel="noopener"><img src={instaImg} alt="Mandi.trade on Instagram" width='36px' height='36px' /></a>
                    </Box>
                </Grid>
                <Grid item >
                    <Box>
                        <a href='https://www.youtube.com/@Manditrade' aria-label="YouTube" target="_blank" rel="noopener"><img src={ytImg} alt="Mandi.trade on YouTube" width='36px' height='36px' /></a>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}