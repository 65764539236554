import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "./Loader";
import { cDecrypt } from "../../helpers";

export default function ExpoRedirect() {
    const params = useParams();
    const expoToken = cDecrypt(params.token);
    const navigate = useNavigate();

    React.useEffect(() => {
        localStorage.setItem('expoToken', expoToken);
        navigate("/");
    }, []);
    
    return <Loader />
}