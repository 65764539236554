
import resource from 'resource-axios';
import axios from '../../base-comm-console';
import { config } from '../../../config';

export const CommodityCommon = resource(config.common_api_commodity_url, {
    Countries: (params) => {
        return axios.get(`${config.common_api_commodity_url}/locations/countries`)
    },

    getALLStates: (params) => {
        console.log(params)
        var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        // return axios.get(`${config.common_api_commodity_url}/locations/countries`, params)
        return axios.get(`${config.common_api_commodity_url}/locations/countries/states?${queryString}`, params)
    },
    getAllCity: (params) => {
        console.log(params)
        var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        // return axios.get(`${config.common_api_commodity_url}/locations/countries`, params)
        return axios.get(`${config.common_api_commodity_url}/locations/countries/states/cities?${queryString}`, params)
    },
    Currency: () => {

        return axios.get(`${config.common_api_commodity_url}/currencies`)
    },
    Industry: () => {

        return axios.get(`${config.common_api_commodity_url}/industries`)
    },
    
    singleIndustry: (id) => {
        return axios.get(`${config.common_api_commodity_url}/industries/${id}`)
    },
    Commodities: (params) => {
        let queryString = "";
        if(typeof params == 'object')
        {
            queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        }
        return axios.get(`${config.common_api_commodity_url}/commodities?${queryString}`)
    },
    CommodityType: (params) => {
        let queryString = "";
        if(typeof params == 'object')
        {
            queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        }
        return axios.get(`${config.common_api_commodity_url}/commodity-types?${queryString}`)
    },

    AllMarkets : () => {

        return axios.get(`${config.common_api_commodity_url}/markets`)
    },
    
    latestBuyListings : () => {
        return axios.get(`${config.common_api_commodity_url}/listings/live-buyers`)
    },
    latestSellListings : () => {
        return axios.get(`${config.common_api_commodity_url}/listings/live-sellers`)
    },
    Contracts: (params) => {
        let queryString = "";
        if(typeof params === "object") {
            queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        }
        const result = axios.get(`${config.common_api_commodity_url}/contracts?${queryString}`);
        return result;
    },
    bestBuyersGuest : (contractId) => {
        return axios.get(`${config.common_api_commodity_url}/listings/guest/contract/${contractId}/live-buyers`);
    },
    bestSellersGuest : (contractId) => {
        return axios.get(`${config.common_api_commodity_url}/listings/guest/contract/${contractId}/live-sellers`);
    },
    getDesignations: () => {
        return axios.get(`${config.common_api_commodity_url}/designations`)
    },
    getEvents: (params) => {
        return axios.get(`${config.common_api_commodity_url}/events`)
    }


}, axios)