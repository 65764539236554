import resource from 'resource-axios';
import axios from '../base';
import {
    config
} from '../../config';


export const Extras = resource(config.api_url, {

    getTranslations: (languageCode) => {
        return axios.get(`${config.api_url}/language/${languageCode}/translations`)
    },
    getLatestFiveDeals:()=>{
        return axios.get(`${config.api_url}/latestfivedeals`)
    }
    ,
    globalColaNews:()=>{
        return axios.get(`${config.api_url}/global-coal-news`)
    },
    getAvailableLanguages: () => {
        return axios.get(`${config.api_url}/available-languages`)
    },

    // Report bug
    postBugReport: (params) => {
        return axios.post(`${config.api_url}/postBugReport`, params)
    },

    // Submit users' query from contact page and widget
    submitEnquiryForm: (params) => {
        return axios.post(`${config.api_url}/contact-us`, params)
    },

    // Events List
    listEvents: () => {
        return axios.get(`${config.api_url}/getallevents`)
    },

    // Events List
    indiaIndexes: () => {
        return axios.get(`${config.api_url}/indiaindexes`)
    },

    // News List
    getNewsList: (page) => {
        page = !page ? 1 : page
        return axios.get(`${config.api_url}/getallnewslist?page=${page}`)
    },

    // Single News List
    getSingleNewsList: (slug) => {
        return axios.get(`${config.api_url}/getnewsbyslug/${slug}`)
    },

    // Auctions
    getAuctions: (slug) => {
        return axios.get(`${config.api_url}/viewauctionlist`)
    },

    // General SEO tags and metas
    getGeneralSEO: (route_url) => {
        return axios.post(`${config.api_url}/getgeneralseodetails`)
    },

    // latest 5 news for homepage
    latestFiveNews: () => {
        return axios.get(`${config.api_url}/latestfivenews`)
    },

    // latest 5 blogs for homepage
    latestFiveBlogs: () => {
        return axios.get(`${config.api_url}/latestfiveblog`)
    },

    // latest 5 events for homepage
    latestFiveEvents: () => {
        return axios.get(`${config.api_url}/latestfiveevents`)
    },

    getIncoterms: () => {
        return axios.get(`${config.api_url}/incoterms`)
    },
    getPlans: () => {
        return axios.get(`${config.api_url}/plans`)
    }

}, axios);
