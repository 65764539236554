import { Box, Button, Card, CardContent, CardMedia, Grid, Tooltip, Typography } from '@mui/material';
import React from 'react'
import SmCardLoader from '../partials/content-loaders/SmCardLoader';
import CarouselSlide from './Carousel';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import trackingEvents from '../../eventsTracking';
import { cEncrypt, timeAgo } from '../../helpers';
import dateformat from "dateformat";

function ListingCarousel({ listings, isLoading }) {
    const isLoggedIn = useSelector(state => state.userReducer.isLoggedIn);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const navigate = useNavigate();
    // console.log('listings', listings)
    return (
        <Box sx={{}}>
            <CarouselSlide>
                {/* {listings.length > 0 && listings.map(l => {
                    return (<p>
                        {l?.price}
                    </p>)
                    // <BuyListingItem data={l} key={l.listingNumber} />
                })} */}
                {
                    isLoading
                        ? [1, 2, 3, 4].map((i) => (
                            <Box sx={{ marginRight: "10px" }} key={i}>
                                <SmCardLoader key={i} loaderProps={{ height: "115px", width: "200px" }} />
                            </Box>
                        ))
                        : !isLoading
                        && listings
                        && listings.length > 0
                        && listings.map(l => {
                            const { price, contract, quantity, quantityUnit, settlementFields, images, currency } = l
                            return (
                                <>
                                <Box 
                                        // to={{
                                        //         // pathname: `/markets/${contract?.commodity?.commodityType.slug}/${contract.slug}`,
                                        //         pathname: `/markets/${contract?.commodity?.commodityType.slug}`,
                                        //         search: searchParams.toString(),
                                        //         state:{data:contract?.commodity?.commodityType}
                                        //     }}
                                            onClick={() => {
                                                // console.log(l)
                                                trackingEvents.listingCarouselCardCLick({contract: contract.name, commodity: contract.commodity.name, quantity: quantity})
                                                searchParams.set('name', contract.slug)
			                                    searchParams.set('id', cEncrypt(contract._id))
                                                searchParams.set('listingRefId', cEncrypt(l._id))
                                                navigate(`/markets/${contract?.commodity?.commodityType.slug}?${searchParams.toString()}`)
                                            }}
                                        >
                                    <Card
                                        variant="outlined"
                                        sx={{ display: "flex", minWidth: "max-content", mr: 1, "&:hover": { cursor: 'pointer', boxShadow: "1px 10px 16px #282424"} }}
                                    >
                                        {(images && images.length > 0) ? <CardMedia
                                            style={{
                                                border: "2px #ddd solid", display: 'flex',
                                                justifyContent: 'center', alignItems: 'center'
                                            }}
                                            component="img"
                                            sx={{ width: 120, height: 110, mt: 2 }}
                                            image={`${process.env.REACT_APP_API_URL_COMMODITY}/${images[0]?.url}`}
                                            alt={contract.name}
                                        /> : contract
                                            && contract.images
                                            && contract.images.length > 0 ?
                                            <CardMedia
                                                style={{
                                                    border: "2px #ddd solid", display: 'flex',
                                                    justifyContent: 'center', alignItems: 'center'
                                                }}
                                                component="img"
                                                sx={{ width: 120, height: 110, mt: 2 }}
                                                image={`${process.env.REACT_APP_API_URL_COMMODITY}/${contract.images[0]?.url}`}
                                                alt={contract.name}
                                            />
                                            :
                                            <Box style={{ width: 120, height: 110, marginTop: '16px', alignItems: 'center', border: "2px #ddd solid", display: "flex", justifyContent: "center", flexDirection: "column" }}>
                                                No Image
                                            </Box>
                                        }
                                        <CardContent sx={{ flex: '1 0 auto', padding:'16px' }}>
                                        {/* <Link 
                                        to={{
                                                pathname: `/markets/${contract?.commodity?.commodityType.slug}/${contract.slug}`,
                                                state:{data:contract?.commodity?.commodityType}
                                            }}
                                        >listing details</Link> */}
                                            <Box sx={{padding: '8px', mb: -1 }}>
                                                <Tooltip title={`${contract?.commodity?.commodityType?.name} - ${contract?.commodity?.name}`}>
                                                    <Box sx={{ fontSize: "14px", fontWeight: "600", mb: 0,
                                                    //  overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth:280  
                                                }}>
                                                        {contract?.commodity?.commodityType?.name} - {contract?.commodity?.name.length > 50 ? `${contract?.commodity?.name.substring(0, 50)}...` : contract?.commodity?.name}{" "}
                                                    </Box>
                                                </Tooltip>
                                                <Box sx={{ mb: 0 }} >
                                                    {contract && (
                                                        <Tooltip title={contract.name}>
                                                            <Typography sx={{ fontWeight: 'bold', textTransform: 'uppercase', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth:280 }}>
                                                                {contract.name.length > 40 ? `${contract.name}...` : contract.name}
                                                            </Typography>
                                                        </Tooltip>
                                                    )}
                                                </Box>
                                                <Box sx={{ display: 'flex', justifyContent: 'flex-start', fontSize: '16px' }}>
                                                    {quantity && (
                                                        <Box sx={{mr:2}}>
                                                            {quantity} {quantityUnit?.name}
                                                        </Box>
                                                    )}
                                                    {/* {price ?   */}
                                                        <Box>
                                                            {isLoggedIn ? (price || '0')  : 'xxxx'} {currency} / {quantityUnit?.name}
                                                        </Box>
                                                        {/* : 
                                                        <Box>
                                                            {isLoggedIn ? '0' : 'xxxx'} {currency} / {quantityUnit.name}
                                                        </Box>
                                                    } */}
                                                </Box>
                                                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth:280 }}>
                                                    {settlementFields?.filter(sf => sf?.value).map((sf, index, filteredArr) => (
                                                        <Box sx={{  }} key={index}>
                                                            {/* <Button onClick={()=>console.log(sf)}>sf</Button> */}
                                                            {sf?.value || ''} {sf?.units[0]?.name ? sf.units[0].name : sf?.name}
                                                            {sf.value != "" && (index < filteredArr.length - 1 && <span>&nbsp;|&nbsp;</span>)}
                                                        </Box>
                                                    ))}
                                                </Box>
                                                <Box>
                                                    <strong>{timeAgo(dateformat(l.createdAt, 'yyyy-mm-dd HH:MM:ss'))}</strong>
                                                </Box>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Box>
                                </>
                            )
                        })
                }
            </CarouselSlide>
        </Box>
    )
}

export default ListingCarousel