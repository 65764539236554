import React from 'react';
import Skeleton from '@mui/material/Skeleton';

export default function CardLoader() {
    return (
        <div className="card-skeleton">
            <Skeleton variant="rectangular" width={`100%`} height={118} />
            <Skeleton />
            <Skeleton width="40%" />
            <Skeleton width="60%" />
        </div>
    );
}