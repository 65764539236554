import { Box, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, Radio, RadioGroup } from '@mui/material'
import React from 'react'

const GstCheck = (props) => {
    const [haveGstNumber,setHaveGstNumber] = React.useState('')
    const [isSubmitted, setIsSubmitted] = React.useState(false);
    const handleGstCheck=(e)=>{
        setHaveGstNumber(e.target.value)
        props.setGstCheckErrorMessage(false)
    }
    const sendDataToParentOnClick = () => {
  
      
        localStorage.setItem('haveGST', haveGstNumber);
      console.log('Value set in localStorage:', haveGstNumber);
   
 
    };
    const isError = isSubmitted && !haveGstNumber;
  return (
  
    <Grid container style={{display:"flex",flexDirection:"column",alignContent:"center"}}>
      <Grid item xs={12}>
      <Box style={{  display: "flex", alignItems: "center",marginTop:"1vh" }}>
  <InputLabel>
    <strong>Do you have a GST number?</strong><sup style={{ color: "red" }}>*</sup>
  </InputLabel>
</Box>
      </Grid>
      <Grid item xs={12}>
      <Box style={{  display: "flex", justifyContent:"center",marginTop:"1vh"}}>
    <FormControl error={!haveGstNumber}>
      <RadioGroup  row aria-label="gender" name="row-radio-buttons-group" value={haveGstNumber}  onChange={handleGstCheck} onClick={sendDataToParentOnClick()}>
        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
        <FormControlLabel value="No" control={<Radio />} label="No" />
      </RadioGroup>
      {/* {isError && <FormHelperText>Please select an option</FormHelperText>} */}
    </FormControl>
    </Box>
      </Grid>
     
    </Grid>
                     
  )
}

export default GstCheck